import React, { FC } from "react";
import "./Progress.scoped.scss";

interface Progress {
  value: number;
  background?: string;
  className?: string;
}

const Progress: FC<Progress> = ({ value, background, className }) => {
  return (
    <div className="progress" style={{ backgroundColor: background ? background : undefined }}>
      {background && (
        <div className="progress--inner" style={{ width: `calc(${value}% + 3px`, backgroundColor: "#FFFFFF" }} />
      )}
      <div className="progress--inner" style={{ width: `${value}%` }} />
    </div>
  );
};

export default Progress;
