import AnalyticsEvent from "./AnalyticsEvent";

abstract class FirstTimeReportOnboarding implements AnalyticsEvent {
  abstract name: string;
  readonly category = "User";
  readonly data = { flow: "first_time_report_onboarding" };
}

export class OverviewSeenEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.OverviewSeen";
}

export class OverviewContinueEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.OverviewContinue";
}

export class OverviewDifferentPropertyEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.OverviewDifferentProperty";
}

export class MortgageShownEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.MortgageShown";
}

export class MortgageConfirmedEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.MortgageYes";
}

export class MortgageDismissedEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.MortgageNo";
}

export class UserCreatedAccountEvent extends FirstTimeReportOnboarding {
  readonly name = "FirstTimeReportOnboarding.CreateAccount";
}
