import { PropertyContext } from "@components/utils/contexts";
import { TemporaryPropertyModel } from "@components/utils/contexts/property";
import Analytics from "@helpers/analytics";
import { getQueryError } from "@helpers/Errors";
import { useFormik } from "formik";
import React, { FC, useCallback, useContext, useState } from "react";
import { Back, Button } from "../../index";
import { Address, AddressSchema, AptOrUnitNumber, AptOrUnitNumberSchema } from "./forms";
import "./OwnerOnboarding.scoped.scss";

interface OwnerOnboarding {
  onSubmit: (property: TemporaryPropertyModel) => Promise<void>;
  onBack: (() => void) | null;
  loading: boolean;
  error?: any;
  propertyLimitReached?: boolean;
}

const OwnerOnboarding: FC<OwnerOnboarding> = ({ onSubmit, onBack, loading, error, propertyLimitReached = false }) => {
  const [step, setStep] = useState<number>(1);
  const [showError, setShowError] = useState<any>(false);
  const { temporaryProperty, setTemporaryPropertyAttributes } = useContext(PropertyContext);

  const renderValidation = (step: number) => {
    switch (step) {
      case 1:
        return AddressSchema;
      case 2:
        return AptOrUnitNumberSchema;
      default:
        return AddressSchema;
    }
  };

  const sendStepCompletedEvent = () => {
    switch (step) {
      case 1:
        return Analytics.trackEvent(new Analytics.Events.Product.UserProvidedAddressEvent());
      case 2:
        return Analytics.trackEvent(new Analytics.Events.Product.UserProvidedUnitNumberEvent());
    }
  };

  const initialValues: TemporaryPropertyModel = {
    search: temporaryProperty?.search || "",
    street_address: temporaryProperty?.street_address || "",
    city: temporaryProperty?.city || "",
    state: temporaryProperty?.state || "",
    zip_code: temporaryProperty?.zip_code || "",
    apt_or_unit: temporaryProperty?.apt_or_unit || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: renderValidation(step),
    onSubmit: (values) => {
      const { search, ...propertyValues } = values;
      setTemporaryPropertyAttributes({ type: "owner", ...propertyValues });
      sendStepCompletedEvent();
      if (step >= 2) {
        if (onSubmit) {
          onSubmit({ ...temporaryProperty, apt_or_unit: values.apt_or_unit })
            //TODO this is hacky
            .catch((err: string) => {
              setShowError(true);
            });
        }
      } else {
        setStep(step + 1);
      }
    },
  });

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return <Address formik={formik} propertyLimitReached={propertyLimitReached} />;
      case 2:
        return <AptOrUnitNumber formik={formik} error={showError && getQueryError(error)} />;
      default:
        return <Address formik={formik} propertyLimitReached={propertyLimitReached} />;
    }
  };

  const onBackHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (step < 2) {
        if (onBack) {
          onBack();
        }
      } else {
        setShowError(false);
        setStep(step - 1);
      }
    },
    [step]
  );

  return (
    <div className="owner-onboarding">
      <form onSubmit={formik.handleSubmit}>
        {renderStep(step)}
        <div className="owner-onboarding--actions">
          {onBack && <Back type="button" onClick={onBackHandler} />}
          <Button fullWidth loading={loading} disabled={!formik.isValid}>
            {step !== 2 ? "Continue" : formik.values.apt_or_unit ? "Save & Continue" : "Skip"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OwnerOnboarding;
