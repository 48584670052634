import { Button, Card } from "@components/global";
import React, { FC } from "react";
import "./NotificationCard.scoped.scss";

const Body = Card.Body;
const NotificationCard: FC = (props) => {
  const defaultContent = () => (
    <>
      <div>
        <h3>
          <i className="digs-icon-bolt" /> We found you savings
        </h3>
        <p>
          Switching to bi-weekly mortgage payments will save you around <b>$920/year in interest payments.</b>
        </p>
      </div>
      <Button className="notification-card--button" onClick={() => {}}>
        Learn More
      </Button>
    </>
  );

  return (
    <Card type="dark" className="notification-card">
      <Body className="notification-card--inner">{props.children || defaultContent()}</Body>
    </Card>
  );
};

export default NotificationCard;
