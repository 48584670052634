import React, { FC, MouseEventHandler } from "react";
import "./CardFooter.scoped.scss";

interface CardFooter {
  title: string;
  type?: "default" | "dropdown" | "card";
  visible?: boolean;
  loading?: boolean;
  className?: string;
  onClick: MouseEventHandler;
  disabled?: boolean;
}

const CardFooter: FC<CardFooter> = ({
  title,
  type = "default",
  visible = false,
  loading = false,
  className = "",
  onClick,
  children,
  disabled,
  ...props
}) => {
  return (
    <div
      className={`card-footer ${type} ${loading ? "loading" : ""} ${className} ${disabled ? "disabled" : ""}`}
      {...props}
    >
      <button onClick={onClick} disabled={loading}>
        <span>{title}</span>
        {!loading && (
          <>
            {visible &&
              (type === "default" || type === "card" ? (
                <i className="digs-icon-minus-filled" />
              ) : (
                <i className="digs-icon-up" />
              ))}
            {!visible &&
              (type === "default" || type === "card" ? (
                <i className="digs-icon-plus-circle" />
              ) : (
                <i className="digs-icon-down" />
              ))}
          </>
        )}
        {loading && <i className="digs-icon-loader" />}
      </button>
      {visible && children}
    </div>
  );
};

export default CardFooter;
