import { Button } from "@components/global";
import Modal, { ModalPropsInterface } from "@components/global/alt-modal/Modal";
import { UiContext } from "@components/utils/contexts";
import Analytics from "@helpers/analytics";
import { useGetPropertyByUUIDQuery } from "@store/services/properties";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import React, { FC, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./RegistrationModal.scss";

export interface RegistrationModalProps extends ModalPropsInterface {}

interface ParamTypes {
  uuid: string;
}

const RegistrationModal: FC<RegistrationModalProps> = ({
  onRequestClose,
  onModalDismissed,
  onModalShown,
  ...props
}) => {
  const history = useHistory();
  const { data: user } = useGetUserIfLoggedInQuery(undefined);

  //assumes we are on /owner/:uuid
  const { uuid } = useParams<ParamTypes>();

  const { data: property, isLoading: propertyLoading } = useGetPropertyByUUIDQuery(uuid, { skip: !uuid });

  const { setUiStateAttributes, uiState } = useContext(UiContext);
  const { registration_modal_props: registrationProps } = uiState;

  const handleModalShown = () => {
    Analytics.trackEvent(
      new Analytics.Events.PublicProperty.RegistrationModalShownEvent(
        registrationProps ? registrationProps.intent : undefined
      )
    );
    onModalShown && onModalShown();
  };
  const handleModalDismissed = () => {
    Analytics.trackEvent(new Analytics.Events.PublicProperty.RegistrationModalDismissedEvent());
    onModalDismissed && onModalDismissed();
  };

  const onLoginClick = () => {
    setUiStateAttributes({ login_modal: true, registration_modal: false });
  };

  return (
    <Modal
      className="registration-modal"
      maxWidth="43rem"
      onRequestClose={onRequestClose}
      onModalDismissed={handleModalDismissed}
      onModalShown={handleModalShown}
      {...props}
    >
      <img
        className="digs-primary"
        src="/assets/images/global/logos/movoto-by-ojo-logo.svg"
        alt={"Movoto by OJO Logo"}
      />
      <h3 className="registration-modal__title">
        <b>Download the mobile app if you want to:</b>
      </h3>
      <ul className="registration-modal__value-props">
        <li>
          <i className="digs-icon-pie-chart-line" />
          Monitor your equity
        </li>
        <li>
          <i className="digs-icon-trend-graph-arrow" />
          Track your home’s value
        </li>
        <li>
          <i className="digs-icon-checklist" />
          Get personalized maintenance checklists{" "}
        </li>
        <li>
          <i className="digs-icon-people" />
          Connect with top-rated home experts
        </li>
        <li>
          <i className="digs-icon-map-pin" />
          Be informed with real-time insights
        </li>
      </ul>
      <Button to="https://www.movoto.com/mobile/" target="_blank">
        Download the App
      </Button>
      <div className="registration-modal__footer">
        Already have an account?{" "}
        <a href="#" onClick={onLoginClick}>
          Log in.
        </a>
      </div>
    </Modal>
  );
};

export default RegistrationModal;
