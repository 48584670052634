import AnalyticsEvent from "./AnalyticsEvent";

export type ThumbtackSource = "property" | "home-services" | "checklist";

abstract class ThumbtackEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Thumbtack";
  readonly data: any;

  constructor() {
    this.data = {};
  }
}

export class ProsSearchedEvent extends ThumbtackEvent {
  readonly name = "Thumbtack.ProsSearched";

  constructor(search: string, source: ThumbtackSource, zipCode: string) {
    super();
    this.data.search = search;
    this.data.source = source;
    this.data.zipCode = zipCode;
  }
}

export class SuggestionClickedEvent extends ThumbtackEvent {
  readonly name = "Thumbtack.SuggestionClicked";

  constructor(search: string, source: ThumbtackSource, zipCode?: string) {
    super();
    this.data.search = search;
    this.data.zipCode = zipCode;
    this.data.source = source;
  }
}

export class ProClickedEvent extends ThumbtackEvent {
  readonly name = "Thumbtack.ProClicked";

  constructor(search: string, source: ThumbtackSource, zipCode: string, proName: string) {
    super();
    this.data.search = search;
    this.data.source = source;
    this.data.zipCode = zipCode;
    this.data.proName = proName;
  }
}
