import React, { FC } from "react";
import NumberInput, { INumberInput } from "../numberInput/NumberInput";
import "./PercentageInput.scoped.scss";

const NON_DIGITS = /[^0-9\.]*/g;
export interface IPercentageInput extends Omit<INumberInput, "onChange" | "value"> {
  large?: boolean;
  value?: number | null;
  onChange?: (value: number | undefined) => void;
}

//TODO this shouldn't be global
let trailingDecimal = false;

const PercentageInput: FC<IPercentageInput> = ({ value, large = false, className, ...props }) => {
  const parse = (value: string) => {
    const parsedValue = parseFloat(value.replace(NON_DIGITS, ""));
    if (!Number.isFinite(parsedValue) || Number.isNaN(parsedValue)) {
      return 0;
    }

    //todo - doesn't currently support values between 0 and 1
    trailingDecimal = parsedValue % 1 == 0 && value.endsWith(".%");
    return parsedValue;
  };

  const format = (value: number) => {
    return `${value}${trailingDecimal ? "." : ""}%`;
  };

  const formatCursorPosition = (value: string, cursorPosition: number, formattedValue: string) => {
    return Math.min(cursorPosition, formattedValue.length - 1);
  };

  return (
    <NumberInput
      {...props}
      inputMode="decimal"
      value={value}
      formatCursorPosition={formatCursorPosition}
      format={format}
      parse={parse}
      className={`percentage-input percentage-input__${large ? "large" : "default"} ${
        value ? "" : "percentage-input__empty"
      }`}
    />
  );
};

export default PercentageInput;
