import { GlobalContextProvider } from "@components/utils/contexts/global";
import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/utils/ScrollToTop";
import Routes from "./routes";

const App: FC = () => {
  return (
    <Router>
      <GlobalContextProvider>
        <ScrollToTop />
        <Routes />
      </GlobalContextProvider>
    </Router>
  );
};

export default App;
