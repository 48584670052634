import React, { ChangeEvent, FC } from "react";
import "./RadioButton.scoped.scss";

export interface IRadioButton extends Partial<HTMLInputElement> {
  onChange?: (e: ChangeEvent) => void;
}

const RadioButton: FC<IRadioButton> = ({
  className = "",
  name,
  value,
  checked,
  id,
  disabled = false,
  onChange,
  ...props
}) => {
  return (
    <input
      disabled={disabled}
      type="radio"
      name={name}
      value={value}
      checked={checked}
      id={id}
      onChange={onChange}
      className={`radio-button ${className}`}
    />
  );
};

export default RadioButton;
