import AnalyticsEvent from "./AnalyticsEvent";

abstract class LightStream implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Partnerships";
  readonly data: any = {};
}

export class LightStreamCTAClicked extends LightStream {
  readonly name = "Lightstream.HomeImprovementLoanModalSeen";
}
