import AnalyticsEvent from "./AnalyticsEvent";

abstract class AAAQuizEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "AAAQuiz";
  readonly data: any;

  constructor(quizId: number, propertyId: number) {
    this.data = { quizId, propertyId };
  }
}

export class QuizModalShownEvent extends AAAQuizEvent {
  readonly name = "AAAQuiz.ModalShown";
}

export class QuizSubmittedEvent extends AAAQuizEvent {
  readonly name = "AAAQuiz.QuizSubmitted";

  constructor(quizId: number, propertyId: number, results: string[]) {
    super(quizId, propertyId);
    this.data.results = results;
  }
}
