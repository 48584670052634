import { useTreatments } from "@splitsoftware/splitio-react";

/**
 * Provides an object mapping known splits to the values
 * they return when running the Split SDK in LOCAL mode.
 *
 * Changing the values has no impact on how the Split SDK behaves
 * when leveraging their API (such as in a deployed environemnt)
 *
 * Be careful of adding or removing entries from this object.  The
 * keys of this object act as an allow-list and specify which splits
 * are downloaded in all environments.
 */
export const splitTreatments = {
  "digs-app_show-movoto-landing-page": "on",
  "digs-app_alternate-signup-page": "on",
  "digs-app_alternate-insurance-announcement": "on",
};

type SplitName = keyof typeof splitTreatments;

/**
 * Returns the treatment value of a split for the current user
 * @param splitName name of the split to retrieve the treatment value of
 * @returns {string} either the treatment value or "control" if Split hasn't initialized or has encountered an error
 */
export const useTreatment = (splitName: SplitName) => {
  const treatments = useTreatments([splitName]);
  return treatments[splitName].treatment;
};
