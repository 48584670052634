import { ThumbtackChecklistTaskTagLine } from "@store/models/checklist";
import { HomeFundModel } from "@store/models/funds";

export const PROPERTY_LIMIT = 10;

export const daysOfWeek = [
  {
    title: "S",
    label: "Sunday",
    value: 0,
    disabled: true,
  },
  {
    title: "M",
    label: "Monday",
    value: 1,
  },
  {
    title: "T",
    label: "Tuesday",
    value: 2,
  },
  {
    title: "W",
    label: "Wednesday",
    value: 3,
  },
  {
    title: "T",
    label: "Thursday",
    value: 4,
  },
  {
    title: "F",
    label: "Friday",
    value: 5,
  },
  {
    title: "S",
    label: "Saturday",
    value: 6,
    disabled: true,
  },
];

export const daysOfMonth = Array.from(Array(28).keys());

export const propertyUses: {
  label: string;
  image: string;
  value: HomeFundModel["property_use"];
}[] = [
  {
    label: "Primary Residence",
    image: "/assets/images/global/home-use/primary.png",
    value: "primary",
  },
  {
    label: "Secondary Residence",
    image: "/assets/images/global/home-use/secondary.png",
    value: "secondary",
  },
  {
    label: "Investment Property",
    image: "/assets/images/global/home-use/investment.png",
    value: "investment",
  },
];

export const ownerPropertyUses: {
  [key in HomeFundModel["property_use"]]: {
    label: string;
    image: string;
    value: HomeFundModel["property_use"];
  };
} = {
  primary: {
    label: "Primary Residence",
    image: "/assets/images/global/home-use/primary.png",
    value: "primary",
  },
  secondary: {
    label: "Secondary Residence",
    image: "/assets/images/global/home-use/secondary.png",
    value: "secondary",
  },
  investment: {
    label: "Investment Property",
    image: "/assets/images/global/home-use/investment.png",
    value: "investment",
  },
};

export const propertyTypes: {
  [key in HomeFundModel["property_type"]]: {
    title: string;
    value: HomeFundModel["property_type"];
    variants: HomeFundModel["property_variant"][];
  };
} = {
  single: {
    title: "Single Family",
    value: "single",
    variants: [
      "suburban",
      "modern",
      "ranch",
      "lake",
      "beach",
      "row",
      "tudor",
      "victorian",
      "cottage",
      "cabin",
      "mobile",
      "vintage",
      "tiny",
    ],
  },
  townhouse: {
    title: "Townhouse",
    value: "townhouse",
    variants: ["row", "duplex", "complex"],
  },
  condo: {
    title: "Condo",
    value: "condo",
    variants: ["duplex", "complex", "midrise", "highrise"],
  },
  multi: {
    title: "Multi-family",
    value: "multi",
    variants: ["complex", "duplex", "midrise", "highrise"],
  },
  manufactured: {
    title: "Manufactured or Mobile",
    value: "manufactured",
    variants: ["mobile", "vintage", "tiny"],
  },
};

export const propertyVariants: {
  [key in HomeFundModel["property_variant"]]: {
    title: string;
    value: HomeFundModel["property_variant"];
  };
} = {
  suburban: {
    title: "Suburban",
    value: "suburban",
  },
  modern: {
    title: "Modern",
    value: "modern",
  },
  ranch: {
    title: "Ranch",
    value: "ranch",
  },
  lake: {
    title: "Lake House",
    value: "lake",
  },
  beach: {
    title: "Beach House",
    value: "beach",
  },
  row: {
    title: "Row House",
    value: "row",
  },
  tudor: {
    title: "Tudor",
    value: "tudor",
  },
  victorian: {
    title: "Victorian",
    value: "victorian",
  },
  cottage: {
    title: "Cottage",
    value: "cottage",
  },
  cabin: {
    title: "Log Cabin",
    value: "cabin",
  },
  mobile: {
    title: "Mobile Home",
    value: "mobile",
  },
  vintage: {
    title: "Vintage",
    value: "vintage",
  },
  tiny: {
    title: "Tiny House",
    value: "tiny",
  },
  duplex: {
    title: "Duplex Or Flat",
    value: "duplex",
  },
  complex: {
    title: "Complex",
    value: "complex",
  },
  midrise: {
    title: "Midrise",
    value: "midrise",
  },
  highrise: {
    title: "Highrise",
    value: "highrise",
  },
};

export const timelineOptions = [
  {
    label: "In the next 30 days",
    icon: "icon-stopwatch",
    value: "next-30-days",
  },
  {
    label: "In the next 3 months",
    icon: "icon-clock",
    value: "next-3-months",
  },
  {
    label: "In the next year",
    icon: "icon-calendar",
    value: "next-year",
  },
  {
    label: "In 1-2 years",
    icon: "icon-calendar-two",
    value: "next-1-2-years",
  },
  {
    label: "More than 2 years",
    icon: "icon-calendar-three",
    value: "more-than-2",
  },
];

export const creditScoreOptions = [
  {
    label: "Excellent (Above 740)",
    value: "excellent",
  },
  {
    label: "Good (Between 700 - 739)",
    value: "good",
  },
  {
    label: "Fair (Between 660 - 699)",
    value: "fair",
  },
  {
    label: "Poor (Less Than 659)",
    value: "poor",
  },
  {
    label: "Less Than 500",
    value: "less",
  },
];

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const loanTerms = [30, 20, 15, 10].map((value) => ({
  label: `${value} Year Fixed`,
  value,
}));

export const currentAaaQuizId = 4;
export const aaaQuizDelayDays = 7;
export const aaaReferralCode = "AAA";

export const propertyValueCacheTime = 60;

export const authTokenStorageKey = "authToken_v1";
export const sponsorStorageKey = "temp_sponsor_v1";
export const propertyStorageKey = "temp_property_v1";
export const uiStorageKey = "ui_state_v1";
export const userStorageKey = "temp_user_v1";
export const leadSourceStorageKey = "temp_lead_source_v1";

export const anonymousSplitUserStorageKey = "split_anonymous_key_v1";
export const springGreen = "#ABE629";
export const night = "#212121";
export const emerald = "#0E6959";
export const forest = "#00494F";
export const seafoam = "#C8F6EB";
export const concrete = "#F2F2F2";
export const aubergine = "#38003E";
export const mustard = "#EBB600";
export const raspberry = "#B51851";
export const ruby = "#DB2165";
export const grape = "#741B47";

export const thumbtackPopularSearches = [
  "Handyman",
  "House Cleaning",
  "Junk Removal",
  "Interior Painting",
  "Floor Installation or Replacement",
  "TV Mounting",
  "Bathroom Remodel",
  "Local Moving (under 50 miles)",
  "Appliance Repair or Maintenance",
  "Lawn Mowing and Trimming",
  "Electrical and Wiring Repair",
  "Furniture Moving and Heavy Lifting",
  "Furniture Assembly",
  "Plumbing Pipe Repair",
  "Outdoor Landscaping and Design",
  "Full Service Lawn Care",
];

export const THUMBTACK_UTM_SOURCE = "cma-ojo-homeowner";
export const THUMBTACK_UTM_CONTENT = "TTuser";

export const thumbtackTagLines: { [key in ThumbtackChecklistTaskTagLine]: { text: string; icon: string } } = {
  PREVENTS_FLOODING: {
    text: "Prevents flooding",
    icon: "icon-flood-factor",
  },
  PREVENTS_BREAKDOWNS: {
    text: "Prevents breakdowns",
    icon: "digs-icon-repairs",
  },
  IMPROVES_YOUR_HOME: {
    text: "Improves your home",
    icon: "icon-shield-star",
  },
  REDUCES_ELECTRIC_BILL: {
    text: "Reduces your electric bill",
    icon: "digs-icon-lightning-bolt",
  },
  PREVENTS_MOLD: {
    text: "Prevents mold",
    icon: "icon-shield-star",
  },
  PREVENTS_FIRES: {
    text: "Prevents fires",
    icon: "digs-icon-home-health",
  },
  IMPROVES_SAFETY: {
    text: "Improves safety",
    icon: "icon-shield-star",
  },
  SAVES_ENERGY_COSTS: {
    text: "Saves on energy costs",
    icon: "icon-property-light",
  },
  IMPROVES_AIR_QUALITY: {
    text: "Improves air quality",
    icon: "icon-home-heart",
  },
  PREVENTS_LEAKS: {
    text: "Prevents leaks",
    icon: "digs-icon-repairs",
  },
  BOOSTS_CURB_APPEAL: {
    text: "Boosts curb appeal",
    icon: "digs-icon-sparkle",
  },
};

export const stateCodesAndNames = [
  {
    code: "AL",
    name: "Alabama",
  },
  {
    code: "AK",
    name: "Alaska",
  },
  {
    code: "AZ",
    name: "Arizona",
  },
  {
    code: "AR",
    name: "Arkansas",
  },
  {
    code: "CA",
    name: "California",
  },
  {
    code: "CO",
    name: "Colorado",
  },
  {
    code: "CT",
    name: "Connecticut",
  },
  {
    code: "DE",
    name: "Delaware",
  },
  {
    code: "FL",
    name: "Florida",
  },
  {
    code: "GA",
    name: "Georgia",
  },
  {
    code: "HI",
    name: "Hawaii",
  },
  {
    code: "ID",
    name: "Idaho",
  },
  {
    code: "IL",
    name: "Illinois",
  },
  {
    code: "IN",
    name: "Indiana",
  },
  {
    code: "IA",
    name: "Iowa",
  },
  {
    code: "KS",
    name: "Kansas",
  },
  {
    code: "KY",
    name: "Kentucky",
  },
  {
    code: "LA",
    name: "Louisiana",
  },
  {
    code: "ME",
    name: "Maine",
  },
  {
    code: "MD",
    name: "Maryland",
  },
  {
    code: "MA",
    name: "Massachusetts",
  },
  {
    code: "MI",
    name: "Michigan",
  },
  {
    code: "MN",
    name: "Minnesota",
  },
  {
    code: "MS",
    name: "Mississippi",
  },
  {
    code: "MO",
    name: "Missouri",
  },
  {
    code: "MT",
    name: "Montana",
  },
  {
    code: "NE",
    name: "Nebraska",
  },
  {
    code: "NV",
    name: "Nevada",
  },
  {
    code: "NH",
    name: "New Hampshire",
  },
  {
    code: "NJ",
    name: "New Jersey",
  },
  {
    code: "NM",
    name: "New Mexico",
  },
  {
    code: "NY",
    name: "New York",
  },
  {
    code: "NC",
    name: "North Carolina",
  },
  {
    code: "ND",
    name: "North Dakota",
  },
  {
    code: "OH",
    name: "Ohio",
  },
  {
    code: "OK",
    name: "Oklahoma",
  },
  {
    code: "OR",
    name: "Oregon",
  },
  {
    code: "PA",
    name: "Pennsylvania",
  },
  {
    code: "RI",
    name: "Rhode Island",
  },
  {
    code: "SC",
    name: "South Carolina",
  },
  {
    code: "SD",
    name: "South Dakota",
  },
  {
    code: "TN",
    name: "Tennessee",
  },
  {
    code: "TX",
    name: "Texas",
  },
  {
    code: "UT",
    name: "Utah",
  },
  {
    code: "VT",
    name: "Vermont",
  },
  {
    code: "VA",
    name: "Virginia",
  },
  {
    code: "WA",
    name: "Washington",
  },
  {
    code: "WV",
    name: "West Virginia",
  },
  {
    code: "WI",
    name: "Wisconsin",
  },
  {
    code: "WY",
    name: "Wyoming",
  },
];
