import { uiStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import { defaultUiState, RegistrationModalProps, UiState } from "@store/models/ui";
import moment from "moment";
import React, { createContext, FC, useEffect, useState } from "react";

export interface UiContextInterface {
  uiState: UiState;
  setUiStateAttributes: (keysToUpdate: Partial<UiState>) => void;
  clearUiStateAttributes: () => void;
  openSponsorPanel: () => void;
  closeSponsorPanel: () => void;
  closeSponsorMessage: () => void;
  showLoginModal: () => void;
  hideLoginModal: () => void;
  showRegistrationModal: (registrationModalProps: RegistrationModalProps) => void;
  hideRegistrationModal: () => void;
  setDashboardWelcomeDismissed: () => void;
  setDashboardPropertyTypeDismissed: () => void;
}

const UiContext = createContext<UiContextInterface>({
  uiState: defaultUiState,
  setUiStateAttributes: () => {},
  clearUiStateAttributes: () => {},
  openSponsorPanel: () => {},
  closeSponsorPanel: () => {},
  closeSponsorMessage: () => {},
  showLoginModal: () => {},
  hideLoginModal: () => {},
  showRegistrationModal: () => {},
  hideRegistrationModal: () => {},
  setDashboardWelcomeDismissed: () => {},
  setDashboardPropertyTypeDismissed: () => {},
});

export const UiProvider: FC = (props) => {
  const [uiStateInStorage, setUiStateInStorage] = useLocalStorage(uiStorageKey, defaultUiState);
  const [uiState, setUiState] = useState<UiState>(uiStateInStorage);

  const setUiStateAttributes = (keysToUpdate: Partial<UiState>) => {
    setUiState({ ...uiStateInStorage, ...keysToUpdate });
  };

  const clearUiStateAttributes = () => {
    setUiStateInStorage(null);
  };

  const openSponsorPanel = () => {
    const dismissedAt = new Date();
    setUiState({
      ...uiStateInStorage,
      sponsor_panel: true,
      sponsor_message: false,
      sponsor_message_dismissed_at: dismissedAt,
    });
  };

  const closeSponsorPanel = () => {
    setUiState({ ...uiStateInStorage, sponsor_panel: false });
  };

  const closeSponsorMessage = () => {
    const dismissedAt = new Date();
    setUiState({ ...uiStateInStorage, sponsor_message: false, sponsor_message_dismissed_at: dismissedAt });
  };

  const showLoginModal = () => {
    setUiState({ ...uiStateInStorage, login_modal: true });
  };

  const hideLoginModal = () => {
    setUiState({ ...uiStateInStorage, login_modal: false });
  };

  const showRegistrationModal = (registration_modal_props: RegistrationModalProps) => {
    setUiState({ ...uiStateInStorage, registration_modal: true, registration_modal_props });
  };

  const hideRegistrationModal = () => {
    setUiState({ ...uiStateInStorage, registration_modal: false });
  };

  const setDashboardWelcomeDismissed = () => {
    setUiState({ ...uiStateInStorage, dashboard_welcome_dismissed: true });
  };

  const setDashboardPropertyTypeDismissed = () => {
    setUiState({ ...uiStateInStorage, dashboard_property_type_dismissed: true });
  };

  useEffect(() => {
    setUiStateInStorage(uiState);
  }, [uiState]);

  // If new UI keys don't exist in local storage, add the default value
  useEffect(() => {
    for (const key in defaultUiState) {
      if (!uiStateInStorage.hasOwnProperty(key)) {
        setUiState({ ...defaultUiState, ...uiStateInStorage });
      }
    }
  }, []);

  // show the sponsor message again after X amount of time has passed since it was last dismissed
  useEffect(() => {
    if (
      uiState.sponsor_message_dismissed_at &&
      moment(new Date()).isAfter(moment(uiStateInStorage.sponsor_message_dismissed_at).add(30, "days"))
    ) {
      setUiState({ ...uiStateInStorage, sponsor_message: true });
    }
  }, []);

  return (
    <UiContext.Provider
      value={{
        uiState,
        setUiStateAttributes,
        clearUiStateAttributes,
        openSponsorPanel,
        closeSponsorPanel,
        closeSponsorMessage,
        showLoginModal,
        hideLoginModal,
        showRegistrationModal,
        hideRegistrationModal,
        setDashboardWelcomeDismissed,
        setDashboardPropertyTypeDismissed,
      }}
    >
      {props.children}
    </UiContext.Provider>
  );
};

export default UiContext;
