import GoogleMapReact, { Coords, Props as GoogleMapProps } from "google-map-react";
import React, { FC, useEffect, useState } from "react";
import DigsHelper, { AddressComponents } from "../../../helpers/DigsHelper";
import "./Map.scoped.scss";
export interface IMap extends GoogleMapProps {
  address: string | AddressComponents;
  onMapLoadHandler?: (results: AddressComponents) => void;
  draggable?: boolean;
  zoomControl?: boolean;
  mapTypeControl?: boolean;
  scaleControl?: boolean;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  fullscreenControl?: boolean;
}

const Map: FC<IMap> = ({
  address = "",
  onMapLoadHandler,
  defaultZoom = 15,
  draggable = false,
  zoomControl = false,
  mapTypeControl = false,
  scaleControl = false,
  streetViewControl = false,
  rotateControl = false,
  fullscreenControl = false,
  ...props
}) => {
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  useEffect(() => {
    let isCancelled = false;

    if (typeof address === "string") {
      DigsHelper.getAddressComponents(address)
        .then((results: AddressComponents) => {
          if (!isCancelled) {
            if (onMapLoadHandler) {
              onMapLoadHandler(results);
            }
            setLatitude(results.latitude);
            setLongitude(results.longitude);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            console.error(error);
          }
        });
    } else {
      setLatitude(address.latitude);
      setLongitude(address.longitude);
      if (onMapLoadHandler) {
        onMapLoadHandler(address);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [address]);

  const createMapOptions = () => {
    return {
      draggable,
      zoomControl,
      mapTypeControl,
      scaleControl,
      streetViewControl,
      rotateControl,
      fullscreenControl,
    };
  };

  const center: Coords = {
    lat: latitude,
    lng: longitude,
  };

  const handleApiLoaded = (map: any, maps: any) => {
    new google.maps.Marker({
      position: center,
      map,
    });
  };

  // TODO: Use custom component for markers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Marker: FC<Coords> = () => <i className="digs-icon-digs logo digs-marker" />;

  if (latitude && longitude) {
    return (
      <GoogleMapReact
        key={`${longitude}${latitude}`}
        options={createMapOptions}
        bootstrapURLKeys={{ key: "AIzaSyBj6qYikzINoJYA0lFeXX29zGuk10vJlJU" }}
        defaultZoom={defaultZoom}
        center={{ lat: latitude, lng: longitude }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {props.children}
      </GoogleMapReact>
    );
  }

  return <div></div>;
};

export default Map;
