import { NavItemGroupProps } from "@components/global/navItemGroup/NavItemGroup";
import { UiContext } from "@components/utils/contexts";
import { OwnerPropertyModel } from "@store/models/properties";
import { RegistrationModalIntent } from "@store/models/ui";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetCountsQuery } from "@store/services/checklist";
import { useGetAllGenericFundsQuery, useGetAllHomeFundsQuery, useGetMaintenanceFundQuery } from "@store/services/funds";
import { useGetAllPropertiesQuery, useGetPropertyByUUIDQuery } from "@store/services/properties";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import { MouseEvent, useContext, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

const propertyNavItem = (args: {
  property: OwnerPropertyModel;
  taskCount: number;
  onClick?: (e: MouseEvent<HTMLAnchorElement>, intent?: RegistrationModalIntent) => void;
  expanded?: boolean;
  isLoggedIn: boolean;
}) => {
  const { property, taskCount, onClick, expanded = false, isLoggedIn } = args;
  const propertyTo = `/properties/${property.id}`;
  const title = isLoggedIn && property.details?.name ? property.details.name : property.street_address;
  return {
    name: title,
    to: propertyTo,
    onClick: onClick ? (e: MouseEvent<HTMLAnchorElement>) => onClick(e, "nav_property") : undefined,
    expandable: true,
    expanded,
    items: [
      {
        name: "Home value",
        to: `${propertyTo}/home-value`,
        onClick: onClick ? (e: MouseEvent<HTMLAnchorElement>) => onClick(e, "nav_home_value") : undefined,
        icon: "chart",
      },
      {
        name: "Mortgage",
        to: `${propertyTo}/mortgage`,
        onClick: onClick ? (e: MouseEvent<HTMLAnchorElement>) => onClick(e, "nav_mortgage") : undefined,
        icon: "percent",
      },
      {
        name: "Maintenance",
        to: `${propertyTo}/maintenance`,
        onClick: onClick ? (e: MouseEvent<HTMLAnchorElement>) => onClick(e, "nav_maintenance") : undefined,
        icon: "checklist",
        badgeText: taskCount ? taskCount.toString() : "",
      },
    ],
  };
};

export const useNavItems = (readyForUser: boolean) => {
  const { showRegistrationModal } = useContext(UiContext);
  const isLoggedIn = useIsLoggedIn();
  const ownerPageMatch = useRouteMatch<{ uuid: string }>("/owner/:uuid");
  const { data: properties } = useGetAllPropertiesQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { data: unownedProperty } = useGetPropertyByUUIDQuery(ownerPageMatch ? ownerPageMatch.params.uuid : "", {
    skip: !ownerPageMatch,
  });
  const { data: user } = useGetUserIfLoggedInQuery(undefined);
  const { data: maintenanceFund } = useGetMaintenanceFundQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { data: genericFunds } = useGetAllGenericFundsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { data: homeFunds } = useGetAllHomeFundsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { data: taskCounts } = useGetCountsQuery(undefined, {
    skip: !isLoggedIn,
  });

  const userOwnsProperty =
    user && (!ownerPageMatch || !unownedProperty || unownedProperty.owner_unique_id === `consumer-${user.id}`);

  const handleShowRegistrationModal = (event: MouseEvent<HTMLAnchorElement>, intent?: RegistrationModalIntent) => {
    event.preventDefault();
    showRegistrationModal({
      intent,
    });
  };

  let onClick: (e: MouseEvent<HTMLAnchorElement>) => void | undefined;
  if (isLoggedIn) {
    if (!userOwnsProperty) {
      //TODO claim modal here
    }
  } else {
    onClick = handleShowRegistrationModal;
  }

  const navItems: NavItemGroupProps[] = useMemo(() => {
    const items: NavItemGroupProps[] = (ownerPageMatch && unownedProperty ? [unownedProperty] : properties || []).map(
      (property) =>
        propertyNavItem({
          property,
          taskCount: (taskCounts && taskCounts[property.id.toString()]) || 0,
          onClick,
          expanded: unownedProperty ? true : false,
          isLoggedIn,
        })
    );

    if (isLoggedIn && maintenanceFund && readyForUser) {
      items.push({
        name: "Maintenance Fund",
        to: "/savings-funds/maintenance",
      });
    }

    //show generic funds below home funds
    homeFunds.concat(genericFunds).forEach((fund) => {
      items.push({
        name: fund.name || "Fund",
        to: `/savings-funds/${fund.id}`,
      });
    });

    return items;
  }, [properties, homeFunds, genericFunds, isLoggedIn, unownedProperty, taskCounts, ownerPageMatch, readyForUser]);

  return navItems;
};
