import { Card } from "@components/global";
import DigsHelper from "@helpers/DigsHelper";
import usePlaid from "@helpers/Plaid/usePlaid";
import { AccountModel } from "@store/models/accounts";
import { useGetAllAccountsQuery, useGetFundingSourceQuery } from "@store/services/accounts";
import React, { FC, MouseEventHandler } from "react";
import "./FundingSourceCard.scoped.scss";

const WithoutFundingSource: FC<{
  openPlaid: MouseEventHandler;
  loading: boolean;
  noFundText?: string;
}> = ({ openPlaid, loading, noFundText }) => {
  return (
    <Card className="funding-source-card">
      <Card.Body>
        <div className="no-funding">
          <img src="/assets/images/global/icons/bank.svg" alt="bank" className="no-funding--icon" />
          {noFundText ? (
            <span className="funding-source-card__empty">{noFundText}</span>
          ) : (
            <span className="funding-source-card__empty">You do not have a funding source</span>
          )}
        </div>
        <Card.FooterButton title="Add Funding Source" onClick={openPlaid} loading={loading} />
      </Card.Body>
    </Card>
  );
};

const WithFundingSource: FC<{
  openPlaid: MouseEventHandler;
  source: AccountModel;
  loading: boolean;
}> = ({ openPlaid, source, loading }) => {
  const subtype = source.subtype ? source.subtype?.charAt(0).toUpperCase() + source.subtype?.substring(1) : "";

  const title = [source.institution_name, subtype, `****${source.mask}`].join(" ");

  return (
    <Card className="funding-source-card">
      <Card.Body>
        <div className="funding-source-card__meta">
          <Card.Meta
            icon={
              source.institution_logo
                ? `data:image/png;base64,${source.institution_logo}`
                : "https://app.digs.co/images/branding/digs-saver-icon.svg"
            }
            title={title}
            subtitle={`Added on ${DigsHelper.formatISODate(source.created_at)}`}
          />
        </div>
        <Card.FooterButton
          title="Replace Funding Source"
          onClick={openPlaid}
          icon={"digs-icon-edit"}
          loading={loading}
        />
      </Card.Body>
    </Card>
  );
};

interface FundingSourceCardProps {
  noFundText?: string;
}

const FundingSourceCard: FC<FundingSourceCardProps> = ({ noFundText }) => {
  const { openPlaid, ready, isPlaidOpen, isCreatingAccount, isGeneratingToken } = usePlaid();

  const { isFetching: accountsFetching } = useGetAllAccountsQuery(undefined);

  const { data: fundingSource } = useGetFundingSourceQuery();

  const loading = isPlaidOpen || accountsFetching || isCreatingAccount || isGeneratingToken;

  return fundingSource ? (
    <WithFundingSource openPlaid={openPlaid} source={fundingSource} loading={loading} />
  ) : (
    <WithoutFundingSource openPlaid={openPlaid} loading={!!(loading && ready)} noFundText={noFundText} />
  );
};

export default FundingSourceCard;
