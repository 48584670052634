import { LeadModel } from "@store/models/properties";
import { SponsorModel } from "@store/models/sponsor";
import { baseApi } from "@store/services/base";

const transformSingleResponse = (data: { sponsor: SponsorModel }) => data.sponsor;

const baseUrl = "consumer/v1/sponsor";

export const sponsorApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSponsor: builder.query({
      query: () => baseUrl,
      transformResponse: transformSingleResponse,
      providesTags: ["Sponsor"],
    }),
    lookupSponsor: builder.query({
      query: ({ primary, secondary }) => `${baseUrl}/lookup/${secondary ? `${primary}/${secondary}` : `${primary}`}`,
      transformResponse: transformSingleResponse,
      providesTags: ["Sponsor"],
    }),
    getSponsorByUUID: builder.query({
      query: (uuid) => `consumer/v1/owner/${uuid}/sponsor`,
      transformResponse: transformSingleResponse,
      providesTags: ["Sponsor"],
    }),
    contactSponsor: builder.mutation({
      query: (body: LeadModel) => ({
        url: `${baseUrl}/contact`,
        method: "POST",
        body,
      }),
    }),
    removeSponsor: builder.mutation({
      query: () => ({
        url: `${baseUrl}/remove`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["Sponsor"],
    }),
  }),
});

export const {
  useGetSponsorQuery,
  useLookupSponsorQuery,
  useGetSponsorByUUIDQuery,
  useContactSponsorMutation,
  useRemoveSponsorMutation,
} = sponsorApi;
