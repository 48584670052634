import { Card } from "@components/global";
import React, { FC } from "react";
import "./WarningNotificationCard.scss";

interface WarningNotificationCard {
  message?: string;
  className?: string;
}

const WarningNotificationCard: FC<WarningNotificationCard> = ({ message = "I'm a warning" }) => {
  return (
    <Card className="warning-notification">
      <Card.Body>
        <div>
          <img src="/assets/images/global/icons/exclamation.svg" alt="bank" className="no-funding--icon" />
        </div>
        <span>{message}</span>
      </Card.Body>
    </Card>
  );
};

export default WarningNotificationCard;
