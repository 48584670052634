import { THUMBTACK_UTM_SOURCE } from "@helpers/Constants";
import { ChecklistCount, ChecklistCounts, ChecklistTask, isDigsItem, isThumbtackItem } from "@store/models/checklist";
import { isAfter, parseISO, subDays } from "date-fns";
import { baseApi } from "./base";

const baseUrl = "/consumer/v1";

export const checklistApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTasks: builder.query<ChecklistTask[], { id: number; utm_source?: string }>({
      query: ({ id, utm_source = THUMBTACK_UTM_SOURCE }) =>
        `${baseUrl}/properties/${id}/tasks?utm_source=${utm_source}`,
      transformResponse: ({ tasks }) => tasks.map((task: ChecklistTask) => normalizeTaskData(task)),
      providesTags: ["Tasks"],
    }),
    getTasksByUUID: builder.query<ChecklistTask[], { uuid: string; utm_source?: string }>({
      query: ({ uuid, utm_source = THUMBTACK_UTM_SOURCE }) => `${baseUrl}/owner/${uuid}/tasks?utm_source=${utm_source}`,
      transformResponse: ({ tasks }) => tasks.map((task: ChecklistTask) => normalizeTaskData(task)),
      providesTags: ["Tasks"],
    }),
    getCounts: builder.query<ChecklistCounts, void>({
      query: () => `${baseUrl}/tasks/counts`,
      transformResponse: ({ counts }: { counts: ChecklistCount[] }) =>
        Object.fromEntries(counts.map(({ property_id, count }) => [property_id, count])),
      providesTags: ["ChecklistCounts"],
    }),
    updateTask: builder.mutation<ChecklistTask, Partial<ChecklistTask> & { id: number; property_id: number }>({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/tasks/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: ({ task }) => normalizeTaskData(task),
      invalidatesTags: ["ChecklistCounts"],
      async onQueryStarted({ id, property_id, ...patch }, { dispatch, queryFulfilled }) {
        // When we start the request, just immediately update the cache
        const patchResult = dispatch(
          checklistApi.util.updateQueryData("getTasks", { id: property_id }, (draft) => {
            return draft.map((task) => (task.id === id ? { ...task, ...patch } : task));
          })
        );
        try {
          const { data: updatedTask } = await queryFulfilled;
          dispatch(
            checklistApi.util.updateQueryData("getTasks", { id: property_id }, (draft) => {
              return draft.map((task) => (task.id === id ? { ...updatedTask } : task));
            })
          );
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    updateManyTasks: builder.mutation<
      ChecklistTask[],
      { tasks: (Partial<ChecklistTask> & { id: number })[]; property_id: number }
    >({
      query: ({ tasks, property_id }) => ({
        url: `${baseUrl}/tasks`,
        method: "PATCH",
        body: {
          tasks,
        },
      }),
      transformResponse: ({ tasks }) => tasks.map((task: ChecklistTask) => normalizeTaskData(task)),
      invalidatesTags: ["ChecklistCounts", "PropertyMetrics"],
      async onQueryStarted({ tasks, property_id }, { dispatch, queryFulfilled }) {
        const tasksTable = Object.fromEntries(tasks.map((task) => [task.id, task]));
        // When we start the request, just immediately update the cache
        const patchResult = dispatch(
          checklistApi.util.updateQueryData("getTasks", { id: property_id }, (draft) => {
            return draft.map((task) => (tasksTable[task.id] ? { ...task, ...tasksTable[task.id] } : task));
          })
        );
        try {
          const { data: updatedTasks } = await queryFulfilled;
          const updatedTasksTable = Object.fromEntries(updatedTasks.map((task) => [task.id, task]));
          dispatch(
            checklistApi.util.updateQueryData("getTasks", { id: property_id }, (draft) => {
              return draft.map((task) =>
                updatedTasksTable[task.id] ? { ...task, ...updatedTasksTable[task.id] } : task
              );
            })
          );
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTasksByUUIDQuery,
  useGetCountsQuery,
  useUpdateTaskMutation,
  useUpdateManyTasksMutation,
} = checklistApi;

export const normalizeTaskData = (item: ChecklistTask): ChecklistTask => {
  if (isThumbtackItem(item)) {
    return {
      ...item,
      imageUrl: item.rawData.imageURL,
      url: item.rawData.url,
      cost: item.rawData.taskDetails.avgCost.min,
      categoryName: item.rawData.categoryName,
      categoryDisplayName: item.rawData.categoryDisplayName,
      description: item.rawData.taskDetails.description,
      tagLine: item.rawData.taskDetails.tagline,
      isNew: !item.completed_at && !item.read_at && isAfter(parseISO(item.created_at), subDays(new Date(), 30)),
    };
  } else if (isDigsItem(item)) {
    //TODO fill in values when we decide the shape of digs items
    return {
      ...item,
      imageUrl: "",
      url: "",
      cost: 0,
      categoryName: "",
      categoryDisplayName: "",
      description: "",
      tagLine: "IMPROVES_YOUR_HOME",
      isNew: !item.completed_at && !item.read_at && isAfter(parseISO(item.created_at), subDays(new Date(), 30)),
    };
  } else {
    return item;
  }
};
