import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);

      /* For Three Column Layout */
      const threeColumnLayout = document.querySelector(".three-column-layout");
      if (threeColumnLayout) {
        threeColumnLayout.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  });
  return null;
}

export default withRouter(ScrollToTop);
