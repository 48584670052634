import AnalyticsEvent from "./AnalyticsEvent";

export type PageData = {
  pathname: string;
  search: string;
  hash: string;
};

export default class PageviewEvent implements AnalyticsEvent {
  readonly name = "Pageview";
  readonly category = "Pageview";
  data;

  constructor(pageviewData: PageData) {
    this.data = pageviewData;
  }
}
