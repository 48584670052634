import React, { FC } from "react";
import "./MaintenanceOverlay.scoped.scss";

interface MaintenanceOverlay {
  flexDirection?: "row" | "column";
  message?: string;
  background?: string;
}

const MaintenanceOverlay: FC<MaintenanceOverlay> = ({
  flexDirection = "column",
  background = "rgba(255, 255, 255, 0.9)",
  message,
  ...props
}) => {
  return (
    <div className={`maintenance-overlay`} style={{ flexDirection, background }}>
      <img src="/assets/images/global/icons/exclamation.svg" alt="warning" className="no-funding--icon" />
      {message && (
        <p>
          <b>{message}</b>
        </p>
      )}
      {props.children}
    </div>
  );
};

export default MaintenanceOverlay;
