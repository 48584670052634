import React, { FC } from "react";
import "./CardForm.scoped.scss";

interface CardForm {}

const CardForm: FC<CardForm> = ({ children, ...props }) => {
  return (
    <div className="card-form" {...props}>
      {children}
    </div>
  );
};

export default CardForm;
