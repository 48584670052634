import { StatusModel, useGetAllStatusesQuery } from "@store/services/statuses";
import React, { createContext, FC } from "react";
export interface StatusContext {
  statuses: StatusModel[] | undefined;
  maintenanceMode: boolean;
}

const StatusContext = createContext<StatusContext>({
  statuses: [],
  maintenanceMode: false,
});

export const StatusProvider: FC = (props) => {
  const { data: statuses } = useGetAllStatusesQuery(undefined, {
    pollingInterval: 30000,
  });

  // If any service has a status of 'critical' maintenance mode is true
  const downedServices = statuses ? statuses.filter((service: any) => service.status === "critical") : [];
  // TODO Make more specific to each service
  const maintenanceMode = downedServices.length > 0;

  return <StatusContext.Provider value={{ statuses, maintenanceMode }}>{props.children}</StatusContext.Provider>;
};

export default StatusContext;
