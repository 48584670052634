import AnalyticsEvent from "./AnalyticsEvent";

abstract class HomeValueFlowEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "User";
  readonly data = { flow: "home-value" };
}

export class UserProvidedAddressEvent extends HomeValueFlowEvent {
  readonly name = "HomeValueFlow.UserProvidedAddress";
}

export class UserProvidedUnitNumberEvent extends HomeValueFlowEvent {
  readonly name = "HomeValueFlow.UserProvidedUnitNumber";
}

export class UserSubscribedEvent extends HomeValueFlowEvent {
  readonly name = "HomeValueFlow.UserSubscribed";
}

export class UserCreatedAccountEvent extends HomeValueFlowEvent {
  readonly name = "HomeValueFlow.UserCreatedAccount";
}
