import React, { FC, MouseEvent, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Button from "../form/button/Button";

export interface NavItemProps {
  to?: string;
  href?: string;
  name: string;
  icon?: string;
  badgeText?: string;
  className?: string;
  parent?: boolean;
  expanded?: boolean;
  onToggleExpanded?: (e: MouseEvent<HTMLButtonElement>) => void;
  newTab?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const NavItem: FC<NavItemProps> = ({
  name,
  to,
  href = "#",
  icon,
  badgeText,
  className = "",
  parent = false,
  expanded = false,
  onToggleExpanded,
  newTab = false,
  onClick,
  ...props
}) => {
  const location = useLocation();

  const onClickExpand = useCallback(
    (e) => {
      if (onToggleExpanded) {
        onToggleExpanded(e);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [onToggleExpanded]
  );

  const content = (
    <>
      {icon ? (
        <div className="navigation__icon">
          <i className={`digs-icon-${icon}`} />
        </div>
      ) : null}
      <span>{name}</span>
      {badgeText ? (
        <div className="navigation__item__badge">
          <span>{badgeText}</span>
        </div>
      ) : null}
      {parent && onToggleExpanded ? (
        <Button
          theme={to && parent && location.pathname.indexOf(to) > -1 ? "outline-primary" : "outline-secondary"}
          iconOnly={true}
          className="navigation__expand"
          onClick={onClickExpand}
        >
          <i className={expanded ? "digs-icon-up" : "digs-icon-down"} />
        </Button>
      ) : null}
      {parent ? null : <div className="navigation__caret"></div>}
    </>
  );

  return (
    <li className={`navigation__item ${className} ${parent ? "navigation__item-group-parent" : ""}`}>
      {to ? (
        <NavLink
          to={to}
          onClick={onClick}
          exact
          activeClassName="navigation__item--active"
          className={parent && location.pathname.indexOf(to) > -1 ? "navigation__item--subitem-active" : ""}
        >
          {content}
        </NavLink>
      ) : (
        <a href={href} target={newTab ? "_blank" : ""} rel="noopener noreferrer" onClick={onClick}>
          {content}
        </a>
      )}
    </li>
  );
};

export default NavItem;
