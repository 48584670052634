import { useLocation } from "react-router-dom";

export interface SearchParamTypes {
  showRegistrationModal?: string;
  aaaquiz?: string;
  uuid?: string;
  address?: string;
  product?: string;
  r?: string;
  fundId?: string;
  invite_token?: string;
  "link-mortgage-modal"?: string;
  firstTimeReport?: string;
  /* Lead Source */
  utm_source?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_campaign?: string;
  csat?: string;
  showLightStreamModal?: string;
}

function useSearchParams<Params extends { [K in keyof Params]?: string } = {}>(): Params {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  //@ts-ignore
  return Object.fromEntries(searchParams.entries());
}

export default useSearchParams;
