import React, { ChangeEvent, FC, FocusEvent, TextareaHTMLAttributes } from "react";
import "./TextArea.scoped.scss";

export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<TextAreaProps> = ({ className, error, ...props }) => {
  return <textarea {...props} className={`${className || ""} textarea-input`} />;
};

export default TextArea;
