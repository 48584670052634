import React, { ChangeEvent, FC, FocusEvent, useCallback, useState } from "react";
import InputMask from "react-input-mask-3.0";
import "./PhoneInput.scoped.scss";

export interface PhoneInputProps {
  id?: string;
  label?: string;
  name?: string;
  size?: "small" | "large";
  value: any;
  error?: boolean;
  theme?: "default" | "dark";
  showPlaceholder?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement> | undefined) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
}

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  name,
  size = "small",
  value,
  error = false,
  theme = "default",
  showPlaceholder = true,
  onBlur,
  onFocus,
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  const onBlurHandler = useCallback((e?: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }, []);

  const onFocusHandler = useCallback((e?: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  }, []);

  return (
    <div
      className={`phone-input phone-input--theme-${theme} ${size} ${focused || value ? "active" : ""} ${
        error ? "errors" : ""
      }`}
    >
      <InputMask
        {...props}
        value={value}
        type="tel"
        mask="(999) 999-9999"
        maskPlaceholder=" "
        placeholder={showPlaceholder ? "(999) 999-9999" : ""}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      />
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`phone-input--line ${focused ? "focused" : ""}`} />
    </div>
  );
};

export default PhoneInput;
