import { FormikErrors } from "formik";
import React, { ChangeEvent, FC, FocusEvent, useCallback, useState } from "react";
import "./TextInput.scoped.scss";

interface TextInput {
  id?: string;
  type?: string;
  label?: string;
  name?: string;
  value: any;
  errors?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  autoComplete?: any;
  autoFocus?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
}

const TextInput: FC<TextInput> = ({ onBlur, onFocus, value, errors, name, label, ...props }) => {
  const [focused, setFocused] = useState<boolean>(false);

  const onBlurHandler = useCallback((e?: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }, []);

  const onFocusHandler = useCallback((e?: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  }, []);

  return (
    <div className={`text-input ${focused || value || value === 0 ? "active" : ""} ${errors ? "errors" : ""}`}>
      <input {...props} value={value} onFocus={onFocusHandler} onBlur={onBlurHandler} />
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`text-input--line ${focused ? "focused" : ""}`} />
    </div>
  );
};

export default TextInput;
