import { createNotification } from "@components/utils/notifications/Notifications";
import { useCreateAccountMutation } from "@store/services/accounts";
import { PlaidTokenModel, useGenerateTokenMutation } from "@store/services/plaid";
import { useEffect, useState } from "react";
import { usePlaidLink } from "./usePlaidLink";

export default function usePlaid(isFundingSource = true) {
  const [token, setToken] = useState<string | null>(null);

  const [createAccount, { isLoading: isCreatingAccount }] = useCreateAccountMutation();
  const [generateToken, { isLoading: isGeneratingToken }] = useGenerateTokenMutation();

  useEffect(() => {
    let isCancelled = false;

    generateToken(true)
      .unwrap()
      .then(({ link_token }: PlaidTokenModel) => {
        if (!isCancelled) {
          setToken(link_token);
        }
      })
      .catch((err) => {});

    return () => {
      isCancelled = true;
    };
  }, []);

  const onSuccessHandler = (public_token: string, metadata: any) => {
    createAccount({
      metadata,
      funding_source: isFundingSource,
    })
      .unwrap()
      .then(() => {
        createNotification("success", "Success!", "Bank account successfully connected.");
      })
      .catch(() => {
        createNotification(
          "danger",
          "Whoops!",
          "An error occurred while processing your credentials. Please try again."
        );
      });
  };

  const onExitHandler = () => {};

  const { open, ready, error, isPlaidOpen } = usePlaidLink({
    token: token || "",
    onSuccess: onSuccessHandler,
    onExit: onExitHandler,
  });

  const onClickHandler = () => {
    open();
  };

  return {
    openPlaid: onClickHandler,
    ready,
    error,
    isPlaidOpen,
    isCreatingAccount,
    isGeneratingToken,
  };
}
