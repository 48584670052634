import { Back, Button, Feedback, TextInputFormItem } from "@components/global";
import { getQueryError } from "@helpers/Errors";
import Yup from "@helpers/Yup";
import { useLogin } from "@store/services/auth";
import { useCreatePropertyMutation } from "@store/services/properties";
import { useFormik } from "formik";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { createNotification } from "../../../../utils/notifications/Notifications";
import "./Forms.scoped.scss";

interface Login {
  formValues: any;
  onSubmit?: (values: any, step?: null | number) => void;
  onBack: () => void;
  step?: number;
  currentStep?: number;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email address required"),
  password: Yup.string().required("Password required"),
});

const Login: FC<Login> = ({ formValues, onSubmit, onBack, step, currentStep }) => {
  const history = useHistory();
  const [createProperty, { isLoading: propertyCreating }] = useCreatePropertyMutation();
  const [login, { isLoading: loginLoading, isError: loginErrored, error: loginError }] = useLogin();

  const loading = loginLoading || propertyCreating;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: formValues.email,
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      login({ email: values.email, password: values.password })
        .unwrap()
        .then(() => {
          onCreatePropertyHandler(formValues);
        })
        .catch((error) => {});
    },
  });

  const onCreatePropertyHandler = (values: any) => {
    createProperty({ ...values, type: "owner" })
      .unwrap()
      .then((property) => {
        createNotification("success", "Success!", "Your new property has been created.");
        history.push(`/properties/${property.id}`);
      })
      .catch(() => {
        createNotification("danger", "Whoops!", "We were unable to create your new property. Please try again.");
        history.push(`/dashboard`);
      });
  };

  return (
    <div className={`signup-onboarding--home-value-step landing-page__login-container`}>
      <h3>
        It looks like you already have an account. <br />
        <b>Log in to add this property.</b>
      </h3>
      {loginErrored && <Feedback type="error" content={getQueryError(loginError)} />}
      <TextInputFormItem
        errors={formik.errors.email}
        touched={!!formik.touched.email}
        name="email"
        label="Email Address"
        type="email"
        autoComplete="username"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
      <TextInputFormItem
        errors={formik.errors.password}
        touched={!!formik.touched.password}
        name="password"
        label="Password"
        type="password"
        autoComplete="current-password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
      />
      <div className="signup-onboarding--actions landing-page__login-buttons">
        <div className="landing-page__back-button">
          <Back type="button" onClick={onBack} />
        </div>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
          loading={loading}
          disabled={!(formik.isValid && formik.dirty)}
        >
          Log In
        </Button>
      </div>
      <Link to="/forgot-password" className="forgot-password">
        Forgot Password?
      </Link>
    </div>
  );
};

export default Login;
