import React, { FC } from "react";
import "./CardExpand.scss";

interface CardExpand {
  icon?: string;
  title?: string;
  subtitle?: string;
  expanded?: boolean;
}

const CardExpand: FC<CardExpand> = ({ children, expanded, ...props }) => {
  return (
    <div className={`card-expand ${expanded ? "expanded" : ""}`} {...props}>
      <div className={"card-expand--drawer"}>{children}</div>
    </div>
  );
};

export default CardExpand;
