import { SuspenseLoader } from "@components/global";
import config from "@config";
import Analytics from "@helpers/analytics";
import { anonymousSplitUserStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import { splitTreatments } from "@helpers/Split";
import { SplitClient, SplitContext, SplitFactory, useTreatments } from "@splitsoftware/splitio-react";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import React, { FC, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./assets/scss/index.scss";

const WaitForSplit: FC = (props) => {
  const [renderChildren, setRenderChildren] = useState(false);
  const treatments = useTreatments(Object.keys(splitTreatments));
  const splitContext = useContext(SplitContext);
  // isReady is true on success, need to consult isTimedout in case of errors
  const isSplitReady = splitContext.isReady || splitContext.isTimedout;

  useEffect(() => {
    if (isSplitReady) {
      Object.entries(treatments).forEach(([key, value]) => {
        Analytics.setSharedEventProperties({
          [`split.${key}`]: value.treatment,
        });
      });
      setRenderChildren(true);
    }
  }, [isSplitReady, treatments]);

  return renderChildren ? <>{props.children}</> : <SuspenseLoader />;
};

const SplitWrapper: FC = (props) => {
  const loggedIn = useIsLoggedIn();
  const { data: user } = useGetUserIfLoggedInQuery(undefined);
  // We're required to have a non-empty splitKey
  const [splitKey, setSplitKey] = useState<string>("INITIALIZING");
  const [anonymousSplitUserKey, setAnonymousSplitUserKey] = useLocalStorage(anonymousSplitUserStorageKey, undefined);

  useEffect(() => {
    if (loggedIn && user && user.unique_id) {
      return setSplitKey(user.unique_id);
    }

    if (!loggedIn) {
      if (!anonymousSplitUserKey) {
        setAnonymousSplitUserKey(uuidv4());
      }
      return setSplitKey(anonymousSplitUserKey);
    }
  }, [loggedIn, user, anonymousSplitUserKey]);

  const splitConfig = {
    core: {
      authorizationKey: config.SPLIT_IO_AUTH_KEY,
      key: splitKey,
    },
    startup: {
      readyTimeout: 1,
    },
    sync: {
      splitFilters: [
        {
          type: "byName",
          values: Object.keys(splitTreatments),
        },
      ],
    },
    features: splitTreatments,
  };

  return (
    <SplitFactory config={splitConfig}>
      <SplitClient splitKey={splitKey} updateOnSdkTimedout={true}>
        <WaitForSplit>{props.children}</WaitForSplit>
      </SplitClient>
    </SplitFactory>
  );
};

export default SplitWrapper;
