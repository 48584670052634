import { FundModel } from "@store/models/funds";
import AnalyticsEvent from "./AnalyticsEvent";

abstract class NewProductFlowEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Product";
  readonly data: any = {};
}

export class UserStartedNewProduct extends NewProductFlowEvent {
  readonly name = "Product.UserStartedNewProduct";
}

export class UserStartedNewFund extends NewProductFlowEvent {
  readonly name = "Product.UserStartedMaintenanceFund";

  constructor(fundType: FundModel["type"]) {
    super();
    this.data.fundType = fundType;
  }
}

export class UserProvidedAddressEvent extends NewProductFlowEvent {
  readonly name = "Product.UserProvidedAddress";
}

export class UserProvidedUnitNumberEvent extends NewProductFlowEvent {
  readonly name = "Product.UserProvidedUnitNumber";
}

export class NewPropertyCreatedEvent extends NewProductFlowEvent {
  readonly name = "Product.UserCreatedNewProperty";
}

export class NewFundCreatedEvent extends NewProductFlowEvent {
  readonly name = "Product.UserCreatedNewFund";

  constructor(fundType: FundModel["type"]) {
    super();
    this.data.fundType = fundType;
  }
}
