import React, { ChangeEvent, FC, FocusEvent, forwardRef, MouseEvent, Ref, useCallback, useState } from "react";
import "./TextInput.scoped.scss";

export interface ITextInput {
  id?: string;
  type?: string;
  label?: string;
  size?: "default" | "small" | "large";
  justify?: "left" | "center" | "right";
  name?: string;
  inputMode?: "text" | "numeric" | "none" | "tel" | "url" | "email" | "decimal" | "search";
  showLabel?: boolean;
  jost?: boolean;
  theme?: "default" | "box" | "dark";
  value: any;
  className?: string;
  success?: boolean;
  error?: boolean;
  required?: boolean;
  autoComplete?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  ref?: Ref<HTMLInputElement>;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
  onClick?: (e?: MouseEvent) => void;
}

const TextInput: FC<ITextInput> = forwardRef(
  (
    {
      onBlur,
      onFocus,
      inputMode = "text",
      showLabel = true,
      size = "default",
      justify = "left",
      jost = false,
      theme = "default",
      value,
      success = false,
      error = false,
      required = false,
      name,
      label,
      className = "",
      readOnly = false,
      ...props
    },
    ref: Ref<HTMLInputElement>
  ) => {
    const [focused, setFocused] = useState<boolean>(false);

    const onBlurHandler = useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur, setFocused]
    );

    const onFocusHandler = useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        if (onFocus) {
          onFocus(e);
        }
      },
      [onFocus, setFocused]
    );

    //TODO fix bem classnames
    return (
      <div
        className={`text-input ${focused || (value != null && value !== "") ? "text-input--active" : ""} ${
          jost ? "text-input--jost" : ""
        } text-input--theme-${theme} ${error ? "text-input--errors" : ""} ${className} ${
          success ? "text-input--success" : ""
        } ${readOnly ? "text-input--read-only" : ""} text-input--size-${size} text-input--justify-${justify}`}
      >
        <input
          {...props}
          inputMode={inputMode}
          name={name}
          ref={ref}
          value={value}
          readOnly={readOnly}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          aria-invalid={error}
          aria-required={required}
        />
        {label && !showLabel && (
          <label htmlFor={name} className="sr-only">
            {label}
          </label>
        )}
        {label && showLabel && <label htmlFor={name}>{label}</label>}
        <div className={`text-input__line ${focused ? "text-input__line--focused" : ""}`} />
      </div>
    );
  }
);

export default TextInput;
