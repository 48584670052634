import { ChecklistTask } from "@store/models/checklist";
import { format, parseISO } from "date-fns";
import React, { FC } from "react";
import "./Timeline.scoped.scss";

interface TimelinePropsInterface {
  className?: string;
  items: ChecklistTask[];
  onClickItem?: (item: ChecklistTask) => void;
}

const Timeline: FC<TimelinePropsInterface> = ({ items, className, onClickItem }) => {
  return (
    <>
      <ul className={`timeline ${className ? className : ""}`}>
        {items.map((item, i) => {
          const showTail = i !== 0;
          return (
            <li
              className="timeline__item"
              key={`${item.id}`}
              onClick={() => {
                onClickItem && onClickItem(item);
              }}
            >
              <div className={`timeline__item-progress`}>
                <div className="timeline__item-progress--circle">
                  <i className="digs-icon-circle-checkmark-filled" />
                </div>
                {showTail && <div className="timeline__item-progress--tail" />}
              </div>
              <div className="timeline__item-content">
                <span className="timeline__item-title">{item.name}</span>
                <span className="timeline__item-subtitle">{`Completed on ${format(
                  parseISO(item.completed_at || ""),
                  "LLLL dd, yyyy"
                )}`}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Timeline;
