import { StatusContext } from "@components/utils/contexts";
import DigsHelper from "@helpers/DigsHelper";
import React, { FC, useContext, useState } from "react";
import "./Balance.scoped.scss";

interface Balance {
  currentBalance: number;
  availableBalance: number;
  pendingBalance: number;
  className?: string;
}

const Balance: FC<Balance> = ({ currentBalance, availableBalance, pendingBalance, className }) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const { maintenanceMode } = useContext(StatusContext);

  if (maintenanceMode) {
    return (
      <div className="balance">
        <div className="balance__warning">
          <img src="/assets/images/global/icons/exclamation.svg" alt="maintenance" />
          <h3>We’re undergoing maintenance</h3>
          <p>Our banking services are currently undergoing maintenance, please try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`balance ${toggle ? "balance--toggled" : ""} ${className ? className : ""}`}
      onClick={() => setToggle(!toggle)}
    >
      <div className="balance__container">
        <div className="balance__item">
          <div className="balance__item-inner">
            <div className="balance__column">
              <div className="balance__lockup-value">{DigsHelper.formatMoney(currentBalance, 2)}</div>
              <div className="balance__lockup-subtitle">Current Balance</div>
            </div>
            <i className="digs-icon-caret-right" />
          </div>
        </div>
        <div className="balance__item">
          <i className="digs-icon-caret-left" />
          <div className="balance__item-inner balance__item-inner--right">
            <div className="balance__column">
              <div className="balance__row">
                <div className="balance__row-title">Available Balance</div>
                <div className="balance__row-value">{DigsHelper.formatMoney(availableBalance, 2)}</div>
              </div>
              <div className="balance__row">
                <div className="balance__row-title">Pending Balance</div>
                <div className="balance__row-value">{DigsHelper.formatMoney(pendingBalance, 2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balance;
