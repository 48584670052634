import React, { FC, FocusEvent } from "react";
import ReactCodeInput from "react-code-input";
import "./CodeInput.scss";

export interface ICodeInput {
  id?: string;
  name: string;
  type?: string;
  value: any;
  onChange?: (value: string | undefined) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement> | undefined) => void;
  onFocus?: (e?: FocusEvent<HTMLInputElement> | undefined) => void;
}

const CodeInput: FC<ICodeInput> = ({ name, type = "number", ...props }) => {
  return (
    <ReactCodeInput {...props} name={name} inputMode={"numeric"} className={"code-input"} fields={4} type="number" />
  );
};

export default CodeInput;
