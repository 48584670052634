import { useIsLoggedIn } from "@store/services/auth";
import queryString from "query-string";
import React, { FC, useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";

export const AUTH_TYPE_GUEST = "AUTH_TYPE_GUEST";
export const AUTH_TYPE_AUTHORIZED = "AUTH_TYPE_AUTHORIZED";

const AuthWrapper = (
  ComposedComponent: any,
  authType: string = AUTH_TYPE_AUTHORIZED,
  roles: Array<string> = [],
  forceRedirect: boolean = true
) => {
  const Auth: FC = (props) => {
    const location = useLocation();
    const isLoggedIn = useIsLoggedIn();
    const { redirectUrl } = queryString.parse(location.search);

    const shouldGuestAutomaticallyRedirect = useMemo(() => {
      /*
      Use memoization on first load to determine their "original" isLoggedIn state.
      If the user enters !isLoggedIn and forcedRedirect is false, this allows
      them to stay on the page and the page can self-manage the redirect (ie. registration).
      If the user tries to enter the page at a later point - they will be automatically redirected.
     */
      return isLoggedIn;
    }, []);

    // Attempting to access authorized page without being logged in
    if (authType === AUTH_TYPE_AUTHORIZED && !isLoggedIn) {
      if (location.pathname !== "/" && location.pathname !== "/logout") {
        return (
          <Redirect
            to={{
              pathname: "/",
              search: `?redirectUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`,
              state: { from: location },
            }}
          />
        );
      }
      return <Redirect to={{ pathname: "/", state: { from: location } }} />;
    }

    // Attempting to access guest page while logged in
    if (authType === AUTH_TYPE_GUEST && isLoggedIn) {
      if (shouldGuestAutomaticallyRedirect || forceRedirect) {
        const pathname = redirectUrl && typeof redirectUrl === "string" ? queryString.parseUrl(redirectUrl).url : null;
        const query = redirectUrl && typeof redirectUrl === "string" ? queryString.extract(redirectUrl) : null;

        if (pathname) {
          return (
            <Redirect
              to={{
                pathname: pathname,
                search: query ? `?${query}` : undefined,
                state: { from: location },
              }}
            />
          );
        }

        return <Redirect to={{ pathname: "/dashboard", state: { from: location } }} />;
      }
    }

    return <ComposedComponent {...props} />;
  };

  return Auth;
};

export default AuthWrapper;
