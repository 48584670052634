import React, { FC, InputHTMLAttributes } from "react";
import "./Checkbox.scoped.scss";

interface Checkbox extends InputHTMLAttributes<HTMLInputElement> {}

const Checkbox: FC<Checkbox> = ({ name, className, disabled, checked, children, ...props }) => {
  return (
    <label
      className={`checkbox ${className ? className : ""} ${checked ? "checkbox--active" : ""} ${
        disabled ? "checkbox--disabled" : ""
      }`}
    >
      <input type="checkbox" name={name} {...props} checked={checked} disabled={disabled} />
      <div className="checkbox__icon">
        <i className="digs-icon-checkmark" />
      </div>
    </label>
  );
};

export default Checkbox;
