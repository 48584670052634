import React, { FC, MouseEventHandler } from "react";
import "./CardMeta.scoped.scss";

interface CardMeta {
  onClick?: MouseEventHandler;
  className?: string;
  icon?: string;
  hoverable?: boolean;
  title?: string;
  subtitle?: string;
  linked?: "linked" | "unlinked";
  currency?: boolean;
  arrow?: boolean;
  arrowDown?: boolean;
  loading?: boolean;
}

const CardMeta: FC<CardMeta> = ({
  className,
  icon,
  hoverable,
  title,
  subtitle,
  linked,
  currency = true,
  arrow,
  arrowDown = false,
  loading = false,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`card-meta ${loading ? "loading" : ""} ${hoverable ? "hoverable" : ""} ${className || ""}`}
    >
      {icon && (
        <div className="card-meta--icon">
          <img src={icon} alt="" />
        </div>
      )}
      <div className={`card-meta--details ${currency ? "currency" : "text"}`}>
        {title && <span className="title">{title}</span>}
        {subtitle && (
          <div className="subtitle">
            {linked && (
              <span className={`${linked}`}>
                {linked} <span className={"divider"}>|</span>{" "}
              </span>
            )}
            <span>{subtitle}</span>
          </div>
        )}
      </div>
      {loading && <i className="digs-icon-loader" />}
      {arrow && !loading && <i className={`digs-icon-caret-right ${arrowDown ? "down" : ""}`} />}
    </div>
  );
};

export default CardMeta;
