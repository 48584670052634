import FormItem, { FormItemProps } from "@components/global/form/formItem/FormItem";
import React, { FC } from "react";

interface CommonInputProps {
  error?: boolean;
  name?: string;
  "aria-describedby"?: string;
  id?: string;
}

function inputFormItemFactory<InputPropsType extends CommonInputProps>(
  Input: FC<InputPropsType>
): FC<InputPropsType & FormItemProps> {
  const InputFormItem: FC<InputPropsType & FormItemProps> = ({
    errors,
    touched,
    hideErrors,
    showValidated,
    darkBackground,
    name,
    ...props
  }) => {
    const isErrorState: boolean = errors && touched;
    const feedbackId = `${name}_feedback`;
    return (
      <FormItem
        errors={errors}
        touched={touched}
        feedbackId={feedbackId}
        hideErrors={hideErrors}
        showValidated={showValidated}
        darkBackground={darkBackground}
      >
        <Input
          error={isErrorState}
          name={name}
          aria-describedby={feedbackId}
          id={name}
          {...(props as InputPropsType)}
        />
      </FormItem>
    );
  };

  return InputFormItem;
}

export default inputFormItemFactory;
