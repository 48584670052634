import { AnnouncementType } from "@components/utils/announcements";
import AnalyticsEvent from "./AnalyticsEvent";

export type AnnouncementLocation = "property" | "public-property" | "dashboard";
export type AnnouncementClickType = "card" | "cta";

abstract class AnnouncementEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Announcement";
  readonly data: any;

  protected constructor() {
    this.data = {};
  }
}

export class AnnouncementReadEvent extends AnnouncementEvent {
  readonly name = "Announcement.Read";

  constructor(name: AnnouncementType, location: string) {
    super();
    this.data.name = name;
    this.data.location = location;
  }
}

export class AnnouncementSeenEvent extends AnnouncementEvent {
  readonly name = "Announcement.Seen";

  constructor(name: AnnouncementType, location: string) {
    super();
    this.data.name = name;
    this.data.location = location;
  }
}

export class AnnouncementClickedEvent extends AnnouncementEvent {
  readonly name = "Announcement.Clicked";

  constructor(name: AnnouncementType, location: string, clickType: AnnouncementClickType) {
    super();
    this.data.name = name;
    this.data.location = location;
    this.data.clickType = clickType;
  }
}

export class AnnouncementDismissedEvent extends AnnouncementEvent {
  readonly name = "Announcement.Dismissed";

  constructor(name: AnnouncementType, location: string) {
    super();
    this.data.name = name;
    this.data.location = location;
  }
}
