import { TextInputFormItem } from "@components/global";
import Yup from "@helpers/Yup";
import React, { FC } from "react";

export const AptOrUnitNumberSchema = Yup.object().shape({
  apt_or_unit: Yup.string(),
});

interface AptOrUnitNumber {
  error?: any;
  formik: any;
}

const AptOrUnitNumber: FC<AptOrUnitNumber> = ({ error, formik }) => {
  return (
    <div className="owner-onboarding--step">
      <h1>Unit or Apt #?</h1>
      <p>If so, what is it?</p>
      <TextInputFormItem
        errors={formik.errors.apt_or_unit || error}
        touched={formik.touched.apt_or_unit}
        name="apt_or_unit"
        label="Apt or Unit #"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.apt_or_unit}
      />
    </div>
  );
};

export default AptOrUnitNumber;
