import { TextInputFormItem } from "@components/global";
import Yup from "@helpers/Yup";
import React, { FC } from "react";
import DigsHelper from "../../../../../helpers/DigsHelper";

export const AptOrUnitNumberSchema = Yup.object().shape({
  apt_or_unit: Yup.string(),
});

interface AptOrUnitNumber {
  formik: any;
}

const AptOrUnitNumber: FC<AptOrUnitNumber> = ({ formik, ...props }) => {
  return (
    <div className="signup-onboarding--step">
      {props.children || (
        <>
          <h1>
            <span>If applicable, add your Apt or Unit # for</span> {DigsHelper.formatAddress(formik.values, true)}
          </h1>
          <p></p>
        </>
      )}
      <TextInputFormItem
        errors={formik.errors.apt_or_unit}
        touched={!!formik.touched.apt_or_unit}
        name="apt_or_unit"
        label="Apt or Unit #"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.apt_or_unit}
      />
    </div>
  );
};

export default AptOrUnitNumber;
