import { UserModel } from "@store/models/user";
import { baseApi } from "@store/services/base";
import { useIsLoggedIn, useLocalLogout } from "./auth";

const transformSingleResponse = (data: { user: UserModel }) => data.user;

const baseUrl = "consumer/v1/user";

export const userApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => baseUrl,
      transformResponse: transformSingleResponse,
      providesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `${baseUrl}`,
        method: "POST",
        body,
      }),
      transformResponse: transformSingleResponse,
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        // When we start the request, just immediately update the cache
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", undefined, (draft) => {
            return { ...draft, ...patch };
          })
        );
        try {
          await queryFulfilled;
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    sendMobileVerification: builder.mutation({
      query: (mobile) => ({
        url: `/consumer/send-verification-code`,
        method: "POST",
        body: { mobile },
      }),
      transformResponse: transformSingleResponse,
      async onQueryStarted(mobile, { dispatch, queryFulfilled }) {
        // When we start the request, just immediately update the cache
        const patchResult = dispatch(
          userApi.util.updateQueryData("getUser", undefined, (draft) => {
            return { mobile, ...draft };
          })
        );
        try {
          const { data: newUser } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData("getUser", undefined, (draft) => {
              return { ...newUser };
            })
          );
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    verifyMobile: builder.mutation({
      query: ({ nexmo_request_id, mobile, code }) => ({
        url: `/consumer/check-verification-code/${nexmo_request_id}`,
        method: "POST",
        body: { mobile, code, nexmo_request_id },
      }),
      transformResponse: transformSingleResponse,
      async onQueryStarted(mobile, { dispatch, queryFulfilled }) {
        try {
          const { data: newUser } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData("getUser", undefined, (draft) => {
              return { ...newUser };
            })
          );
        } catch (err) {}
      },
    }),
    updateSubscription: builder.mutation({
      query: ({ type, amount }) => ({
        url: `/consumer/user/subscription`,
        method: "POST",
        body: { type, amount },
      }),
      transformResponse: transformSingleResponse,
      async onQueryStarted(mobile, { dispatch, queryFulfilled }) {
        try {
          const { data: newUser } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData("getUser", undefined, (draft) => {
              return { ...newUser };
            })
          );
        } catch (err) {}
      },
    }),
    deleteUser: builder.mutation({
      query: (close_account_captcha: string) => ({
        url: `${baseUrl}/close-account`,
        method: "POST",
        body: { close_account_captcha },
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useSendMobileVerificationMutation,
  useVerifyMobileMutation,
  useUpdateSubscriptionMutation,
  useUpdateUserMutation,
} = userApi;

const { useDeleteUserMutation } = userApi;

export const useGetUserIfLoggedInQuery = (arg?: undefined, options?: { skip?: boolean }) => {
  const loggedIn = useIsLoggedIn();
  return useGetUserQuery(undefined, {
    ...options,
    skip: !loggedIn || (options && options.skip),
  });
};

export const useIsMobileVerifiedQuery = (arg?: undefined, options?: { skip?: boolean }) => {
  return useGetUserQuery(undefined, {
    ...options,
    selectFromResult: ({ data, ...props }) => ({
      ...props,
      data: data ? !!data.mobile_verified_at : false,
    }),
  });
};

export const useDeleteUser = () => {
  const [deleteUser, state] = useDeleteUserMutation();
  const localLogout = useLocalLogout();
  const deleteUserAndLogout = (close_account_captcha: string) => {
    const result = deleteUser(close_account_captcha);
    result.unwrap().then(() => {
      localLogout();
    });
    return result;
  };

  return [deleteUserAndLogout, state] as const;
};
