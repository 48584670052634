import { LeadModel } from "@store/models/properties";
import AnalyticsEvent from "./AnalyticsEvent";

abstract class SponsorPanelEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Sponsor";
  readonly data: any;

  constructor(sponsorId?: number) {
    this.data = { sponsorId };
  }
}

export class PanelOpenedEvent extends SponsorPanelEvent {
  readonly name = "SponsorPanel.Opened";
}

export class FormOpenedEvent extends SponsorPanelEvent {
  readonly name = "SponsorPanel.FormOpened";
}

type Purpose = "general" | "technical";

export class FormSubmittedEvent extends SponsorPanelEvent {
  readonly name = "SponsorPanel.FormSubmitted";

  constructor(sponsorId: number | undefined, purpose?: Purpose) {
    super(sponsorId);
    this.data.purpose = purpose;
  }
}

export class RequestAgentSubmitted extends SponsorPanelEvent {
  readonly name = "SponsorPanel.RequestAgentSubmitted";

  constructor(sponsorId: number, lead: LeadModel) {
    super(sponsorId);
    this.data.lead = lead;
  }
}

export class RefinanceCTAClicked extends SponsorPanelEvent {
  readonly name = "SponsorPanel.RefinanceCTAClicked";
}

type ContactMethod = "phone" | "email" | "message" | "linkedIn" | "zillow";
export class ContactClickedEvent extends SponsorPanelEvent {
  readonly name = "SponsorPanel.ContactClicked";

  constructor(sponsorId: number, contactMethod: ContactMethod) {
    super(sponsorId);
    this.data.contactMethod = contactMethod;
  }
}
