import { Button, SuspenseLoader } from "@components/global";
import { LeadSourceContext, ReferralContext } from "@components/utils/contexts";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import { FastAverageColor } from "fast-average-color";
import React, { FC, Suspense, useContext, useEffect, useState } from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";
import "./SplitLayout.scss";

const generateRandomBackground = (): string => {
  const randomInteger = Math.floor(Math.random() * 8) + 1;
  return `/assets/images/layouts/split-layout/bg-0${randomInteger}.jpg`;
};

interface SplitLayout extends RouteProps {
  showBranding?: boolean;
}

const disableRegistation = true;

const SplitLayout: FC<SplitLayout> = ({ showBranding = true, ...props }) => {
  const [backgroundImageUri, setBackgroundImageUri] = useState<string | null>(null);
  const [backgroundDarkness, setBackgroundDarkness] = useState<string>("light");

  useEffect(() => {
    if (backgroundImageUri) {
      const fac = new FastAverageColor();
      fac
        .getColorAsync(backgroundImageUri)
        .then((color) => {
          color.isDark ? setBackgroundDarkness("dark") : setBackgroundDarkness("light");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [backgroundImageUri]);

  const location = useLocation();
  const isLoggedIn = useIsLoggedIn();
  const { displaySponsor: sponsor } = useContext(ReferralContext);
  const { leadSourceLogo } = useContext(LeadSourceContext);
  const { data: user } = useGetUserIfLoggedInQuery(undefined);

  useEffect(() => {
    const randomBackground = generateRandomBackground();
    setBackgroundImageUri(randomBackground);
  }, []);

  return (
    <div className="split-layout">
      <div className="split-layout--left">
        <div className="container">
          <div className="split-layout--branding">
            {leadSourceLogo && showBranding && <img className="cobranded-primary" src={leadSourceLogo} />}
            {!leadSourceLogo && sponsor && sponsor.id && sponsor.logo && showBranding && (
              <img className="sponsor-primary" src={sponsor.logo} />
            )}
            {((!leadSourceLogo && !(sponsor && sponsor.id && sponsor.logo)) || !showBranding) && (
              <img
                className="digs-primary"
                src="/assets/images/global/logos/movoto-by-ojo-logo.svg"
                alt={"Movoto by OJO Logo"}
              />
            )}
          </div>
          <Suspense fallback={<SuspenseLoader />}>
            <Route {...props} />
          </Suspense>
          {!leadSourceLogo && sponsor && sponsor.id && sponsor.logo && showBranding && (
            <img
              className="plus-ojo"
              src="/assets/images/layouts/split-layout/powered-by-ojo.svg"
              alt="Powered by Movoto Homeowner"
            />
          )}
        </div>
      </div>
      <div id="bg-image" className="split-layout--right" style={{ backgroundImage: `url(${backgroundImageUri})` }}>
        {!disableRegistation && !isLoggedIn && (location.pathname === "/" || location.pathname === "/forgot-password") && (
          <div className={`split-layout--actions split-layout--actions__${backgroundDarkness}`}>
            <span>Don't have an account?</span>
            <Button to={"/signup"} scale="medium" theme={backgroundDarkness === "light" ? "primary" : "secondary"}>
              Sign Up
            </Button>
          </div>
        )}
        {!isLoggedIn && location.pathname !== "/" && location.pathname !== "/forgot-password" && (
          <div className={`split-layout--actions split-layout--actions__${backgroundDarkness}`}>
            <span>Already have an account?</span>
            <Button to={"/"} scale="medium" theme={backgroundDarkness === "light" ? "primary" : "secondary"}>
              Sign In
            </Button>
          </div>
        )}
        {isLoggedIn && user && (
          <div className={`split-layout--actions split-layout--actions__${backgroundDarkness}`}>
            <span>Not {user.firstname}?</span>
            <Button to={"/logout"} scale="medium" theme={backgroundDarkness === "light" ? "primary" : "secondary"}>
              Logout
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitLayout;
