import React, { FC, useEffect, useState } from "react";
import LoaderBlock from "./LoaderBlock";

interface SuspenseLoader {}

const SuspenseLoader: FC<SuspenseLoader> = () => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    let isMounted = true;

    setTimeout(() => isMounted && setShowLoader(true), 500);

    return () => {
      isMounted = false;
    };
  }, []);
  return showLoader ? <LoaderBlock /> : null;
};

export default SuspenseLoader;
