import Yup from "@helpers/Yup";
import React, { FC } from "react";
import AddressInput, { SearchSuggestion } from "../../../form/addressInput/AddressInput";
import { FormItem, WarningNotificationCard } from "../../../index";

interface Address {
  formik: any;
  propertyLimitReached?: boolean;
}

export const AddressSchema = Yup.object().shape({
  search: Yup.string().required("Address required"),
  street_address: Yup.string().required("Street address required"),
  city: Yup.string().required("City required"),
  state: Yup.string().required("State required"),
  zip_code: Yup.string().required("Zip code required"),
});

const Address: FC<Address> = ({ formik, propertyLimitReached = false }) => {
  const onSelectHandler = (results: SearchSuggestion) => {
    formik
      .setValues({
        ...formik.values,
        search: results.search,
        street_address: results.street_address || "",
        city: results.city || "",
        state: results.state || "",
        zip_code: results.zip_code || "",
        apt_or_unit: results.apt_or_unit || "",
      })
      .then(() => {
        formik.submitForm();
      });
  };

  return (
    <div className="owner-onboarding--step">
      <h1>What's your address?</h1>
      <p>Enter your address to begin</p>
      <FormItem>
        <AddressInput
          value={formik.values.search}
          onChange={(val) => formik.setFieldValue("search", val)}
          onSelect={onSelectHandler}
        />
      </FormItem>
      <FormItem>
        {propertyLimitReached && (
          <WarningNotificationCard message={`You cannot add another property because you've reached your limit.`} />
        )}
      </FormItem>
    </div>
  );
};

export default Address;
