import { LoaderBlock, SponsorCard, SuspenseLoader } from "@components/global";
import TermsAndConditions from "@components/global/terms-and-conditions/TermsAndConditions";
import { ReferralContext, UiContext } from "@components/utils/contexts";
import { breakpoints } from "@helpers/Constants";
import DigsHelper from "@helpers/DigsHelper";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetAllFundsQuery, useGetAllHomeFundsQuery } from "@store/services/funds";
import { useGetAllPropertiesQuery } from "@store/services/properties";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import { includes } from "lodash";
import React, { FC, Suspense, useContext, useMemo } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import LayoutProps from "../LayoutProps";
import Dropdown from "./dropdown/Dropdown";
import Header from "./header/Header";
import LoginModal from "./loginModal/LoginModal";
import DesktopNavigation from "./navigation/desktopNavigation/DesktopNavigation";
import { useNavItems } from "./navItems";
import RegistrationModal from "./registrationModal/RegistrationModal";
import SponsorPanel from "./sponsorPanel/SponsorPanel";
import "./ThreeColumnLayout.scoped.scss";

const ThreeColumnLayout: FC<LayoutProps> = ({ components, paths, ...props }) => {
  const isLoggedIn = useIsLoggedIn();
  const { data: allFunds = [], isSuccess: allFundsLoaded } = useGetAllFundsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { data: homeFunds, isSuccess: homeFundsLoaded } = useGetAllHomeFundsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { isSuccess: propertiesLoaded } = useGetAllPropertiesQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { isSuccess: userLoaded } = useGetUserIfLoggedInQuery(undefined);
  const loading = isLoggedIn && !homeFundsLoaded && !propertiesLoaded && !userLoaded;

  const { displaySponsor: sponsor } = useContext(ReferralContext);
  const { uiState, hideLoginModal, hideRegistrationModal } = useContext(UiContext);
  const { login_modal: loginModal, sponsor_panel: sponsorPanel, registration_modal: registrationModal } = uiState;
  const { width } = useWindowSize();
  const location = useLocation();

  const dropdownItems = useMemo(() => {
    if (isLoggedIn) {
      return [
        {
          title: "Account Settings",
          path: "/settings",
        },
        {
          title: "Support",
          href: "https://help.digs.co/en/",
        },
        {
          title: "Log Out",
          path: "/logout",
        },
      ];
    }
    return [
      {
        title: "Log In",
        path: "/",
      },
      {
        title: "Support",
        href: "https://help.digs.co/en/",
      },
    ];
  }, [isLoggedIn]);

  const navItems = useNavItems(!loading);

  const sponsorContent = (
    <>
      <SponsorCard sponsor={sponsor || undefined} />
      <SponsorPanel sponsor={sponsor || undefined} visible={sponsorPanel} />
    </>
  );

  const hideSponsorContent = useMemo(() => {
    const excludedPaths = ["/withdraw"];
    paths.forEach(({ path }) => {
      if (DigsHelper.matchRule(path, "/settings*")) {
        excludedPaths.push(path);
      }
    });
    return includes(excludedPaths, location.pathname);
  }, [location.pathname, paths]);

  //TODO global component for logo link
  return (
    <>
      <div className="three-column-layout">
        <Header isLoggedIn={isLoggedIn} navItems={navItems} />
        <div className="three-column-layout__left">
          <div className="three-column-layout__left__inner">
            <Link to="/" className="three-column-layout__home three-column-layout__header">
              <img src="/assets/images/global/logos/movoto-by-ojo-logo.svg" alt="Movoto by OJO logo" className="logo" />
              <span className="sr-only">Go to homepage</span>
            </Link>
            <DesktopNavigation items={navItems} />
            {width >= breakpoints.sm && !hideSponsorContent ? sponsorContent : null}
          </div>
        </div>
        <main className="three-column-layout__main">
          <Suspense fallback={<SuspenseLoader />}>
            {loading ? (
              <LoaderBlock />
            ) : (
              <>
                <Switch>
                  {paths.map(({ path, exact, Component }, i) => (
                    <Route {...props} path={path} component={Component} exact={exact} key={`route-${i}`} />
                  ))}
                </Switch>
              </>
            )}
          </Suspense>
          <TermsAndConditions />
          {width < breakpoints.sm ? sponsorContent : null}
        </main>
        <div className="three-column-layout__right">
          <div className="three-column-layout__right__inner">
            <div className="three-column-layout__header">
              <Dropdown items={dropdownItems}>
                {width >= breakpoints.lg ? "My Account" : ""}
                <i className="digs-icon-menu" />
              </Dropdown>
            </div>
          </div>
        </div>
        {!isLoggedIn && (
          <>
            <LoginModal visible={loginModal} onRequestClose={() => hideLoginModal()} />
            <RegistrationModal visible={registrationModal} onRequestClose={() => hideRegistrationModal()} />
          </>
        )}
      </div>
    </>
  );
};

export default ThreeColumnLayout;
