import isString from "lodash/isString";
import React, { FC } from "react";
import "./Feedback.scoped.scss";

export interface FeedbackProps {
  type: string;
  content?: any;
}

const Feedback: FC<FeedbackProps> = ({ type = "error", content, children, ...props }) => {
  let keys = content && (isString(content) ? null : Object.keys(content));
  let items = [];

  if (keys && keys.length) {
    keys.forEach((item: string, index: number) => {
      if (Array.isArray(content[item])) {
        for (let i in content[item]) {
          items.push(<span key={index}>{content[item][i]}</span>);
        }
      } else {
        items.push(<span>{content[item]}</span>);
      }
    });
  } else if (isString(content)) {
    items.push(<span>{content}</span>);
  }

  return (
    <div className={`feedback ${type}`}>
      {items.length > 1 && (
        <ul>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      )}
      {items.length <= 1 && items.map((item, i) => <span key={i}>{item}</span>)}
      {children}
    </div>
  );
};

export default Feedback;
