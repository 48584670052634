import Link from "@components/global/links/Link";
import React, { FC, MouseEventHandler, ReactNode, useLayoutEffect, useRef, useState } from "react";
import "./ListItem.scoped.scss";

interface BaseListItem {
  label: string;
  subLabel?: string;
  linkTo?: string;
  target?: string;
  onClick?: MouseEventHandler;
  className?: string;
  leftContent?: ReactNode;
  leftIcon?: string;
  rightIcon?: string;
  expandable?: boolean;
  expanded?: boolean;
}

interface ListItemWithPath extends BaseListItem {
  linkTo: string;
}

interface ListItemWithClickHandler extends BaseListItem {
  onClick: MouseEventHandler;
}

export type ListItemProps = ListItemWithPath | ListItemWithClickHandler;

const ListItem: FC<ListItemProps> = ({
  label,
  subLabel,
  className,
  onClick,
  leftContent,
  leftIcon,
  rightIcon = "caret-right",
  expandable = false,
  expanded = false,
  linkTo,
  target = "_self",
  children,
}) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const expandableRef = useRef(null);
  useLayoutEffect(() => {
    if (expandableRef && expandableRef.current) {
      //@ts-ignore
      setMaxHeight(expandableRef.current.scrollHeight);
    }
  }, [expandableRef]);

  const buttonContent = (
    <>
      <div className="list-item__button-inner">
        {leftIcon && <i className={`left-icon icon digs-icon-${leftIcon}`}></i>}
        {leftContent && <div className="list-item__left-content">{leftContent}</div>}
        <span>
          <div className="label">{label}</div>
          <div className="sub-label">{subLabel}</div>
        </span>
      </div>
      <div className="list-item__button-inner">
        <i className={`icon digs-icon-${rightIcon}`}></i>
      </div>
    </>
  );

  return (
    <div
      className={`${className ? className : ""} ${
        expandable ? `list-item--${expanded ? "expanded" : "collapsed"}` : ""
      } list-item`}
    >
      {linkTo && (
        <Link className="list-item__button" to={linkTo} target={target}>
          {buttonContent}
        </Link>
      )}
      {onClick && (
        <button className="list-item__button" type="button" onClick={onClick}>
          {buttonContent}
        </button>
      )}
      {expandable ? (
        <div
          className="list-item__expandable"
          ref={expandableRef}
          style={{ maxHeight: expanded ? `${maxHeight}px` : "0" }}
          aria-expanded={expanded ? "true" : "false"}
        >
          <div className="list-item__expandable-inner">{children}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ListItem;
