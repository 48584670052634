import React, { FC, ReactNode } from "react";

interface Faq {
  question: ReactNode;
  answer: ReactNode;
  className?: string;
}
const Faq: FC<Faq> = ({ question, answer, className = "" }) => {
  return (
    <div className={`faqs__faq ${className}`}>
      <h3 className="faqs__question">{question}</h3>
      <p className="faqs__answer">{answer}</p>
    </div>
  );
};

export default Faq;
