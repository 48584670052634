import * as Events from "./events";
import AnalyticsEvent from "./events/AnalyticsEvent";
import PageviewEvent, { PageData } from "./events/PageviewEvent";
import { AnalyticsModule } from "./modules/AnalyticsModule";
import TagManager from "./modules/TagManager";

const serviceConfig = {
  tagmanager: TagManager,
};

type Service = keyof typeof serviceConfig;

let sharedEventProperties = {};

const addSharedPropertiesToEvent = <E extends AnalyticsEvent>(event: E): E => {
  Object.assign(event.data, sharedEventProperties);
  return event;
};

const getServices = (services: Service[]): Array<AnalyticsModule> => {
  return services.length > 0 ? services.map((s) => serviceConfig[s]) : Object.values(serviceConfig);
};

const initialize = (services: Service[] = []) => {
  getServices(services).forEach((s) => s.initialize());
};

const setSharedEventProperties = (sharedProperties: any) => {
  sharedEventProperties = { ...sharedEventProperties, ...sharedProperties };
};

const identify = (user: { unique_id: string; provider_id?: number }, services: Service[] = []) => {
  getServices(services).forEach((s) => s.identify(user));
};

const alias = (alias: string, services: Service[] = []) => {
  if (!(alias.startsWith("subscriber-") || alias.startsWith("consumer-"))) {
    return console.warn("Aliases must start with subscriber- or consumer-");
  }

  getServices(services).forEach((s) => s.alias(alias));
};

const reset = (services: Service[] = []) => {
  sharedEventProperties = {};
  getServices(services).forEach((s) => s.reset());
};

const trackEvent = (event: AnalyticsEvent, services: Service[] = []) => {
  getServices(services).forEach((s) => s.trackEvent(addSharedPropertiesToEvent(event)));
};

const trackPageview = (data: PageData, services: Service[] = []) => {
  getServices(services).forEach((s) => s.trackPageview(addSharedPropertiesToEvent(new PageviewEvent(data))));
};

export default {
  Events,
  initialize,
  identify,
  alias,
  setSharedEventProperties,
  reset,
  trackEvent,
  trackPageview,
};
