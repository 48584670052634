import React, { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { default as SelectComponent } from "react-select";
import { SelectComponentsProps } from "react-select/base";
import useWindowSize from "../../../utils/useWindowSize";
import "./Select.scss";

export interface SelectProps extends SelectComponentsProps {
  label?: string;
  name?: string;
  size?: "large" | "default" | "small";
  className?: string;
  jost?: boolean;
  theme?: "default" | "dark";
  error?: boolean;
}

//TODO new select component that is accessible, uses standard prop names and callback arguments, works properly as a controlled input, add to storybook
const Select: FC<SelectProps> = ({
  label,
  size = "default",
  name,
  className,
  jost = false,
  options,
  placeholder,
  readOnly,
  disabled,
  error = false,
  menuPlacement,
  theme = "default",
  ...props
}) => {
  const { height } = useWindowSize();
  const selectContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [menuPlacementBottom, setMenuPlacementBottom] = useState<boolean>(true);
  const { value } = props;

  useEffect(() => {
    if (selectContainerRef.current) {
      const { offsetTop, offsetHeight } = selectContainerRef.current;
      const distanceToBottom = height - offsetTop + offsetHeight;
      if (!Number.isNaN(distanceToBottom)) {
        if (distanceToBottom < 500) {
          if (menuPlacementBottom) {
            setMenuPlacementBottom(false);
          }
        } else {
          if (!menuPlacementBottom) {
            setMenuPlacementBottom(true);
          }
        }
      }
    }
  }, [height]);

  return (
    <div ref={selectContainerRef} className={`select-input ${className ? className : ""}`}>
      <SelectComponent
        {...props}
        className={`select select--theme-${theme} ${readOnly ? "select--readonly" : ""} select--size-${size}`}
        classNamePrefix="select"
        options={options}
        menuPlacement={menuPlacement ? menuPlacement : menuPlacementBottom ? "bottom" : "top"}
        isSearchable={false}
        isDisabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder || label}
      />
      {label && <label htmlFor={name}>{label}</label>}
    </div>
  );
};
export default Select;
