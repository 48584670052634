import React, { FC } from "react";
import "./ModalFooter.scoped.scss";

interface ModalFooter {
  className?: string;
}

const ModalFooter: FC<ModalFooter> = ({ className, children, ...props }) => {
  return (
    <div {...props} className={`modal-footer ${className ? className : ""}`}>
      {children}
    </div>
  );
};

export default ModalFooter;
