import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import "./Dropdown.scoped.scss";

interface Dropdown {
  items?: Array<{
    href?: string;
    title?: string;
    path?: string;
  }>;
}

const Dropdown: FC<Dropdown> = ({ items, ...props }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div
      className="navigation-dropdown"
      onMouseLeave={() => {
        setExpanded(false);
      }}
      onMouseEnter={() => {
        setExpanded(true);
      }}
    >
      <button
        className="navigation-dropdown__link"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {props.children}
      </button>
      {items && items.length > 0 && (
        <div className={`navigation-dropdown__container ${expanded ? "navigation-dropdown__container--expanded" : ""}`}>
          <ul>
            {items.map((item, i) => {
              return item.href ? (
                <li key={i}>
                  <a href={item.href} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </li>
              ) : (
                <li key={i}>
                  <Link to={item.path}>{item.title}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
