import AnalyticsEvent from "./AnalyticsEvent";

abstract class OnboardingFlowEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "User";
  readonly data = { flow: "onboarding" };
}

export class UserProvidedAddressEvent extends OnboardingFlowEvent {
  readonly name = "Onboarding.UserProvidedAddress";
}

export class UserProvidedUnitNumberEvent extends OnboardingFlowEvent {
  readonly name = "Onboarding.UserProvidedUnitNumber";
}

export class UserSubscribedEvent extends OnboardingFlowEvent {
  readonly name = "Onboarding.UserSubscribed";
}

export class UserCreatedAccountEvent extends OnboardingFlowEvent {
  readonly name = "Onboarding.UserCreatedAccount";
}
