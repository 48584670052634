import Api from "@helpers/api/index";
import { debounce } from "lodash";
import React, { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from "react";
import Autocomplete from "react-autocomplete";
import Loader from "../../loader/Loader";
import "./AddressInput.scss";

interface AddressInput {
  value: string;
  onChange: (value: string) => void;
  onSelect: (results: SearchSuggestion) => void | undefined;
}

export interface SearchSuggestion {
  street_address: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | number | null;
  apt_or_unit?: string | number | null;
  search: string | null;
}

const AddressInput: FC<AddressInput> = ({ value, onChange, onSelect }) => {
  const inputRef = useRef<Autocomplete>(null);
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value || "");
    if (e.target.value) {
      debouncedQuery(e.target.value);
    }
  };

  const debouncedQuery = useCallback(
    debounce((query: string) => fetchSuggestions(query), 500),
    []
  );

  const fetchSuggestions = (q: string) => {
    setLoading(true);
    Api.ssSearchProperties(q)
      .then((res) => {
        setLoading(false);
        setSuggestions(res.data.suggestions || []);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClearHandler = () => {
    onChange("");
    setSuggestions([]);
    if (inputRef.current) {
      inputRef.current.blur();
    }

    const normalizedSuggestion = {
      street_address: selectedValue.street_line,
      apt_or_unit: selectedValue.secondary,
      city: selectedValue.city,
      state: selectedValue.state,
      zip_code: selectedValue.zipcode,
      search: buildAddress(selectedValue),
    };

    onSelect(normalizedSuggestion);
  };

  const buildAddress = (suggestion: any) => {
    const { street_line, secondary, city, state, zipcode } = suggestion;
    return `${street_line} ${secondary} ${city}, ${state} ${zipcode}`;
  };

  const getItemValue = (item: SearchSuggestion) => {
    setSelectedValue(item);
    return buildAddress(item) || "";
  };

  const onSuggestionSelected = (suggestion: any) => {
    onChange(buildAddress(selectedValue));

    if (inputRef.current) {
      inputRef.current.blur();
    }

    const { street_line = "", secondary = "", city = "", state = "", zipcode = "" } = selectedValue;

    const normalizedSuggestion: SearchSuggestion = {
      street_address: street_line,
      apt_or_unit: secondary,
      city: city,
      state: state,
      zip_code: zipcode,
      search: buildAddress(selectedValue),
    };

    onSelect(normalizedSuggestion);
  };

  const renderItem = useMemo(
    () => (item: any, isHighlighted: boolean) => {
      return (
        <li className={isHighlighted ? "highlighted" : ""} key={suggestions.indexOf(item)}>
          <span>{buildAddress(item)}</span>
        </li>
      );
    },
    [suggestions]
  );

  const renderMenu = useMemo(
    () => (items: any) => {
      return (
        <div>
          {suggestions.length > 0 && (
            <ul className="places-autocomplete--results">
              {items}
              <li onClick={onClearHandler}>
                <span>
                  <i className="digs-icon-close" /> Clear Search
                </span>
              </li>
            </ul>
          )}
        </div>
      );
    },
    [suggestions]
  );

  return (
    <div className="places-autocomplete">
      <Autocomplete
        items={suggestions || []}
        ref={inputRef}
        getItemValue={(item) => getItemValue(item)}
        renderItem={(item, isHighlighted) => renderItem(item, isHighlighted)}
        renderMenu={(items) => renderMenu(items)}
        wrapperStyle={{ width: "100%" }}
        renderInput={(props) => (
          <input className="location-search-input" placeholder="Enter your address to begin..." {...props} />
        )}
        value={value}
        onChange={onChangeHandler}
        onSelect={(item) => onSuggestionSelected(item)}
      />
      <div className="places-autocomplete--icon">
        {loading ? <Loader size={15} /> : <i className="digs-icon-search" />}
      </div>
    </div>
  );
};

export default AddressInput;
