import React, { FC } from "react";
import Loader from "./Loader";
import "./LoaderBlock.scoped.scss";

interface LoaderBlock {}

const LoaderBlock: FC<LoaderBlock> = () => {
  return (
    <div className="loader-block">
      <Loader size={35} />
    </div>
  );
};

export default LoaderBlock;
