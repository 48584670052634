import { JSXElement } from "@babel/types";
import React, { ChangeEvent, CSSProperties, FC, FocusEvent, MouseEventHandler } from "react";
import "./Radio.scoped.scss";

interface Radio {
  defaultChecked?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  onClick?: MouseEventHandler;
  style?: CSSProperties;
  id?: string;
  name?: string;
  value: any;
  label: string | JSXElement;
  subtitle?: string;
  icon?: string;
  image?: string;
  className?: string;
  children?: any;
  type?: "horizontal" | "vertical" | "vertical tall";
  disabled?: boolean;
}

const Radio: FC<Radio> = ({
  defaultChecked,
  label,
  icon,
  image,
  className,
  subtitle,
  children,
  type = "vertical",
  ...props
}) => {
  return (
    <label className={`radio ${className} ${type} ${defaultChecked ? "active" : ""}`}>
      <div className="radio--container">
        <div className="radio--container-inner" style={{ justifyContent: icon || image ? "space-between" : "center" }}>
          <div className="radio--checkmark">
            <i className="digs-icon-checkmark" />
          </div>
          <input {...props} type="radio" />
          <div className="radio--text">
            <div>{label}</div>
            {subtitle && <div className="radio--subtitle">{subtitle}</div>}
          </div>
          {icon && (
            <div className="radio--icon">
              <i className={icon} />
            </div>
          )}
          {image && (
            <div className="radio--image">
              <img src={image} />
            </div>
          )}
        </div>
        {children && <div className="radio--hidden">{children}</div>}
      </div>
    </label>
  );
};

export default Radio;
