import React, { FC, FocusEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import TextInput, { ITextInput } from "../alt-input/TextInput";
import "./NumberInput.scoped.scss";

const NON_DIGITS = /[^0-9.]*/g;
const DECIMAL_PLACES = 2;

export interface INumberInput extends Omit<ITextInput, "onChange" | "value"> {
  large?: boolean;
  value?: number | null;
  suffix?: string;
  max?: number;
  blankZeroValue?: boolean;
  format?: (n: number) => string;
  parse?: (s: string) => number;
  formatCursorPosition?: (value: string, cursorPosition: number, formattedValue: string) => number;
  onChange?: (value: number | undefined) => void;
}

const NumberInput: FC<INumberInput> = ({
  value,
  large = false,
  className,
  suffix = "",
  max = Number.MAX_SAFE_INTEGER,
  blankZeroValue = true,
  format = (n: number) => `${n.toString()}${suffix}`,
  parse = (n: string) => parseFloat(n),
  formatCursorPosition = (v: string, c: number, f: string) => Math.min(c, f.length - suffix.length),
  onChange,
  onFocus,
  onClick,
  inputMode = "numeric",
  ...props
}) => {
  const valueDisplay = value || !blankZeroValue ? format(value || 0) : "";
  const [formattedCursorPosition, setFormattedCursorPosition] = useState(valueDisplay.length);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        const newValue = Math.max(0, Math.min(max, parse(e.target.value)));
        const newDisplayValue = format(newValue);
        onChange(newValue);
        setFormattedCursorPosition(formatCursorPosition(e.target.value, e.target.selectionEnd, newDisplayValue));
      }
    },
    [max, onChange, parse, format, formatCursorPosition]
  );

  const setInitialFocus = () => {
    if (
      inputRef &&
      inputRef.current &&
      inputRef.current.selectionEnd &&
      inputRef.current.selectionStart === inputRef.current.selectionEnd
    ) {
      setCursorPosition(formatCursorPosition(inputRef.current.value, inputRef.current.selectionEnd, valueDisplay));
    }
  };

  const handleFocus = (e?: FocusEvent<HTMLInputElement>) => {
    setInitialFocus();
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleClick = (e?: MouseEvent) => {
    setInitialFocus();
    if (onClick) {
      onClick(e);
    }
  };

  const setCursorPosition = (pos: number) => {
    if (
      inputRef &&
      inputRef.current &&
      (inputRef.current.selectionStart !== pos || inputRef.current.selectionEnd !== pos)
    ) {
      inputRef.current.selectionStart = inputRef.current.selectionEnd = pos;
    }
  };

  useEffect(() => {
    setCursorPosition(formattedCursorPosition);
  });

  return (
    <TextInput
      {...props}
      inputMode={inputMode}
      ref={inputRef}
      onFocus={handleFocus}
      onChange={handleChange}
      onClick={handleClick}
      value={valueDisplay}
      className={`number-input number-input__${large ? "large" : "default"} ${value ? "" : "number-input__empty"}`}
    />
  );
};

export default NumberInput;
