import React, { FC } from "react";
import "./CardBody.scoped.scss";

interface CardBody {
  className?: string;
  style?: object;
}

const CardBody: FC<CardBody> = ({ className, children, ...props }) => {
  return (
    <div {...props} className={`${className ? className : ""} card-body`}>
      {children}
    </div>
  );
};

export default CardBody;
