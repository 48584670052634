import { recordError, recordWarning } from "@helpers/Errors";
import React from "react";

export default class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error) {
    //TODO hacky
    if (error && error.message && /^loading chunk/i.test(error.message)) {
      recordWarning(`Failed to lazy load component: ${error && error.message}`, error);
    } else {
      recordError(`Error boundary caught error: ${error && error.message}`, error);
    }
  }
  render() {
    return this.props.children;
  }
}
