import { Button } from "@components/global";
import React, { FC } from "react";
import "./ShowMore.scoped.scss";

interface ShowMore {
  items: React.ReactNode[];
  initialCount: number;
  seeLessButton?: boolean;
  expanded: boolean;
  onToggle: (expand: boolean) => void;
}

const ShowMore: FC<ShowMore> = ({ items, initialCount = 4, seeLessButton = false, expanded, onToggle, children }) => {
  return (
    <>
      {expanded ? items : items.slice(0, initialCount)}
      {(items.length > initialCount || children) && (
        <div className="toggle-more-button">
          <div>
            {items.length > initialCount ? (
              expanded ? (
                <Button theme="ghost" scale="medium" onClick={() => onToggle(false)} type="button">
                  See less
                </Button>
              ) : (
                <Button theme="ghost" scale="medium" fullWidth onClick={() => onToggle(true)} type="button">
                  + {items.length - initialCount} more
                </Button>
              )
            ) : null}
          </div>
          <div className="toggle-more-button__children">{children}</div>
        </div>
      )}
    </>
  );
};

export default ShowMore;
