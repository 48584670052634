import Config from "./config";

const getConfig = () => {
  const { hostname } = window.location;
  if (hostname === "homeowner.ojo.com" || hostname === "digs-consumer-app.prod.aws.us-east-1.ojocore.com") {
    return require("./prod").default;
  }

  if (hostname === "digs-consumer-app.stage.aws.us-east-1.ojocore.com") {
    return require("./staging").default;
  }

  if (hostname.endsWith("digs-consumer-app.dev.aws.us-east-1.ojocore.com")) {
    return require("./dev").default;
  }

  return require("./local").default;
};

export default getConfig() as Config;
