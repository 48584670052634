import { propertyVariants } from "@helpers/Constants";
import { HomeFundModel } from "@store/models/funds";
import React, { FC } from "react";

interface PropertyVariantImage {
  property_variant: HomeFundModel["property_variant"];
  className?: string;
}

const PropertyVariantImage: FC<PropertyVariantImage> = ({ property_variant, className }) => {
  const title = propertyVariants[property_variant] && propertyVariants[property_variant].title;
  return (
    <img
      className={className || ""}
      src={`/assets/images/global/home-variants/${property_variant}@2x.png`}
      alt={title || property_variant}
    />
  );
};

export default PropertyVariantImage;
