import { leadSourceStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import useSearchParams, { SearchParamTypes } from "@helpers/useSearchParams";
import { LeadSourceModel } from "@store/models/leadsource";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetLeadSourcesByUUIDQuery, useGetLeadSourcesQuery } from "@store/services/leadsources";
import React, { createContext, FC, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

export interface LeadSourceContextInterface {
  leadSource: LeadSourceModel | null;
  leadSourceLogo: string | null;
  isThumbtackLead: boolean;
}

const LeadSourceContext = createContext<LeadSourceContextInterface>({
  leadSource: null,
  leadSourceLogo: null,
  isThumbtackLead: false,
});

const cobrandedLeads = [
  {
    name: "thumbtack",
    logo: "/assets/images/global/logos/ojo-thumbtack-cobrand.svg",
  },
];

const convertToThumbtackHack = (utm_source: string) => {
  if (utm_source === "customer") {
    return "thumbtack";
  }

  return utm_source;
};

export const LeadSourceProvider: FC = (props) => {
  const isLoggedIn = useIsLoggedIn();
  const ownerPageMatch = useRouteMatch<{ uuid?: string }>("/owner/:uuid");
  const { utm_source, utm_medium, utm_campaign, utm_content } = useSearchParams<SearchParamTypes>();
  const [leadSourceInLocalStorage, setLeadSourceInLocalStorage] = useLocalStorage(leadSourceStorageKey, null);

  // If user is logged in
  const { data: leadSources } = useGetLeadSourcesQuery(undefined, {
    skip: !isLoggedIn,
  });

  // if visiting a property page unauthenticated, and the property has an associated sponsor
  const { data: leadSourcesByUUID } = useGetLeadSourcesByUUIDQuery(ownerPageMatch && ownerPageMatch.params.uuid, {
    skip: !ownerPageMatch || isLoggedIn,
  });

  useEffect(() => {
    if (isLoggedIn) {
      setLeadSourceInLocalStorage(null);
    } else {
      if (utm_source) {
        setLeadSourceInLocalStorage({
          source: convertToThumbtackHack(utm_source),
          medium: utm_medium,
          content: utm_content,
          campaign: utm_campaign,
        });
      }
    }
  }, [isLoggedIn, utm_source, utm_medium, utm_campaign, utm_content]);

  const verifiedCobrandedLead = leadSourceInLocalStorage
    ? cobrandedLeads.find((brandedLead) => brandedLead.name === leadSourceInLocalStorage.source)
    : null;

  const isThumbtackLead =
    leadSourceInLocalStorage?.source === "thumbtack" ||
    (leadSources && leadSources.filter((leadSource) => leadSource.source === "thumbtack").length > 0) ||
    (leadSourcesByUUID && leadSourcesByUUID.filter((leadSource) => leadSource.source === "thumbtack").length > 0);

  return (
    <LeadSourceContext.Provider
      value={{
        leadSource: leadSourceInLocalStorage,
        leadSourceLogo: verifiedCobrandedLead ? verifiedCobrandedLead.logo : null,
        isThumbtackLead: !!isThumbtackLead,
      }}
    >
      {props.children}
    </LeadSourceContext.Provider>
  );
};

export default LeadSourceContext;
