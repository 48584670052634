import React, { FC, MouseEvent } from "react";
import Analytics from "../../helpers/analytics";
import AnalyticsEvent from "../../helpers/analytics/events/AnalyticsEvent";

interface ITrackableLink extends React.HTMLProps<HTMLAnchorElement> {
  event: AnalyticsEvent;
}

const TrackableLink: FC<ITrackableLink> = ({ event, ...props }) => {
  const onClickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    if (props.href && props.target !== "_blank") {
      e.preventDefault();
      Analytics.trackEvent(event);
      window.location.href = props.href;
    } else {
      Analytics.trackEvent(event);
    }
  };

  return (
    <a {...props} onClick={(e) => onClickHandler(e)}>
      {props.children}
    </a>
  );
};

export default TrackableLink;
