import React, { FC } from "react";
import "./TermsAndConditions.scss";

const TermsAndConditions: FC = () => {
  return (
    <div className="terms-and-conditions">
      <p className="terms-and-conditions__company-title">
        <b>Movoto Homeowner</b>
      </p>
      <p>
        © 2022 OJO Labs and its affiliates. All rights reserved.{" "}
        <a href="https://ojo.com/terms-and-conditions" target="_blank">
          Terms and Conditions
        </a>
      </p>
    </div>
  );
};

export default TermsAndConditions;
