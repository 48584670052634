import { baseApi } from "@store/services/base";

const baseUrl = "consumer/v1/home-value/subscribe";

export const subscriberApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createSubscriber: builder.mutation({
      query: ({ ...body }) => ({ url: `${baseUrl}`, method: "POST", body }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
    unsubscribe: builder.mutation({
      query: ({ ...body }) => ({ url: "consumer/v1/unsubscribe", method: "POST", body }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {}
      },
    }),
  }),
});

export const { useCreateSubscriberMutation, useUnsubscribeMutation } = subscriberApi;
