import config from "@config";
import { AddressComponents } from "@helpers/DigsHelper";
import { recordError } from "@helpers/Errors";
import axios from "axios";

/* TODO: Check CORS issues on API to allow production usage */
export const MORTGAGE_API_URL =
  config.ENVIRONMENT === "production" ? "https://mortgage-api-dev.digs.co/v1" : "https://mortgage-api-dev.digs.co/v1";

const SMARTY_STREETS_EMBEDDED_KEY = config.REACT_APP_SMARTY_STREETS_EMBEDDED_KEY;

const getHistoricalMortgageData = () => axios.get(`${MORTGAGE_API_URL}/historic`);

// Smarty Streets - City and State required in query string, can accept unit numbers
const getSSPropertyByAddress = (address: string) => {
  return new Promise<AddressComponents>((resolve, reject) => {
    axios
      .get(
        `https://us-street.api.smartystreets.com/street-address?street=${encodeURIComponent(
          address
        )}&key=${SMARTY_STREETS_EMBEDDED_KEY}`
      )
      .then((res) => {
        if (!res.data.length) {
          reject("No Smarty Streets properties found.");
        } else {
          const { components, metadata } = res.data.length > 0 && res.data[0];
          const {
            primary_number = "",
            street_predirection = "",
            street_name = "",
            street_suffix = "",
            secondary_designator = "",
            secondary_number = "",
            city_name = "",
            state_abbreviation = "",
            zipcode = "",
          } = components;
          const streetAddress = `${primary_number} ${street_predirection} ${street_name} ${street_suffix}`;
          const property = {
            street_address: streetAddress ?? undefined,
            neighborhood: city_name,
            city: city_name,
            state: state_abbreviation,
            zip_code: zipcode,
            apt_or_unit: secondary_designator ? `${secondary_designator} ${secondary_number}` : secondary_number,
            latitude: metadata.latitude,
            longitude: metadata.longitude,
          };
          resolve(property);
        }
      })
      .catch((err) => reject(err));
  });
};

const ssSearchProperties = (value: string) =>
  axios
    .get(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?search=${encodeURIComponent(
        value
      )}&max_results=5&key=${SMARTY_STREETS_EMBEDDED_KEY}`
    )
    .catch((err) => {
      recordError("Address autocomplete error", err);
      return err;
    });

export default {
  getHistoricalMortgageData,
  getSSPropertyByAddress,
  ssSearchProperties,
};
