import Amortization from "@components/utils/Amortization";
import { AccountModel } from "@store/models/accounts";
import { MortgageLookupData, OwnerPropertyModel, PropertyMortgage } from "@store/models/properties";
import { MortgageRatesModel } from "@store/services/foundsavings";
import { addMonths, differenceInMonths, format, isPast, parseISO } from "date-fns";
import moment from "moment/moment";
import React from "react";
import { geocodeByAddress } from "react-places-autocomplete";
import { loanTerms, timelineOptions } from "./Constants";

export interface AddressComponents {
  street_address: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | number | null;
  neighborhood: string | null;
  latitude: number;
  longitude: number;
  apt_or_unit?: string | number | null;
}
class DigsHelper {
  static getClosingCosts(purchasePrice: number) {
    return 0.03 * purchasePrice;
  }
  static formatMoney(amount: any = 0, toFixed = 2, withoutCurrency = false) {
    // All money is dollars with two fractional digits...right?
    amount = parseFloat(amount);

    if (!amount) {
      // check for db null value
      amount = 0;
    }
    return `${withoutCurrency ? "" : "$"}${amount.toLocaleString(undefined, {
      minimumFractionDigits: toFixed,
      maximumFractionDigits: toFixed,
    })}`;
  }

  static cursorPositionAfterMoneyFormat(oldValue: string, oldPosition: number, newValue: string) {
    //special case: when input was completely cleared, place cursor at the end
    if (oldValue === "") {
      return newValue.length;
    }
    //calculate new position such that cursor will follow the same sequence of digits as it did before formatting
    //ex: $12|34 => $1,2|34
    const digitsBeforeCursor = oldValue.substr(0, oldPosition).replace(/[^0-9]*/g, "");
    let j = 0;
    let i;
    for (i = 0; i < newValue.length && j < digitsBeforeCursor.length; i++) {
      if (digitsBeforeCursor.charAt(j) === newValue.charAt(i)) {
        j++;
      }
    }
    return i;
  }

  static ordinal_suffix_of(i: number) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  static formatBankAccount(account: AccountModel, withInstitution: boolean = true) {
    let accountName = "";

    if (withInstitution && account.institution_name) {
      accountName += `${account.institution_name} `;
    }

    if (account.name) {
      accountName += `${account.name} `;
    }

    accountName += "****";

    if (account.mask) {
      accountName += ` ${account.mask}`;
    }

    return accountName;
  }

  static formatPhoneNumber(phoneNumberString: string | number) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    let mask = cleaned.match(/^(\d{3})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    } else if (mask) {
      return "(" + mask[1] + ") ***-****";
    }
    return null;
  }

  static formatDecimal(value: number, decimalPlaces: number = 1, style: string = "decimal") {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      style: style,
    });
  }

  static formatFixedPointNumber(value: number, digits: number) {
    const numberAccept = /[\d.]+/g;
    const parseNumber = (string: any) => (string.match(numberAccept) || []).join("");

    const parsed = parseNumber(value.toString());
    const [head, tail] = parsed.split(".");
    // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
    // must not to convert it to 1.24, it must stay 1.23
    const scaledTail = tail != null ? tail.slice(0, digits) : "";

    let number = Number.parseFloat(`${head}.${scaledTail}`);

    // For fixed format numbers deleting "." must be no-op
    // as imagine u have 123.45 then delete "." and get 12345.00 looks bad in UI
    // so we transform here 12345 into 123.45 instead of 12345.00.
    // The main disadvantage of this, that you need carefully check input value
    // that it always has fractional part
    if (digits > 0 && tail == null) {
      const paddedHead = head.padStart(digits + 1 - head.length, "0");
      number = Number.parseFloat(`${paddedHead.slice(0, -digits)}.${paddedHead.slice(-digits)}`);
    }

    if (Number.isNaN(number)) {
      return "";
    }

    const formatted = number.toLocaleString("de-CH", {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });

    return formatted;
  }

  static formatISODate(date: string) {
    return format(parseISO(date), "MMMM do, yyyy");
  }

  static formatAddress = ({ street_address, city, state, zip_code, apt_or_unit }: any, preUnit = false) => {
    let formattedAddress = "";

    if (street_address) {
      formattedAddress += street_address;
    }

    if (apt_or_unit && !preUnit) {
      formattedAddress += ` ${apt_or_unit}`;
    }

    formattedAddress += ", ";

    if (city) {
      formattedAddress += city;
    }

    formattedAddress += ", ";

    if (state) {
      formattedAddress += state;
    }

    if (zip_code) {
      formattedAddress += ` ${zip_code}`;
    }

    return formattedAddress;
  };

  static formatAddressJSX = ({ street_address, city, state, zip_code, apt_or_unit }: any) => {
    return (
      <>
        <div>
          {street_address}
          {apt_or_unit && ` ${apt_or_unit}`},{" "}
        </div>
        <div>
          {city}, {state} {zip_code}
        </div>
      </>
    );
  };

  static getAddressComponents = async (address: string) =>
    new Promise<AddressComponents>(async (resolve, reject) => {
      try {
        const results = await geocodeByAddress(address);
        if (results.length > 0) {
          const { address_components, formatted_address, geometry } = results[0];
          const addressComponents = {
            street_address: `${DigsHelper.findAddressComponentByType(
              address_components,
              "street_number"
            )} ${DigsHelper.findAddressComponentByType(address_components, "route")}`,
            city: DigsHelper.findAddressComponentByType(address_components, "locality"),
            state: DigsHelper.findAddressComponentByType(address_components, "administrative_area_level_1"),
            zip_code: DigsHelper.findAddressComponentByType(address_components, "postal_code"),
            neighborhood: DigsHelper.findAddressComponentByType(address_components, ["neighborhood", "locality"]),
            formatted_address,
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
          };
          resolve(addressComponents);
        }
      } catch (err) {
        reject(err);
      }
    });

  static getTimelineFromDateString = (date: string) => {
    const parsed = parseISO(date);
    const today = new Date();

    if (isPast(parsed)) {
      return timelineOptions[0];
    }

    const monthDiff = differenceInMonths(parsed, today);

    if (monthDiff <= 1) {
      return timelineOptions[0];
    }
    if (monthDiff <= 3) {
      return timelineOptions[1];
    }
    if (monthDiff <= 10) {
      return timelineOptions[2];
    }
    if (monthDiff <= 18) {
      return timelineOptions[3];
    }
    return timelineOptions[4];
  };

  static getDateFromTimelineString = (timeline: string) => {
    switch (timeline) {
      case "next-30-days":
        return format(addMonths(new Date(), 1), "yyyy-MM-dd");
      case "next-3-months":
        return format(addMonths(new Date(), 3), "yyyy-MM-dd");
      case "next-year":
        return format(addMonths(new Date(), 10), "yyyy-MM-dd");
      case "next-1-2-years":
        return format(addMonths(new Date(), 18), "yyyy-MM-dd");
      case "more-than-2":
        return format(addMonths(new Date(), 24), "yyyy-MM-dd");
      default:
        return format(addMonths(new Date(), 10), "yyyy-MM-dd");
    }
  };

  static findAddressComponentByType = (address_components: Array<any>, type: string | string[]): string | null => {
    if (address_components.length > 0) {
      for (let i = 0; i < address_components.length; i++) {
        for (let j = 0; j < address_components[i].types.length; j++) {
          if (type instanceof Array) {
            for (let k = 0; k < type.length; k++) {
              if (type[k] === address_components[i].types[j]) {
                return address_components[i].short_name;
              }
            }
          } else if (address_components[i].types[j] === type) {
            return address_components[i].short_name;
          }
        }
      }
    }
    return null;
  };

  static matchRule = (str: string, rule: string) => {
    const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
    return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
  };

  static formatMortgageFromBKResponse(mortgageDetails: MortgageLookupData) {
    const loan_amount = mortgageDetails.loan_amount || 0;
    const loan_origination_date = format(new Date(mortgageDetails.loan_origination_date), "yyyy-MM-dd");
    const loan_interest_rate = mortgageDetails.loan_interest_rate || 0;

    let loan_term_length = mortgageDetails.loan_term_length || 0;
    if (!loan_term_length || !loanTerms.find(({ value }) => loan_term_length === value)) {
      loan_term_length = 30;
    }

    return {
      loan_amount,
      loan_origination_date,
      loan_interest_rate,
      loan_term_length,
    };
  }

  /**
   * given distance in miles, return human friendly string
   * e.g. "2 miles", "250 ft", etc.
   */
  static formatDistance(distance: number) {
    const feet = Math.round(distance * 5280);
    if (feet < 1000) {
      return `${feet} feet`;
    }
    return `${distance.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} miles`;
  }

  static getRefinanceDetails = (mortgage: PropertyMortgage, currentRates?: MortgageRatesModel) => {
    if (
      mortgage.loan_amount &&
      mortgage.loan_interest_rate &&
      mortgage.loan_term_length &&
      currentRates &&
      currentRates.min < mortgage.loan_interest_rate
    ) {
      const currentLoanDetails = Amortization.buildAmortizationSchedule(
        mortgage.loan_amount,
        mortgage.loan_interest_rate,
        mortgage.loan_term_length * 12,
        mortgage.loan_origination_date,
        null,
        0,
        null,
        0,
        null,
        0
      );

      const totalLoanMonths = addMonths(new Date(mortgage.loan_origination_date), mortgage.loan_term_length * 12);
      const remainingLoanYears = differenceInMonths(totalLoanMonths, new Date()) / 12;

      return Amortization.refinanceCalculator(
        currentLoanDetails.monthly_payment,
        currentLoanDetails.current_balance_to_date,
        remainingLoanYears,
        mortgage.loan_interest_rate,
        currentRates.min,
        mortgage.loan_term_length
      );
    }

    return null;
  };

  /* Bi-Weekly Calculations */
  static getPotentialBiweeklySavings = (mortgage: PropertyMortgage) => {
    let totalSavings = null;
    let payoffDifference = null;

    const remainingMonthsOnLoan = moment(mortgage.loan_origination_date)
      .add(mortgage.loan_term_length * 12, "M")
      .diff(moment(), "months", true);

    const currentBalanceToDate = DigsHelper.getCurrentBalanceToDate(mortgage);

    const standardLoanDetails = DigsHelper.getTotalInterestFromToday(mortgage, currentBalanceToDate);
    const biweeklyLoanDetails = DigsHelper.getBiweeklyInterestFromToday(mortgage, currentBalanceToDate);

    if (standardLoanDetails && biweeklyLoanDetails) {
      const standardLoanDetailsTotalInterest = standardLoanDetails.total_interest_to_be_paid
        ? standardLoanDetails.total_interest_to_be_paid
        : 0;
      const biweeklyLoanDetailsTotalInterest = biweeklyLoanDetails.total_interest_to_be_paid
        ? biweeklyLoanDetails.total_interest_to_be_paid
        : 0;
      const standardLoanDetailsPayoffDate = standardLoanDetails.payoff_date ? standardLoanDetails.payoff_date : null;
      const biweeklyLoanDetailsPayoffDate = biweeklyLoanDetails.payoff_date ? biweeklyLoanDetails.payoff_date : null;

      totalSavings = standardLoanDetailsTotalInterest - biweeklyLoanDetailsTotalInterest;
      payoffDifference =
        standardLoanDetailsPayoffDate && biweeklyLoanDetailsPayoffDate
          ? moment(standardLoanDetailsPayoffDate).diff(biweeklyLoanDetailsPayoffDate, "years", false)
          : null;
    }

    return {
      total_savings: totalSavings !== null ? totalSavings / (remainingMonthsOnLoan / 12) : null,
      payoff_difference: payoffDifference,
    };
  };

  static getCurrentBalanceToDate = (mortgage: PropertyMortgage) => {
    const loanDetails = Amortization.buildAmortizationSchedule(
      mortgage.loan_amount,
      mortgage.loan_interest_rate,
      mortgage.loan_term_length * 12,
      moment(mortgage.loan_origination_date),
      null,
      0,
      null,
      0,
      null,
      0
    );

    return loanDetails.current_balance_to_date;
  };

  static getTotalInterestFromToday = (mortgage: PropertyMortgage, outstandingBalance: number) => {
    if (outstandingBalance) {
      const remainingTimeOnLoan = moment(mortgage.loan_origination_date)
        .add(mortgage.loan_term_length * 12, "M")
        .diff(moment(), "months", true);

      const loanDetails = Amortization.buildAmortizationSchedule(
        outstandingBalance,
        mortgage.loan_interest_rate,
        remainingTimeOnLoan,
        moment(),
        null,
        0,
        null,
        0,
        null,
        0
      );

      return loanDetails;
    }

    return null;
  };

  static getBiweeklyInterestFromToday = (mortgage: PropertyMortgage, outstandingBalance: number) => {
    if (outstandingBalance) {
      const remainingTimeOnLoan = moment(mortgage.loan_origination_date)
        .add(mortgage.loan_term_length * 12, "M")
        .diff(moment(), "months", true);

      return Amortization.calculateBiweeklyPaymentValues(
        outstandingBalance,
        mortgage.loan_interest_rate,
        remainingTimeOnLoan
      );
    }

    return null;
  };

  /* Mortgage */
  static isConfirmedMortgage(mortgage: OwnerPropertyModel["mortgage"]) {
    return;
  }
}

export default DigsHelper;
