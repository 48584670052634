import { baseApi } from "@store/services/base";

export interface StatusModel {
  id: number;
  service_name: string;
  status: "none" | "major" | "critical" | "unknown";
}

const transformArrayResponse = (data: { statuses: StatusModel[] }) => data.statuses;

const baseUrl = "consumer/v1/status";

export const statusesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllStatuses: builder.query({
      query: () => `${baseUrl}`,
      transformResponse: transformArrayResponse,
      providesTags: ["Status"],
    }),
  }),
});

export const { useGetAllStatusesQuery } = statusesApi;
