import moment from "moment";

class Amortization {
  static getMonthlyPayment = (loanAmount, interestRate, termInMonths) => {
    // 1200 = months in year times 100
    const rate = interestRate / 1200;
    let factor = 1;
    for (let i = 0; i < termInMonths; i++) {
      factor *= rate + 1;
    }

    return (loanAmount * factor * rate) / (factor - 1);
  };

  static buildAmortizationSchedule = (
    loanAmount,
    interestRate,
    termInMonths,
    loanOriginationDate,
    monthlyExtraPaymentStartDate = null,
    monthlyExtraPayment = 0,
    yearlyExtraPaymentMonth = null,
    yearlyExtraPayment = 0,
    oneTimeExtraPaymentDate = null,
    oneTimeExtraPayment = 0
  ) => {
    const today = moment(new Date());
    // 1200 = months in year times 100
    const rate = interestRate / 1200;
    const monthlyPayment = this.getMonthlyPayment(loanAmount, interestRate, termInMonths);
    let schedule = [];
    let payoffDate;
    let totalPrincipalPaid = 0;
    let totalInterestPaid = 0;
    let currentBalanceToDate = 0;

    let balance = loanAmount;

    for (let i = 0; i < termInMonths; i++) {
      const rowDate = moment(loanOriginationDate).add(i + 1, "M"); // start at the next month

      // monthly payment
      let rowMonthlyPayment =
        monthlyPayment +
        (monthlyExtraPaymentStartDate
          ? rowDate.isSameOrAfter(monthlyExtraPaymentStartDate)
            ? monthlyExtraPayment
            : 0
          : monthlyExtraPayment);
      rowMonthlyPayment += rowDate.month() === yearlyExtraPaymentMonth ? yearlyExtraPayment : 0;
      rowMonthlyPayment += rowDate.isSame(oneTimeExtraPaymentDate, "month") ? oneTimeExtraPayment : 0;

      // principal paid, interest and balance
      const rowInterestPaid = balance * rate;
      let rowPrincipalPaid = rowMonthlyPayment - rowInterestPaid;
      totalInterestPaid += rowInterestPaid;
      totalPrincipalPaid += rowPrincipalPaid;
      balance -= rowPrincipalPaid;

      // adjust payment if balance becomes negative
      // Balance often becomes negative when there are extra payments.
      // Also, round balance to cent to avoid this from failing when balance positive but very tiny, for example 0.000000000024783
      if (i === termInMonths - 1 || Math.round(balance * 100) / 100 <= 0) {
        rowMonthlyPayment += balance;
        rowPrincipalPaid = rowMonthlyPayment - rowInterestPaid;
        totalPrincipalPaid += balance;
        payoffDate = rowDate;
        balance = 0;

        // payoff date may have been changed, make sure one time extra payment is still valid
        if (oneTimeExtraPaymentDate && oneTimeExtraPaymentDate.isAfter(payoffDate)) {
          console.log("* Single payment will fall after the new pay-off date.");
        }
      }

      schedule[i] = {
        paymentDate: rowDate.format("MMM YYYY"),
        payment: rowMonthlyPayment.toFixed(2),
        principalPaid: rowPrincipalPaid.toFixed(2),
        interestPaid: rowInterestPaid.toFixed(2),
        totalInterestPaid: totalInterestPaid.toFixed(2),
        balance: balance.toFixed(2),
      };

      if (rowDate.isSame(today, "month") && rowDate.isSame(today, "year")) {
        currentBalanceToDate = balance.toFixed(2);
      }

      if (balance <= 0) {
        break;
      }
    }

    return {
      monthly_payment: monthlyPayment,
      payoff_date: payoffDate.format("YYYY-MM-DD"),
      total_principal_to_be_paid: totalPrincipalPaid,
      total_interest_to_be_paid: totalInterestPaid,
      current_balance_to_date: currentBalanceToDate,
      schedule: schedule,
    };
  };

  static calculateBiweeklyPaymentValues = (loanAmount, interestRate, termInMonths) => {
    let totalInterestWithBiweeklyPayment = 0;
    let avgInterestWithBiweeklyPayment = 0;
    const amortizationSchedule = [];

    const biweeklyPayment = this.getMonthlyPayment(loanAmount, interestRate, termInMonths) / 2;
    const termInYears = termInMonths / 12;

    // consider days/year = 365.25 taking into account the leap years
    const dailyRate = interestRate / (365.25 * 100);
    const fortnightsPerYear = parseInt(365.25 / 14);
    const totalFortnights = Math.ceil((termInYears * 365.25) / 14);

    let balance = loanAmount;
    let totalInterest = 0;
    let yearNumber = 0;

    for (let i = 1; i <= totalFortnights; i++) {
      const rowInterest = dailyRate * 14 * balance;
      totalInterest += rowInterest;
      balance = balance - biweeklyPayment + rowInterest;

      if (Math.round(balance * 100) / 100 <= 0) {
        balance = 0;
        totalInterestWithBiweeklyPayment = totalInterest;
        avgInterestWithBiweeklyPayment = totalInterest / i;

        if (amortizationSchedule[yearNumber]) {
          amortizationSchedule[yearNumber] = Math.round(balance * 100) / 100;
        }

        return {
          amortizationSchedule: amortizationSchedule,
          total_interest_to_be_paid: totalInterestWithBiweeklyPayment,
          /* assume today is the origination day */
          payoff_date: moment()
            .add(i * 14, "days")
            .format("YYYY-MM-DD"),
          avgInterestWithBiweeklyPayment,
        };
      } else if (i % fortnightsPerYear === 0) {
        amortizationSchedule[yearNumber] = Math.round(balance * 100) / 100;
        yearNumber++;
      }
    }
  };

  static refinanceCalculator = (
    currentMonthlyPayment,
    remainingLoanBalance,
    remainingLoanYears,
    currentLoanInterestRate,
    newLoanInterestRate,
    newLoanTermYears,
    mortgagePoints = 1,
    applicationFee = 0,
    creditCheckFee = 0,
    yourAttorneyFee = 0,
    lendersAttorneyFee = 0,
    titleSearchFee = 0,
    titleInsurance = 0,
    appraisalFee = 0,
    inspectionFees = 0,
    localFees = 0,
    documentPreparationFees = 0,
    otherFees = 0
  ) => {
    const totalCostOfPoints = (remainingLoanBalance * mortgagePoints) / 100;
    const totalCost =
      totalCostOfPoints +
      applicationFee +
      creditCheckFee +
      yourAttorneyFee +
      lendersAttorneyFee +
      titleSearchFee +
      titleInsurance +
      appraisalFee +
      inspectionFees +
      localFees +
      documentPreparationFees +
      otherFees;

    const newInterestRate = newLoanInterestRate / 1200;
    const newLoanTermInMonths = newLoanTermYears * 12;
    const compounded = Math.pow(newInterestRate + 1, newLoanTermInMonths);
    const total1 = remainingLoanBalance * compounded * newInterestRate;
    const total2 = compounded - 1;

    if (total1 === 0) {
      return;
    }

    const newMonthlyPayment = total1 / total2;
    const monthlySavings = currentMonthlyPayment - newMonthlyPayment;

    let monthsToRecoup = totalCost / monthlySavings;
    monthsToRecoup = Math.round(monthsToRecoup * 100) / 100;

    const differenceInInterest =
      currentMonthlyPayment * remainingLoanYears * 12 - newMonthlyPayment * newLoanTermInMonths;

    return {
      newMonthlyPayment: newMonthlyPayment,
      monthlySavings: monthlySavings,
      differenceInInterest: differenceInInterest,
      monthsToRecoup: monthsToRecoup,
    };
  };
}

export default Amortization;
