import React, { FC, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Card } from "../index";
import "./Modal.scoped.scss";

const ANIMATION_DURATION = 500;

export interface ModalPropsInterface {
  animationInDirection?: "up" | "down" | "left" | "right";
  animationOutDirection?: "up" | "down" | "left" | "right";
  className?: string;
  showBack?: boolean;
  showClose?: boolean;
  visible?: boolean;
  onRequestBack?: () => void;
  onRequestClose?: () => void;
  maxWidth?: string | number;
  ariaLabel?: string;
  onModalShown?: () => void;
  onModalDismissed?: () => void;
  theme?: "default" | "center" | "fullscreen";
  zIndex?: number;
}

const Modal: FC<ModalPropsInterface> = ({
  animationInDirection = "up",
  animationOutDirection = "down",
  className,
  showBack = false,
  showClose = true,
  visible = false,
  onRequestBack,
  onRequestClose,
  maxWidth,
  ariaLabel = "",
  onModalShown,
  onModalDismissed,
  theme = "default",
  zIndex = 9999,
  children,
}) => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if (visible) {
      document.body.classList.add("modal-locked");
      setShown(true);
      onModalShown && onModalShown();
    } else {
      if (shown) {
        onModalDismissed && onModalDismissed();
      }
      setTimeout(() => {
        document.body.classList.remove("modal-locked");
      }, ANIMATION_DURATION);
    }
    return () => {
      document.body.classList.remove("modal-locked");
    };
  }, [visible]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape" && onRequestClose) {
        onRequestClose();
      }
      if (e.key === "ArrowLeft" && onRequestBack) {
        onRequestBack();
      }
    },
    [onRequestClose, onRequestBack]
  );

  const handleOnRequestBack = useCallback(
    (e) => {
      e.stopPropagation();
      onRequestBack && onRequestBack();
    },
    [onRequestBack]
  );

  const handleOnRequestClose = useCallback(
    (e) => {
      e.stopPropagation();
      onRequestClose && onRequestClose();
    },
    [onRequestClose]
  );

  const content = (
    <div
      className={`modal modal--animation-out-${animationOutDirection} modal--animation-in-${animationInDirection} ${
        visible ? "visible" : ""
      } ${!visible && shown ? "hide" : ""} ${shown ? "" : "hidden"} ${
        className ? className : ""
      } modal--theme-${theme}`}
      tabIndex={-1}
      aria-modal="true"
      aria-label={ariaLabel}
      role="dialog"
      aria-hidden={visible ? "false" : "true"}
      onKeyDown={handleKeyDown}
      style={{ zIndex }}
    >
      <div className="modal__mask" onClick={handleOnRequestClose} />
      <Card className="modal__card" style={{ maxWidth: maxWidth || undefined }}>
        <Card.Body className="modal__card-body">
          {showBack && (
            <button onClick={handleOnRequestBack} className="modal__back">
              <i className="digs-icon-left-arrow"></i>
              <span className="sr-only">Go back</span>
            </button>
          )}
          {showClose && (
            <button onClick={handleOnRequestClose} className="modal__close">
              <i className="digs-icon-close"></i>
              <span className="sr-only">Close modal</span>
            </button>
          )}
          <div className="modal__body">{children}</div>
        </Card.Body>
      </Card>
    </div>
  );

  return createPortal(content, document.body);
};

export default Modal;
