import { PROPERTY_LIMIT } from "@helpers/Constants";
import {
  MortgageLookupData,
  OwnerPropertyModel,
  PropertyDetails,
  UserValuationParameters,
} from "@store/models/properties";
import { baseApi } from "@store/services/base";

const baseUrl = "/consumer/v1/properties";
const unauthBaseUrl = "/consumer/v1/owner";

const transformArrayResponse = (data: { properties: OwnerPropertyModel[] }) =>
  data.properties.filter((property) => property.type === "owner");
const transformSingleResponse = (data: { property: OwnerPropertyModel }) => data.property;

export const propertiesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllProperties: builder.query({
      query: () => `${baseUrl}`,
      transformResponse: transformArrayResponse,
      providesTags: (properties) => (properties?.length ? properties.map(({ id }) => ({ type: "Property", id })) : []),
    }),
    getPropertyById: builder.query({
      query: (id: number) => `${baseUrl}/${id}`,
      transformResponse: transformSingleResponse,
    }),
    getUserAvmById: builder.query({
      query: (id: number) => `${baseUrl}/${id}/user-valuation`,
      transformResponse: (data: { user_valuation: UserValuationParameters }) => data.user_valuation,
    }),
    getPropertyByUUID: builder.query({
      query: (uuid: string) => `${unauthBaseUrl}/${uuid}`,
      transformResponse: transformSingleResponse,
    }),
    getMortgageByUUID: builder.query({
      query: (uuid: string) => `${unauthBaseUrl}/${uuid}/mortgage`,
      transformResponse: (data: { mortgage: MortgageLookupData }) => data.mortgage,
      keepUnusedDataFor: 86400,
    }),
    getQuizResultsByUUID: builder.query({
      query: ({ quizId, uuid }: { quizId: number; uuid: string }) => `${unauthBaseUrl}/${uuid}/aaa-quiz/${quizId}`,
      transformResponse: (data: { results: string[] | null }) => data.results,
    }),
    createProperty: builder.mutation({
      query: ({ id, ...body }) => ({ url: `${baseUrl}`, method: "POST", body }),
      transformResponse: transformSingleResponse,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: property } = await queryFulfilled;
          dispatch(
            propertiesApi.util.updateQueryData("getAllProperties", undefined, (draft) => draft.concat([property]))
          );
        } catch (err) {}
      },
      invalidatesTags: (property) => [{ type: "Property", id: property ? property.id : 0 }, "Announcements"],
    }),
    updateProperty: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: transformSingleResponse,
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        // When we start the request, just immediately update the cache
        const patchResult = dispatch(
          propertiesApi.util.updateQueryData("getAllProperties", undefined, (draft) => {
            return draft.map((property) => (property.id === id ? { ...property, ...patch } : property));
          })
        );
        try {
          const { data: updatedProperty } = await queryFulfilled;
          dispatch(
            propertiesApi.util.updateQueryData("getAllProperties", undefined, (draft) => {
              return draft.map((property) => (property.id === id ? { ...updatedProperty } : property));
            })
          );
        } catch (err) {
          patchResult.undo();
        }
      },
      invalidatesTags: ["Announcements"],
    }),
    deleteProperty: builder.mutation({
      query: (id: number) => ({ url: `${baseUrl}/${id}`, method: "DELETE" }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            propertiesApi.util.updateQueryData("getAllProperties", undefined, (draft) => {
              return draft.filter((property) => property.id !== id);
            })
          );
        } catch (err) {}
      },
    }),
    updatePropertyDetails: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/details`,
        method: "POST",
        body,
      }),
      transformResponse: (data: { details: PropertyDetails }) => data.details,
      invalidatesTags: ["Tasks", "ChecklistCounts", "RecentlySolds"],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        // When we start the request, just immediately update the cache
        const patchResult = dispatch(
          propertiesApi.util.updateQueryData("getAllProperties", undefined, (draft) => {
            return draft.map((property) =>
              property.id === id ? { ...property, details: { ...property.details, ...patch } } : property
            );
          })
        );
        try {
          await queryFulfilled;
        } catch (err) {
          patchResult.undo();
        }
      },
    }),
    updatePropertyMortgage: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/mortgage`,
        method: "POST",
        body,
      }),
      transformResponse: (data: { property: OwnerPropertyModel }) => data.property,
      invalidatesTags: ["Announcements"],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedProperty } = await queryFulfilled;
          const patchResult = dispatch(
            propertiesApi.util.updateQueryData("getAllProperties", undefined, (draft) => {
              return draft.map((property) => (property.id === id ? { ...property, ...updatedProperty } : property));
            })
          );
        } catch {}
      },
    }),
    updateUserAvm: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${baseUrl}/${id}/user-valuation`,
        method: "POST",
        body,
      }),
      transformResponse: (data: { property: OwnerPropertyModel }) => data.property,
      invalidatesTags: (details, error, { id }) => [{ type: "Property", id }],
    }),
    updateQuizResultsByUUID: builder.mutation({
      query: ({ quizId, uuid, results }: { quizId: number; uuid: string; results: string[] }) => ({
        url: `${unauthBaseUrl}/${uuid}/aaa-quiz/${quizId}`,
        method: "POST",
        body: { results },
      }),
      transformResponse: (data: { results: string[] }) => data.results,
    }),
  }),
});

export const {
  useGetAllPropertiesQuery,
  useGetPropertyByIdQuery,
  useGetPropertyByUUIDQuery,
  useGetMortgageByUUIDQuery,
  useLazyGetMortgageByUUIDQuery,
  useGetQuizResultsByUUIDQuery,
  useGetUserAvmByIdQuery,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,
  useUpdatePropertyMortgageMutation,
  useUpdatePropertyDetailsMutation,
  useUpdateUserAvmMutation,
  useUpdateQuizResultsByUUIDMutation,
} = propertiesApi;

export const useGetPropertyLimitReachedQuery = (arg?: undefined, options?: { skip?: boolean }) => {
  const query = useGetAllPropertiesQuery(undefined, options);
  const data = query.isSuccess ? query.data.length >= PROPERTY_LIMIT : false;
  return { ...query, data };
};

export const useSelectPropertyByIdQuery = (
  id: number,
  options?: { skip?: boolean; refetchOnMountOrArgChange?: boolean | number }
) => {
  return useGetAllPropertiesQuery(undefined, {
    ...options,
    selectFromResult: ({ data, ...props }) => ({
      ...props,
      data: data ? data.find((property) => property.id === id) : undefined,
    }),
  });
};

export const useGetOwnerPropertiesQuery = (arg?: undefined, options?: { skip?: boolean }) => {
  return useGetAllPropertiesQuery(undefined, {
    ...options,
    selectFromResult: ({ data, ...props }) => ({
      ...props,
      data: data ? data.filter((property) => property.type === "owner") : undefined,
    }),
  });
};
