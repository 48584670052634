import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import NavItem from "../navItem/NavItem";
import NavItemGroup, { NavItemGroupProps } from "../navItemGroup/NavItemGroup";
import ShowMore from "../paginated-list/ShowMore";

interface NavProps {
  hideNav?: boolean;
  items: NavItemGroupProps[];
  className?: string;
}

const Nav: FC<NavProps> = ({ hideNav, items, className = "" }) => {
  const { pathname } = useLocation();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [showingMore, setShowingMore] = useState<boolean>(false);

  const onToggleShowMore = useCallback((showMore) => {
    setShowingMore(showMore);
  }, []);

  useEffect(() => {
    setExpandedIndex(
      items.findIndex(({ to, expandable, expanded }) => (to && expandable && pathname.indexOf(to) > -1) || expanded)
    );
  }, [items.length, pathname]);

  const navItems = items.map(({ items, to, ...itemProps }, i) =>
    items ? (
      <NavItemGroup
        {...itemProps}
        to={to}
        key={`item-group-${i}`}
        expanded={i === expandedIndex}
        onToggleExpanded={() => setExpandedIndex(expandedIndex === i ? -1 : i)}
      >
        {items.map((item, j) => (
          <NavItem {...item} key={`subitem-${i}-${j}`} />
        ))}
      </NavItemGroup>
    ) : (
      <NavItem {...itemProps} to={to} key={`item-${i}`} />
    )
  );

  return (
    <nav className={`navigation ${className}`}>
      <ul className={`navigation__items ${hideNav ? "navigation__items--hidden" : ""}`}>
        <ShowMore
          expanded={showingMore}
          onToggle={onToggleShowMore}
          items={navItems}
          initialCount={5}
          seeLessButton={true}
        />
      </ul>
    </nav>
  );
};

export default Nav;
