import AnalyticsEvent from "./AnalyticsEvent";

abstract class SupportEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Support";
  readonly data: any = {};
}

export class ContactSupportClickedEvent extends SupportEvent {
  readonly name = "Support.ContactSupportClicked";
}
