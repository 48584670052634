import { LeadSourceModel } from "@store/models/leadsource";
import { baseApi } from "@store/services/base";

const transformArrayResponse = (data: { lead_sources: LeadSourceModel[] }) => data.lead_sources;

const baseUrl = "consumer/v1/lead-sources";

export const leadSourcesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLeadSources: builder.query({
      query: () => baseUrl,
      transformResponse: transformArrayResponse,
      providesTags: ["LeadSources"],
    }),
    getLeadSourcesByUUID: builder.query({
      query: (uuid) => `consumer/v1/owner/${uuid}/lead-sources`,
      transformResponse: transformArrayResponse,
      providesTags: ["LeadSources"],
    }),
  }),
});

export const { useGetLeadSourcesQuery, useGetLeadSourcesByUUIDQuery } = leadSourcesApi;
