import { AnnouncementType } from "@components/utils/announcements";

export type RegistrationModalIntent =
  | "link_my_mortgage"
  | "home_value"
  | "nav_home_value"
  | "nav_mortgage"
  | "nav_maintenance"
  | "nav_property"
  | "initial_view"
  | "invite_coowner"
  | "checklist"
  | "announcements"
  | "maintenance-fund-enroll"
  | "maintenance-tooltip"
  | "property_metrics_home-value"
  | "property_metrics_mortgage"
  | "property_metrics_maintenance"
  | "mortgage_card";

export interface RegistrationModalProps {
  intent?: RegistrationModalIntent;
}

export type UiState = {
  sponsor_panel: boolean;
  sponsor_message: boolean;
  sponsor_message_dismissed_at: string | null;
  login_modal: boolean;
  registration_modal: boolean;
  registration_modal_props: RegistrationModalProps;
  dashboard_welcome_dismissed: boolean;
  dashboard_property_type_dismissed: boolean;
  announcement_dismissed?: {
    [uuid: string]: AnnouncementType[];
  };
};

export const defaultUiState = {
  sponsor_panel: false,
  sponsor_message: true,
  sponsor_message_dismissed_at: null,
  login_modal: false,
  registration_modal: false,
  registration_modal_props: {},
  dashboard_welcome_dismissed: false,
  dashboard_property_type_dismissed: false,
};
