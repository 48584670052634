import { ChecklistTask } from "@store/models/checklist";
import { CoownerInviteModel } from "@store/models/coownerinvite";
import { LeadModel } from "@store/models/properties";
import AnalyticsEvent from "./AnalyticsEvent";

export type TaskSource = "property" | "checklist";

export type MaintenanceFundEnrollLocation = "checklist" | "maintenance-fund";

abstract class PropertyEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Property";
  readonly data: any = {};
}

export class MortgageLinkedEvent extends PropertyEvent {
  readonly name = "Property.MortgageLinked";
}

export class PublicOwnerPageViewedEvent extends PropertyEvent {
  readonly name = "Property.PublicOwnerPageViewed";
}

export class LinkMortgageModalShown extends PropertyEvent {
  readonly name = "Property.LinkMortgageModalShown";
}

export class LinkMortgageModalDismissed extends PropertyEvent {
  readonly name = "Property.LinkMortgageModalDismissed";
}

export class RefinanceCTAClicked extends PropertyEvent {
  readonly name = "Property.RefinanceCTAClicked";
}

export class RebrandAnnouncementCTAClicked extends PropertyEvent {
  readonly name = "Property.RebrandAnnouncementCTAClicked";
}

export class RequestAgentSubmitted extends PropertyEvent {
  readonly name = "Property.RequestAgentSubmitted";

  constructor(lead: LeadModel) {
    super();
    this.data.lead = lead;
  }
}

export class ContactAgentSubmitted extends PropertyEvent {
  readonly name = "Property.ContactAgentSubmitted";

  constructor(lead: LeadModel) {
    super();
    this.data.lead = lead;
  }
}

export class CoownerInviteStarted extends PropertyEvent {
  readonly name = "Property.CoownerInvite.Started";
}

export class CoownerInviteDismissed extends PropertyEvent {
  readonly name = "Property.CoownerInvite.Dismissed";
}

export class CoownerInviteSent extends PropertyEvent {
  readonly name = "Property.CoownerInvite.Sent";

  constructor(invite: CoownerInviteModel, wasResent?: boolean) {
    super();
    this.data.invite = invite;
    this.data.wasResent = wasResent || false;
  }
}

export class CoownerInviteAccepted extends PropertyEvent {
  readonly name = "Property.CoownerInvite.Accepted";

  constructor(invite: CoownerInviteModel) {
    super();
    this.data.invite = invite;
  }
}

export class CoownerInviteRejected extends PropertyEvent {
  readonly name = "Property.CoownerInvite.Rejected";

  constructor(invite: CoownerInviteModel) {
    super();
    this.data.invite = invite;
  }
}

export class TaskCompleted extends PropertyEvent {
  readonly name = "Property.Task.Completed";

  constructor(task: ChecklistTask) {
    super();
    this.data.task = task;
  }
}

export class TaskIrrelevant extends PropertyEvent {
  readonly name = "Property.Task.Irrelevant";

  constructor(task: ChecklistTask) {
    super();
    this.data.task = task;
  }
}

export class TaskSeeMorePros extends PropertyEvent {
  readonly name = "Property.Task.SeeMorePros";

  constructor(task: ChecklistTask) {
    super();
    this.data.task = task;
  }
}

export class TaskClicked extends PropertyEvent {
  readonly name = "Property.Task.Clicked";

  constructor(task: ChecklistTask, source: TaskSource) {
    super();
    this.data.task = task;
    this.data.source = source;
  }
}

export class SeeMoreTasksClicked extends PropertyEvent {
  readonly name = "Property.Task.SeeMoreClicked";
}

export class MaintenanceFundEnrollClicked extends PropertyEvent {
  readonly name = "Property.MaintenanceFund.EnrollClicked";

  constructor(location: MaintenanceFundEnrollLocation) {
    super();
    this.data.location = location;
  }
}

export class RecentlySoldSeen extends PropertyEvent {
  readonly name = "Property.RecentlySold.Seen";

  constructor(zipCode: string) {
    super();
    this.data.zipCode = zipCode;
  }
}

export class RecentlySoldClicked extends PropertyEvent {
  readonly name = "Property.RecentlySold.Clicked";

  constructor(zipCode: string) {
    super();
    this.data.zipCode = zipCode;
  }
}
