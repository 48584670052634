// organize-imports-ignore
import "react-app-polyfill/stable";
import config from "@config";
import { recordError } from "@helpers/Errors";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/scss/index.scss";
import Notifications from "./components/utils/notifications/Notifications";
import Analytics from "./helpers/analytics";
import * as serviceWorker from "./serviceWorker";
import SplitProvider from "./SplitProvider";
import store from "./store";
import { HelmetProvider } from "react-helmet-async";
import { clearAuthTokenData, getAuthTokenData } from "@helpers/auth";
import ErrorBoundary from "@components/global/errorBoundary/ErrorBoundary";
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const ROOT_URL = config.REACT_APP_API_URL || "http://digs:8888";

Analytics.initialize();

// Initialize Sentry
Sentry.init({
  dsn: "https://d17de59310974e60a1545bc7961d7cd0@sentry.io/3486966",
  environment: config.REACT_APP_SENTRY_ENVIRONMENT || "unknown",
});

axios.defaults.baseURL = ROOT_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (conf) => {
    const tokenData = getAuthTokenData();
    conf.headers = {
      Authorization: tokenData && tokenData.access_token ? `Bearer ${tokenData.access_token}` : undefined,
    };
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (next) => {
    return Promise.resolve(next);
  },
  (error) => {
    // You can handle error here and trigger warning message without get in the code inside
    if (401 === error.response.status) {
      clearAuthTokenData();
    }
    return Promise.reject(error);
  }
);

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  const { reason } = e;
  recordError(`Unhandled rejection: ${reason.message}`, reason);
};

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <SplitProvider>
          <Notifications />
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </SplitProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
