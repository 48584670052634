import Nav from "@components/global/nav/Nav";
import { NavItemGroupProps } from "@components/global/navItemGroup/NavItemGroup";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./MobileNavigation.scss";

interface MobileNavigation {
  isLoggedIn: boolean;
  items: NavItemGroupProps[];
}

const MobileNavigation: FC<MobileNavigation> = ({ isLoggedIn, items }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const history = useHistory();

  /* Lock body / prevent scroll on open */
  useEffect(() => {
    document.body.className = expanded ? "locked" : "";
    return () => {
      document.body.className = "";
    };
  }, [expanded]);

  /* Automatically close on route change */
  useEffect(() => {
    const unlisten = history.listen(() => {
      setExpanded(false);
    });
    return () => {
      unlisten();
    };
  });

  const secondaryItems = [];
  if (!isLoggedIn) {
    secondaryItems.push({
      name: "Login",
      to: "/",
    });
    secondaryItems.push({
      name: "Register",
      to: "/register?product=homeowner",
    });
  }
  if (isLoggedIn) {
    secondaryItems.push({
      name: "Settings",
      to: "/settings",
      icon: "gear",
    });
  }
  secondaryItems.push({
    name: "Support",
    href: "https://help.digs.co/",
    newTab: true,
  });
  if (!isLoggedIn) {
    secondaryItems.push({
      name: "About Movoto Homeowner",
      href: "https://ojo.com/homeowner",
      newTab: true,
    });
  }
  if (isLoggedIn) {
    secondaryItems.push({
      name: "Logout",
      to: "/logout",
    });
  }

  return (
    <>
      <button
        className="mobile-nav__toggle"
        onClick={() => {
          setExpanded(true);
        }}
      >
        <i className="digs-icon-menu" />
        <span className="sr-only">Open sidebar menu</span>
      </button>
      <div className={`mobile-nav ${expanded ? "mobile-nav--expanded" : ""}`}>
        <div className="mobile-nav__container">
          <div className="mobile-nav__top">
            <button
              onClick={() => {
                setExpanded(false);
              }}
            >
              <i className="digs-icon-close" />
              <span className="sr-only">Close sidebar menu</span>
            </button>
          </div>
          <div className="navigation-mobile">
            <Nav items={items} />
          </div>
          <Nav items={secondaryItems} className="navigation-mobile-secondary" />
          <div
            className="mobile-nav__mask"
            onClick={() => {
              setExpanded(false);
            }}
          >
            <span className="sr-only">Close sidebar menu</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNavigation;
