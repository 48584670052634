import React, { FC, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import "./Button.scoped.scss";

export interface IButton {
  theme?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "light"
    | "white"
    | "ghost"
    | "warn"
    | "outline-primary"
    | "outline-secondary"
    | "outline-tertiary"
    | "link-primary"
    | "text-tertiary"
    | "text-black"
    | "outline-black";
  scale?: "large" | "medium" | "small";
  icon?: string;
  iconOnly?: boolean;
  loading?: boolean;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
  fullWidth?: boolean;
  to?: string;
  className?: string;
  target?: string;
  tabIndex?: number;
  onClick?: MouseEventHandler;
}

const ButtonWrapper: FC<IButton> = ({ children, to, ...componentProps }) => {
  if (to) {
    const isExternalURL = to.includes("http");

    if (isExternalURL) {
      return (
        <a href={to} {...componentProps}>
          {children}
        </a>
      );
    } else {
      return (
        <Link to={to} {...componentProps}>
          {children}
        </Link>
      );
    }
  } else {
    return <button {...componentProps}>{children}</button>;
  }
};

const Button: FC<IButton> = ({
  theme = "primary",
  scale = "large",
  icon,
  iconOnly = false,
  type = "submit",
  fullWidth = false,
  loading,
  disabled,
  className,
  to,
  children,
  ...props
}) => {
  return (
    <ButtonWrapper
      {...props}
      to={to}
      className={`btn btn--${theme} btn--${scale} ${icon ? "btn--icon" : ""} ${iconOnly ? "btn--icon-only" : ""} ${
        className || ""
      } ${fullWidth ? "btn--full-width" : ""}`}
      disabled={disabled || loading}
      type={type}
    >
      <span className="btn__inner">
        {icon && !loading && <i className={`${icon} btn__icon`} />}
        <div className="btn__inner__content">
          {loading && <i className="digs-icon-loader spin" />}
          {children}
        </div>
      </span>
    </ButtonWrapper>
  );
};

export default Button;
