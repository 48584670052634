import React, { FC, MouseEventHandler } from "react";
import "./CardFooter.scoped.scss";

interface CardFooterButton {
  title: string;
  loading?: boolean;
  className?: string;
  icon?: string;
  onClick: MouseEventHandler;
}

const CardFooterButton: FC<CardFooterButton> = ({
  title,
  loading = false,
  className,
  icon = "plus-circle",
  onClick,
  ...props
}) => {
  return (
    <div className={`card-footer card ${loading ? "loading" : ""} ${className}`} {...props}>
      <button onClick={onClick} disabled={loading}>
        <span>{title}</span>
        {!loading && <i className={`digs-icon-${icon}`} />}
        {loading && <i className="digs-icon-loader" />}
      </button>
    </div>
  );
};

export default CardFooterButton;
