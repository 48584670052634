import Config from "./config";

const stageConfig: Config = {
  ENVIRONMENT: "staging",
  REACT_APP_API_URL: "https://digs-api.stage.aws.us-east-1.ojocore.com/",
  REACT_APP_PLAID_ENV: "sandbox",
  REACT_APP_PLAID_PUBLIC_KEY: "05ae3dc9c04ab1546c3d11e5e750fc",
  REACT_APP_PLAID_WEBHOOK_URL: "https://digs-api.stage.aws.us-east-1.ojocore.com/api/plaid/webhook",
  REACT_APP_GOOGLE_TAG_MANAGER_ID: "GTM-PCTH4RP",
  REACT_APP_GOOGLE_TAG_MANAGER_AUTH: "ysDfKeLD_4JNTAUlt9ycDQ",
  REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW: "env-5",
  REACT_APP_SENTRY_ENVIRONMENT: "staging",
  REACT_APP_SMARTY_STREETS_EMBEDDED_KEY: "31985545007525338",
  REACT_APP_SATISMETER_WRITE_KEY: "e4Xu2piDfImFEmas",
  SPLIT_IO_AUTH_KEY: "h5j92duc1abl3jcdup3s8ltjbtoohspvbrq2",
};

export default stageConfig;
