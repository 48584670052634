import { authTokenStorageKey } from "./Constants";

export interface AuthTokenData {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export const getAuthTokenData = (): AuthTokenData | null => {
  try {
    const tokenData = window.localStorage.getItem(authTokenStorageKey) || "";
    return tokenData ? JSON.parse(tokenData) : "";
  } catch (err) {
    console.error(`Unable to parse auth token data`, err);
  }
  return null;
};

export const setAuthTokenData = (tokenData: AuthTokenData | null) => {
  try {
    // Save to local storage
    window.localStorage.setItem(authTokenStorageKey, tokenData ? JSON.stringify(tokenData) : "");

    // We dispatch a custom event so every useLocalStorage hook are notified
    window.dispatchEvent(new Event("local-storage"));
  } catch (error) {
    console.warn(`Error setting localStorage key “${authTokenStorageKey}”:`, error);
  }
};

export const clearAuthTokenData = () => {
  setAuthTokenData(null);
};
