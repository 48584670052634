import React, { FC, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import "./Panel.scoped.scss";

const ANIMATION_DURATION = 500;

export interface PanelPropsInterface {
  className?: string;
  showClose?: boolean;
  visible?: boolean;
  onRequestClose?: () => void;
  maxWidth?: string | number;
  ariaLabel?: string;
}

const Panel: FC<PanelPropsInterface> = ({
  className,
  showClose = true,
  visible = false,
  onRequestClose,
  maxWidth,
  ariaLabel = "",
  children,
}) => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if (visible) {
      document.body.classList.add("panel-locked");
      setShown(true);
    } else {
      setTimeout(() => {
        document.body.classList.remove("panel-locked");
      }, ANIMATION_DURATION);
    }
    return () => {
      document.body.classList.remove("panel-locked");
    };
  }, [visible]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape" && onRequestClose) {
        onRequestClose();
      }
    },
    [onRequestClose]
  );

  const content = (
    <div
      className={`panel ${visible ? "visible" : ""} ${!visible && shown ? "hide" : ""} ${shown ? "" : "hidden"} ${
        className ? className : ""
      }`}
      tabIndex={-1}
      aria-modal="true"
      aria-label={ariaLabel}
      role="dialog"
      aria-hidden={visible ? "false" : "true"}
      onKeyDown={handleKeyDown}
    >
      <div className="panel__mask" onClick={onRequestClose} />
      <div className="panel__container" style={{ maxWidth: maxWidth || undefined }}>
        <div className="panel__content">
          {showClose && (
            <button onClick={onRequestClose} className="panel__close">
              <i className="digs-icon-close"></i>
              <span className="sr-only">Close modal</span>
            </button>
          )}
          <div className="panel__body">{children}</div>
        </div>
      </div>
    </div>
  );

  return createPortal(content, document.body);
};

export default Panel;
