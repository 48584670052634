import { TextInputFormItem } from "@components/global";
import Yup from "@helpers/Yup";
import React, { FC } from "react";
import DigsHelper from "../../../../../helpers/DigsHelper";

export const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email address required"),
});

interface Email {
  formik: any;
}

const Email: FC<Email> = ({ formik, ...props }) => {
  return (
    <div className="signup-onboarding--step">
      {props.children || (
        <>
          <h1>
            <span>Where should we send your first report for</span> {DigsHelper.formatAddress(formik.values, false)}?
          </h1>
          <p></p>
        </>
      )}
      <TextInputFormItem
        errors={formik.errors.email}
        touched={!!formik.touched.email}
        name="email"
        label="Email Address"
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
    </div>
  );
};

export default Email;
