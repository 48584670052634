import { propertyStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import { OwnerPropertyModel } from "@store/models/properties";
import React, { createContext, FC, useEffect, useState } from "react";

export type TemporaryPropertyModel = (Partial<OwnerPropertyModel> & { search?: string }) | null;

export interface PropertyContextInterface {
  temporaryProperty: TemporaryPropertyModel;
  setTemporaryPropertyAttributes: (fieldsToUpdate: TemporaryPropertyModel) => void;
  clearTemporaryPropertyAttributes: () => void;
}

const PropertyContext = createContext<PropertyContextInterface>({
  temporaryProperty: null,
  setTemporaryPropertyAttributes: () => {},
  clearTemporaryPropertyAttributes: () => {},
});

export const PropertyProvider: FC = (props) => {
  const [temporaryPropertyInLocalStorage, setTemporaryPropertyInLocalStorage] = useLocalStorage(
    propertyStorageKey,
    null
  );

  // Set initial value to whatever is in local storage (or default)
  const [temporaryProperty, setTemporaryProperty] = useState<TemporaryPropertyModel>(temporaryPropertyInLocalStorage);

  const setTemporaryPropertyAttributes = (fieldsToUpdate: TemporaryPropertyModel) => {
    setTemporaryProperty({ ...temporaryProperty, ...fieldsToUpdate });
  };

  const clearTemporaryPropertyAttributes = () => {
    setTemporaryProperty(null);
  };

  useEffect(() => {
    setTemporaryPropertyInLocalStorage(temporaryProperty);
  }, [temporaryProperty]);

  return (
    <PropertyContext.Provider
      value={{ temporaryProperty, setTemporaryPropertyAttributes, clearTemporaryPropertyAttributes }}
    >
      {props.children}
    </PropertyContext.Provider>
  );
};

export default PropertyContext;
