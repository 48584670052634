import React, { CSSProperties, FC, MouseEvent } from "react";
import { Link } from "react-router-dom";
import "./Card.scss";
import CardBody from "./cardBody/CardBody";
import CardExpand from "./cardExpand/CardExpand";
import CardFooter from "./cardFooter/CardFooter";
import CardFooterButton from "./cardFooter/CardFooterButton";
import CardForm from "./cardForm/CardForm";
import CardLink from "./cardLink/CardLink";
import CardMeta from "./cardMeta/CardMeta";

export interface CardProps {
  onClick?: (e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLDivElement>) => void;
  className?: string;
  style?: CSSProperties;
  type?: "dark";
  children?: React.ReactNode;
  /* RouterLink */
  isLink?: boolean;
  to?: string;
  /* Anchors */
  href?: string;
  target?: string;
  referrer?: string;
}

interface CardInterface extends FC<CardProps> {
  Body: typeof CardBody;
  Meta: typeof CardMeta;
  Link: typeof CardLink;
  Expand: typeof CardExpand;
  Form: typeof CardForm;
  Footer: typeof CardFooter;
  FooterButton: typeof CardFooterButton;
}

const Card: CardInterface = ({ className, children, type, isLink = false, to, href = undefined, ...props }) => {
  const classes = `card ${type ? type : ""} ${isLink ? "card--link" : ""} ${className ? className : ""}`;

  if (to) {
    return (
      <Link className={classes} to={to} {...props}>
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a className={classes} href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <div {...props} className={classes}>
      {children}
    </div>
  );
};

Card.Body = CardBody;
Card.Meta = CardMeta;
Card.Link = CardLink;
Card.Expand = CardExpand;
Card.Form = CardForm;
Card.Footer = CardFooter;
Card.FooterButton = CardFooterButton;

export default Card;
