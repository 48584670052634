import { Button, FormItem, ListItem, PhoneInputFormItem, TextInputFormItem } from "@components/global";
import Panel from "@components/global/panel/Panel";
import SponsorAvatar from "@components/global/sponsorAvatar/SponsorAvatar";
import TrackableLink from "@components/global/TrackableLink";
import { ReferralContext, UiContext } from "@components/utils/contexts";
import Yup from "@helpers/Yup";
import { LeadModel, OwnerPropertyModel } from "@store/models/properties";
import { SponsorModel } from "@store/models/sponsor";
import {
  useGetOwnerPropertiesQuery,
  useGetPropertyByUUIDQuery,
  useSelectPropertyByIdQuery,
} from "@store/services/properties";
import { useContactSponsorMutation } from "@store/services/sponsor";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import { useFormik } from "formik";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import Analytics from "../../../../helpers/analytics";
import { createNotification } from "../../../utils/notifications/Notifications";
import "./SponsorPanel.scss";

interface SponsorPanel {
  sponsor?: SponsorModel;
  visible: boolean;
  property?: OwnerPropertyModel;
}

const MessageSchema = Yup.object().shape({
  purpose: Yup.string().required("Purpose is required"),
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string().required("Phone number is required").length(10, "Phone number must be 10 digits"),
  message: Yup.string().when("purpose", {
    is: "sell",
    then: Yup.string(),
    otherwise: Yup.string().required("Message is required"),
  }),
});

const purposeOptions = [
  {
    label: "Sell My Home",
    value: "sell",
    icon: "/assets/images/global/icons/sell-home.svg",
    iconAltText: "House with dollar sign",
    copy: "Thinking about selling your home? Talk to an expert about how much you can sell for.",
  },
  {
    label: "General Question",
    value: "general",
    icon: "/assets/images/global/icons/general-question.svg",
    iconAltText: "question mark",
    copy: "Do you have a general question about your home, real estate, or Movoto? Ask us here!",
  },
  {
    label: "Technical Support",
    value: "technical",
    icon: "/assets/images/global/icons/tech-support.svg",
    iconAltText: "gear",
    copy: "Did something go wrong or not work correctly? Tell us about it here.",
  },
];

const SponsorPanel: FC<SponsorPanel> = ({ sponsor = null, visible }) => {
  const history = useHistory();
  const [sendMessage, { isLoading: loading }] = useContactSponsorMutation();
  const [showMessagePanel, setShowMessagePanel] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [purpose, setPurpose] = useState<any>(purposeOptions[1]);
  const [showBioPanel, setShowBioPanel] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { id, uuid } = useParams<{ id: string; uuid: string }>();
  const parsedId = parseInt(id, 10);
  const { data: propertyById } = useSelectPropertyByIdQuery(parsedId, {
    skip: !id,
  });
  const { data: propertyByUUID } = useGetPropertyByUUIDQuery(uuid, {
    skip: !uuid,
  });
  const { data: properties, isSuccess: propertiesLoaded, isLoading: propertiesLoading } = useGetOwnerPropertiesQuery(
    undefined,
    {
      skip: pathname !== "/dashboard",
    }
  );
  const { data: user } = useGetUserIfLoggedInQuery(undefined);

  const property = propertyById || propertyByUUID;

  const { sponsorLoading } = useContext(ReferralContext);

  const [selectedProperty, setSelectedProperty] = useState<OwnerPropertyModel>();

  const { closeSponsorPanel } = useContext(UiContext);

  const bankrateUrl =
    "https://mortgage.bankrate.com/?svy_inst_uid=22964&br_mi=999&app=bankrate&pid=movoto_digs_email&rebrand=true&utm_source=ojo_digs&utm_content=refi&utm_medium=contact-panel&utm_cta=refi-my-mortgage";

  /* Track sponsor form opened */
  useEffect(() => {
    if (showMessagePanel && sponsor) {
      Analytics.trackEvent(new Analytics.Events.SponsorPanel.FormOpenedEvent(sponsor.id));
    }
  }, [showMessagePanel, sponsor]);

  /* Automatically close on route change */
  useEffect(() => {
    const unlisten = history.listen(() => {
      closeSponsorPanel();
    });
    return () => {
      unlisten();
    };
  });

  const closePanelHandler = () => {
    closeSponsorPanel();
    resetPanel();
  };

  // If on a property page, set that as the selected property
  useEffect(() => {
    if (property) {
      setSelectedProperty(property);
    }
  }, [property]);

  // If a user only has one property set, set that as the selected property and skip the property select panel
  useEffect(() => {
    if (propertiesLoaded && properties?.length === 1 && !selectedProperty) {
      setSelectedProperty(properties[0]);
    }
  }, [properties]);

  useEffect(() => {
    if (selectedProperty && selectedProperty.uuid) formik.setFieldValue("property_uuid", selectedProperty.uuid);
  }, [selectedProperty]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      purpose: purpose.value,
      firstname: user?.firstname ? user.firstname : "",
      lastname: user?.lastname ? user.lastname : "",
      email: user?.email ? user.email : "",
      mobile: user?.mobile ? user.mobile.toString() : "",
      message: "",
      provider_id: sponsor ? sponsor.id : "",
      property_uuid: selectedProperty ? selectedProperty.uuid : "",
    },
    validateOnMount: true,
    validationSchema: MessageSchema,
    onSubmit: (values) => {
      sendMessage({ ...values })
        .unwrap()
        .then(() => {
          setSuccess(true);
          if (purpose === "sell") {
            const lead: LeadModel = {
              ...values,
            };
            if (sponsor) {
              Analytics.trackEvent(new Analytics.Events.SponsorPanel.RequestAgentSubmitted(sponsor.id, lead));
            } else {
              Analytics.trackEvent(new Analytics.Events.Property.RequestAgentSubmitted(lead));
            }
          } else {
            Analytics.trackEvent(
              new Analytics.Events.SponsorPanel.FormSubmittedEvent(sponsor ? sponsor.id : undefined, purpose.value)
            );
          }
          formik.resetForm();
        })
        .catch(() => {
          createNotification("danger", "Whoops!", "Your message could not be sent at this time. Please try again.");
        });
    },
  });

  const openForm = (purposeIndex: number) => {
    setPurpose(purposeOptions[purposeIndex]);
    setShowMessagePanel(true);
  };

  const trackRefiLink = () => {
    if (selectedProperty) {
      Analytics.trackEvent(new Analytics.Events.SponsorPanel.RefinanceCTAClicked(sponsor ? sponsor.id : undefined));
      window.open(`${bankrateUrl}&zipcode=${selectedProperty.zip_code}`, "_blank");
    }
  };

  const resetPanel = () => {
    setShowMessagePanel(false);
    setSuccess(false);
    if (properties && properties.length > 1) {
      setSelectedProperty(undefined);
    }
  };

  const toggleBioPanel = () => {
    setShowBioPanel(!showBioPanel);
  };

  const selectProperty = (property: OwnerPropertyModel) => {
    setSelectedProperty(property);
  };

  const optionsPanel = useMemo(() => {
    return (
      <>
        <div
          className={`sponsor-panel__content-inner sponsor-panel__options-panel ${
            showMessagePanel ? "message-panel-open" : "message-panel-closed"
          }`}
        >
          <div className="sponsor-panel__top">
            <div className="sponsor-panel__top-avatar">
              {sponsor ? (
                <>
                  <img
                    className="sponsor-panel__top-avatar-bg"
                    src="/assets/images/global/icons/avatar-bg.svg"
                    alt="avatar background"
                  />
                  <SponsorAvatar sponsor={sponsor} size="xsmall" showDot={false} />
                </>
              ) : (
                <img src="/assets/images/global/icons/providerless-chat.svg" alt="chat bubble" />
              )}
            </div>
            {!selectedProperty && properties && properties.length > 1 ? (
              <div className="sponsor-panel__top-message">
                <h2>Which property are you inquiring about?</h2>
              </div>
            ) : (
              <div className="sponsor-panel__top-message">
                <h2>What do you need help with? </h2>
                <p>Ask me anything! Our team of experts usually responds within 24 hours or less.</p>
              </div>
            )}
          </div>
          <div className="sponsor-panel__options-panel__options">
            {!selectedProperty && properties && properties.length > 1 ? (
              <>
                {properties.map((item) => {
                  return <ListItem key={item.id} label={item.street_address} onClick={() => selectProperty(item)} />;
                })}
              </>
            ) : (
              <>
                {selectedProperty && (
                  <>
                    <ListItem leftIcon="home-money" label={"Sell My Home"} onClick={() => openForm(0)} />
                    <ListItem leftIcon="percent" label={"Refinance My Mortgage"} onClick={trackRefiLink} />
                  </>
                )}
                <ListItem leftIcon="question-circle-o" label={"General Question"} onClick={() => openForm(1)} />
                <ListItem leftIcon="gear" label={"Technical Support"} onClick={() => openForm(2)} />
              </>
            )}
          </div>
        </div>
        {sponsor && (
          <div className={`sponsor-panel__sticky ${showBioPanel ? "open" : ""}`}>
            <div className="sponsor-panel__sticky--header">
              <div className="sponsor-panel__sticky__avatar">
                <SponsorAvatar sponsor={sponsor} size="medium" />
                <div className="sponsor-panel__sticky__avatar__name">
                  <h4>
                    {sponsor.firstname} {sponsor.lastname}
                  </h4>
                  <sub>{sponsor.company_name}</sub>
                </div>
              </div>
              <div className="sponsor-panel__sticky__controls">
                <TrackableLink
                  href={`tel:${sponsor.mobile}`}
                  event={new Analytics.Events.SponsorPanel.ContactClickedEvent(sponsor.id, "phone")}
                >
                  <i className="digs-icon-phone" />
                </TrackableLink>
                <TrackableLink
                  href={`sms:+${sponsor.mobile}`}
                  event={new Analytics.Events.SponsorPanel.ContactClickedEvent(sponsor.id, "message")}
                >
                  <i className="digs-icon-chat" />
                </TrackableLink>
                <button className="digs-icon-button" onClick={toggleBioPanel}>
                  {showBioPanel ? <i className="digs-icon-down" /> : <i className="digs-icon-up" />}
                </button>
              </div>
            </div>
            <div className="sponsor-panel__sticky__content">
              <div className="about">
                <h3>About {sponsor.firstname}</h3>
                <p>{sponsor.short_bio}</p>
              </div>
              <div className="bottom">
                <i className="digs-icon-info" /> <b>Why is {sponsor.firstname} my agent?</b>
                <p>
                  {sponsor.firstname} is a real estate agent who can provide human guidance when you have questions. You
                  two are connected because you either met or worked together in the past.{" "}
                  <Link to="/settings/team/agent">See contact preferences here</Link>.
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }, [showBioPanel, selectedProperty, properties]);

  const messagePanel = useMemo(() => {
    return (
      <div
        className={`sponsor-panel__content-inner sponsor-panel__message-panel ${
          showMessagePanel ? "message-panel-open" : "message-panel-closed"
        }`}
      >
        {!success ? (
          <>
            <button className="digs-icon-button sponsor-panel__back-button" onClick={() => setShowMessagePanel(false)}>
              <i className="digs-icon-caret-left" />
            </button>
            <div className="sponsor-panel__top">
              <div className="sponsor-panel__top-avatar">
                {sponsor && purpose.value !== "technical" ? (
                  <>
                    <img
                      className="sponsor-panel__top-avatar-bg"
                      src="/assets/images/global/icons/avatar-bg.svg"
                      alt="avatar background"
                    />
                    <SponsorAvatar sponsor={sponsor} showDot={false} size="xsmall" />
                  </>
                ) : (
                  <img src={purpose.icon} alt={purpose.iconAltText} />
                )}
              </div>
              <div className="sponsor-panel__top-message">
                <h2>{purpose.label}</h2>
                <p>{purpose.copy}</p>
              </div>
            </div>
            <div className="sponsor-panel__form">
              {purpose.value === "sell" && (
                <>
                  <h4>Confirm your details</h4>
                  <p>{`${sponsor ? `Your agent, ${sponsor.firstname}, ` : "An agent "} will contact you shortly.`}</p>
                </>
              )}
              <form onSubmit={formik.handleSubmit}>
                <TextInputFormItem
                  errors={formik.errors.firstname}
                  touched={formik.touched.firstname}
                  name="firstname"
                  label="First Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                />
                <TextInputFormItem
                  errors={formik.errors.lastname}
                  touched={formik.touched.lastname}
                  name="lastname"
                  label="Last Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                />
                <TextInputFormItem
                  errors={formik.errors.email}
                  touched={formik.touched.email}
                  name="email"
                  label="Email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <PhoneInputFormItem
                  errors={formik.errors.mobile}
                  touched={formik.touched.mobile}
                  name="mobile"
                  size="small"
                  label="Phone"
                  showPlaceholder={false}
                  onChange={(e) => e && formik.setFieldValue("mobile", e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
                {purpose.value !== "sell" && (
                  <FormItem errors={formik.errors.message} touched={formik.touched.message}>
                    <textarea
                      placeholder="Type your message..."
                      name="message"
                      rows={8}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                    />
                  </FormItem>
                )}
                <Button loading={loading} fullWidth={true}>
                  Submit
                </Button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className="sponsor-panel__top sponsor-panel__success">
              <div className="sponsor-panel__top-avatar">
                <i className="digs-icon-checkmark" />
              </div>
              <div className="sponsor-panel__top-message">
                <h2>Success!</h2>
                <p>Your request has been submitted.</p>
                <p>
                  <b>Someone will be in touch shortly.</b>
                </p>
              </div>
              <Button onClick={resetPanel}>Ask another question</Button>
            </div>
          </>
        )}
      </div>
    );
  }, [showMessagePanel, purpose, formik, success]);

  return (
    <Panel className="sponsor-panel" visible={visible} onRequestClose={closePanelHandler}>
      {!propertiesLoading && !sponsorLoading && (
        <div className={`sponsor-panel__content ${!sponsor ? "sponsor-panel__providerless" : ""}`}>
          {!showMessagePanel ? optionsPanel : messagePanel}
        </div>
      )}
    </Panel>
  );
};

export default SponsorPanel;
