import * as yup from "yup";
import { StringSchema } from "yup";
import YupPassword from "yup-password";

declare module "yup" {
  interface StringSchema {
    minRepeatingNumbers(length: number, message?: string): StringSchema;
  }
}

const isNullOrUndefined = function (value: string | number) {
  return value === null || value === undefined;
};

yup.addMethod<StringSchema>(yup.string, "minRepeatingNumbers", function (length = 2, message: string) {
  const msg = message || "${path} must not contain sequences of more than ${length} repeated numbers";
  return this.test({
    name: "minRepeatingNumbers",
    exclusive: true,
    message: msg,
    params: { length: length },
    test(value) {
      return isNullOrUndefined(value) || !new RegExp(`([0-9])\\1{${length},}`).test(value);
    },
  });
});

YupPassword(yup);

export default yup;
