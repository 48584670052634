import React, { ChangeEvent, FC, FocusEvent, useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.scss";

export interface DateInputProps {
  id?: string;
  type?: string;
  label?: string;
  name?: string;
  size?: "default" | "large";
  jost?: boolean;
  value: any;
  error?: boolean;
  touched?: boolean;
  autoComplete?: any;
  readOnly?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
}

const DateInput: FC<DateInputProps> = ({
  onBlur,
  onFocus,
  onChange,
  value,
  error = false,
  touched = false,
  name,
  size = "default",
  jost = false,
  label,
  readOnly = false,
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  const onBlurHandler = useCallback((e?: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }, []);

  const onFocusHandler = useCallback((e?: FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  }, []);

  const onChangeHandler = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  }, []);

  return (
    <div
      className={`date-input ${focused || value ? "date-input--active" : ""} ${
        error ? "date-input--errors" : ""
      } date-input--size-${size} ${jost ? "date-input--jost" : ""} ${readOnly ? "date-input--read-only" : ""}`}
    >
      <input
        {...props}
        value={value || ""}
        readOnly={readOnly}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        className="date-input__input"
        type="date"
        aria-invalid={error}
      />
      {label && <label htmlFor={name}>{label}</label>}
      <div className={`date-input__line ${focused ? "date-input__line--focused" : ""}`} />
    </div>
  );
};

export default DateInput;
