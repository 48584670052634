import React, { ChangeEvent, FC, FocusEvent } from "react";
import Toggle from "react-toggle";
import "./Switch.scss";

/*
    See usage here: http://aaronshaf.github.io/react-toggle/
*/

interface Switch {
  id?: string;
  name?: string;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onBlur?: (event?: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event?: ChangeEvent<HTMLInputElement> | undefined) => void;
}

const Switch: FC<Switch> = (props) => {
  return <Toggle {...props} icons={false} />;
};

export default Switch;
