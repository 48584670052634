import { SuspenseLoader } from "@components/global";
import React, { FC, lazy, Suspense, useEffect } from "react";
import { Redirect } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import SponsorCard from "./components/global/sponsorCard/SponsorCard";
/* Layouts */
import { ModalView, SingleColumnLayout, SplitLayout, ThreeColumnLayout } from "./components/layouts";
import Auth, { AUTH_TYPE_AUTHORIZED, AUTH_TYPE_GUEST } from "./components/utils/Auth";
import Analytics from "./helpers/analytics";
/* Pages */
const Agent = lazy(() => import("./pages/settings/team/agent/Agent"));
const BankingSettings = lazy(() => import("./pages/settings/banking/Banking"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword/ForgotPassword"));
const FundingSource = lazy(() => import("./pages/settings/banking/fundingSource/FundingSource"));
const Landing = lazy(() => import("./pages/landing"));
const Login = lazy(() => import("./pages/login/Login"));
const Logout = lazy(() => import("./pages/logout/Logout"));
const NewHomeOwner = lazy(() => import("./pages/addProduct/homeOwner/HomeOwner"));
const NotificationsSettings = lazy(() => import("./pages/settings/notifications/Notifications"));
const PropertyHomeValue = lazy(() => import("./pages/properties/homeValue/HomeValue"));
const PropertyMaintenanceById = lazy(() => import("./pages/properties/maintenance/MaintenanceById"));
const PropertyMaintenanceByUUID = lazy(() => import("./pages/properties/maintenance/MaintenanceByUUID"));
const PropertyMortgage = lazy(() => import("./pages/properties/mortgage/Mortgage"));
const UserAvm = lazy(() => import("./pages/properties/userAvm/UserAvm"));
const PageNotFound = lazy(() => import("./pages/pageNotFound/PageNotFound"));
const ProfileSettings = lazy(() => import("./pages/settings/profile/Profile"));
const R = lazy(() => import("./pages/r/R"));
const Register = lazy(() => import("./pages/register/Register"));
const ResetPassword = lazy(() => import("./pages/resetPassword/ResetPassword"));
const SavingsFunds = lazy(() => import("./pages/savingsFunds/SavingsFunds"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const SetupTransfer = lazy(() => import("./pages/savingsFunds/transfer/Transfer"));
const Signup = lazy(() => import("./pages/signup/Signup"));
const Team = lazy(() => import("./pages/settings/team/Team"));
const ThumbtackResults = lazy(() => import("./pages/thumbtack/ThumbtackResults"));
const Unsubscribe = lazy(() => import("./pages/unsubscribe/Unsubscribe"));
const VerifyMobile = lazy(() => import("./pages/verifyMobile/VerifyMobile"));
const PropertyByUUID = lazy(() => import("./pages/owner/ByUUID"));
const PropertyById = lazy(() => import("./pages/properties/ById"));
const Withdraw = lazy(() => import("./pages/withdraw/Withdraw"));
const SellerLandingPage = lazy(() => import("./pages/sell/SellerLandingPage"));
const AddCoowner = lazy(() => import("./pages/addCoowner/AddCoowner"));
const Statements = lazy(() => import("./pages/settings/banking/statements/Statements"));
const Partners = lazy(() => import("./pages/partners/Partners"));

const Routes: FC = () => {
  const location = useLocation();

  useEffect(() => {
    Analytics.trackPageview(location);
  }, [location]);

  const routes = [
    {
      Layout: ModalView,
      components: {
        LeftSidebar: undefined,
        SponsorCard,
      },
      paths: [
        {
          path: "/add-product/homeowner",
          exact: true,
          Component: Auth(NewHomeOwner, AUTH_TYPE_AUTHORIZED),
          // onClose: () => history.push("/dashboard")
        },
        {
          path: "/savings-funds/:id/transfer",
          exact: true,
          Component: Auth(SetupTransfer, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/properties/:id/user-valuation",
          exact: true,
          Component: Auth(UserAvm, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/properties/:id/add-coowner",
          exact: true,
          Component: Auth(AddCoowner, AUTH_TYPE_AUTHORIZED),
        },
      ],
    },
    {
      Layout: ThreeColumnLayout,
      components: {
        LeftSidebar: undefined,
        SponsorCard,
      },
      paths: [
        {
          exact: true,
          path: "/logout",
          // minimal: true,
          Component: Auth(Logout, AUTH_TYPE_AUTHORIZED),
        },
        {
          exact: true,
          path: "/dashboard",
          // minimal: true,
          Component: Auth(Dashboard, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/properties/:id",
          exact: true,
          Component: Auth(PropertyById, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/properties/:id/mortgage",
          exact: true,
          Component: Auth(PropertyMortgage, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/properties/:id/home-value",
          exact: true,
          Component: Auth(PropertyHomeValue, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/properties/:id/maintenance",
          exact: true,
          Component: Auth(PropertyMaintenanceById, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/savings-funds/maintenance",
          exact: true,
          Component: Auth(SavingsFunds, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/savings-funds/:id",
          exact: true,
          Component: Auth(SavingsFunds, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/withdraw",
          exact: true,
          Component: Auth(Withdraw, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings",
          exact: true,
          Component: Auth(Settings, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/profile",
          exact: true,
          Component: Auth(ProfileSettings, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/notifications",
          exact: true,
          Component: Auth(NotificationsSettings, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/banking",
          exact: true,
          Component: Auth(BankingSettings, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/banking/funding-source",
          exact: true,
          Component: Auth(FundingSource, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/banking/statements",
          exact: true,
          Component: Auth(Statements, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/team",
          exact: true,
          Component: Auth(Team, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/settings/team/agent",
          exact: true,
          Component: Auth(Agent, AUTH_TYPE_AUTHORIZED),
        },
        {
          path: "/owner/:uuid/maintenance",
          exact: true,
          Component: PropertyMaintenanceByUUID,
        },
        {
          path: "/owner/:uuid",
          Component: PropertyByUUID,
        },
        {
          path: "/home-services",
          Component: ThumbtackResults,
        },
        {
          path: "/partners",
          Component: Partners,
        },
      ],
    },
    {
      Layout: SingleColumnLayout,
      paths: [
        {
          path: "/sell",
          Component: SellerLandingPage,
        },
      ],
    },
  ];

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Switch>
        {/* routes by layout */}
        {routes.map(({ paths, Layout, components }, i) => (
          <Route path={(paths as any).map((p: any) => p.path)} key={`route-${i}`}>
            <Layout paths={paths} components={components || {}} />
          </Route>
        ))}

        {/* Unauthenticated */}
        <SplitLayout exact showBranding={false} path="/" component={Auth(Login, AUTH_TYPE_GUEST)} />
        <SplitLayout exact path="/unsubscribe" component={Unsubscribe} />
        <SplitLayout exact path="/signup" component={Auth(Signup, AUTH_TYPE_GUEST)} />
        <SplitLayout exact path="/register" component={Auth(Register, AUTH_TYPE_GUEST, [], false)} />
        <SplitLayout
          exact
          showBranding={false}
          path="/forgot-password"
          component={Auth(ForgotPassword, AUTH_TYPE_GUEST)}
        />
        <SplitLayout
          exact
          showBranding={false}
          path="/reset-password/:token"
          component={Auth(ResetPassword, AUTH_TYPE_GUEST)}
        />
        <SplitLayout exact path="/verify-mobile" component={Auth(VerifyMobile, AUTH_TYPE_AUTHORIZED)} />
        <Route path="/landing/:id" component={Auth(Landing, AUTH_TYPE_GUEST)} />
        {/* Home Value */}
        <Redirect
          exact={false}
          path="/home-value"
          to={{
            pathname: "/signup",
            search: location.search,
          }}
        />
        <Redirect
          exact={true}
          path="/add-product"
          to={{
            pathname: "/add-product/homeowner",
            search: location.search,
          }}
        />
        <Redirect
          exact={true}
          path="/add-product/savings-fund"
          to={{
            pathname: "/add-product/homeowner",
            search: location.search,
          }}
        />
        {/* No Auth Validation (Everyone) */}
        <SplitLayout path="/r/:referralCode/:secondaryCode?" component={R} />
        <SplitLayout showBranding={false} component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
