import Button, { IButton } from "@components/global/form/button/Button";
import React, { FC, ReactNode } from "react";
import Modal, { ModalPropsInterface } from "../alt-modal/Modal";
import "./ConfirmationModal.scoped.scss";

export interface ConfirmationModalPropsInterface extends ModalPropsInterface {
  onConfirm: () => void;
  onCancel?: () => void;
  loading?: boolean;
  confirmDisabled?: boolean;
  title?: string;
  confirmText?: ReactNode | string;
  cancelText?: ReactNode | string;
  reverseButtons?: boolean;
  confirmTheme?: IButton["theme"];
  confirmButtonOnly?: boolean;
  hideButtons?: boolean;
}

const ConfirmationModal: FC<ConfirmationModalPropsInterface> = ({
  onConfirm,
  onRequestClose = () => null,
  onCancel = null,
  loading = false,
  confirmDisabled = false,
  title = undefined,
  confirmText = "Confirm",
  cancelText = "Cancel",
  reverseButtons = false,
  children,
  ariaLabel,
  confirmTheme = "primary",
  confirmButtonOnly = false,
  hideButtons = false,
  ...props
}) => {
  return (
    <Modal
      className="confirmation-modal"
      theme="center"
      onRequestClose={onRequestClose}
      ariaLabel={ariaLabel || title}
      {...props}
    >
      {title ? <h4>{title}</h4> : null}
      {children ? <>{children}</> : null}
      {!hideButtons && (
        <div className={`confirmation-modal__actions ${reverseButtons ? "confirmation-modal__actions--reversed" : ""}`}>
          {!confirmButtonOnly && (
            <Button
              theme="light"
              scale="small"
              className="confirmation-modal__cancel"
              onClick={onCancel || onRequestClose}
              fullWidth={true}
            >
              {cancelText}
            </Button>
          )}
          <Button
            disabled={confirmDisabled}
            loading={loading}
            onClick={onConfirm}
            theme={confirmTheme || "primary"}
            scale="small"
            className="confirmation-modal__confirm"
            fullWidth={true}
          >
            {confirmText}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
