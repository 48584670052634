import { SuspenseLoader } from "@components/global";
import React, { FC, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import LayoutProps from "../LayoutProps";
import "./ModalView.scoped.scss";

const ModalView: FC<LayoutProps> = ({ paths, ...props }) => {
  return (
    <div className="modal-view-layout">
      <Suspense fallback={<SuspenseLoader />}>
        <Switch>
          {paths.map(({ path, exact, Component }, i) => (
            <Route {...props} path={path} component={Component} exact={exact} key={`route-${i}`} />
          ))}
        </Switch>
      </Suspense>
    </div>
  );
};

export default ModalView;
