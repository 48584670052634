import { baseApi } from "@store/services/base";

export type PlaidTokenModel = {
  expiration: string;
  link_token: string;
  request_id: string;
};

const transformSingleResponse = ({ data }: { data: PlaidTokenModel }) => data;

export const plaidApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    generateToken: builder.mutation({
      query: (isFundingSource: boolean = false) => ({
        url: "/consumer/v1/accounts/token",
        method: "POST",
        body: { funding_source: isFundingSource },
      }),
      transformResponse: transformSingleResponse,
      invalidatesTags: ["Plaid"],
    }),
  }),
});

export const { useGenerateTokenMutation } = plaidApi;
