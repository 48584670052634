import { sponsorStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import useSearchParams, { SearchParamTypes } from "@helpers/useSearchParams";
import { SponsorModel } from "@store/models/sponsor";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetSponsorByUUIDQuery, useGetSponsorQuery, useLookupSponsorQuery } from "@store/services/sponsor";
import React, { createContext, FC, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

export interface ReferralContextInterface {
  sponsor: SponsorModel | null;
  displaySponsor: SponsorModel | null;
  sponsorLoading: boolean;
  setSponsorReferralCode: (code: string) => void;
}

const ReferralContext = createContext<ReferralContextInterface>({
  sponsor: null,
  displaySponsor: null,
  sponsorLoading: false,
  setSponsorReferralCode: () => {},
});

export const ReferralProvider: FC = (props) => {
  const [storedReferralCode, setSponsorReferralCode] = useState<string | null>(null);
  const ownerPageMatch = useRouteMatch<{ uuid?: string }>("/owner/:uuid");
  const isLoggedIn = useIsLoggedIn();
  const { r } = useSearchParams<SearchParamTypes>();
  const referralCodes = r && r.split("/");
  const referralCode =
    referralCodes && referralCodes.length > 1
      ? { primary: referralCodes[0], secondary: referralCodes[1] }
      : { primary: r || storedReferralCode };

  const [sponsorInLocalStorage, setSponsorInLocalStorage] = useLocalStorage(sponsorStorageKey, null);

  // checks if the referral code is the same as the sponsor already in storage
  const hasReferredSponsor = sponsorInLocalStorage && sponsorInLocalStorage.referral_code === r;

  // if there is no referral code, or the user is logged in, skip this request
  // sponsor will be fulfilled by the next request in the flow
  const { data: tempSponsorLookup } = useLookupSponsorQuery(referralCode, {
    skip: !referralCode.primary || hasReferredSponsor || isLoggedIn,
  });

  const { data: sponsorLookup, isLoading: sponsorLoading } = useGetSponsorQuery(undefined, {
    skip: !isLoggedIn,
  });

  // if visiting a property page unauthenticated, and the property has an associated sponsor
  const { data: sponsorByUUID } = useGetSponsorByUUIDQuery(ownerPageMatch && ownerPageMatch.params.uuid, {
    skip: !ownerPageMatch || isLoggedIn,
  });

  useEffect(() => {
    if (sponsorByUUID) {
      setSponsorInLocalStorage(sponsorByUUID);
    } else if (tempSponsorLookup) {
      setSponsorInLocalStorage(tempSponsorLookup);
    }
  }, [r, sponsorByUUID, tempSponsorLookup]);

  const tempSponsor = sponsorInLocalStorage || tempSponsorLookup;
  const sponsor = isLoggedIn ? sponsorLookup : tempSponsor;
  const displaySponsor = sponsor && !sponsor.is_discreet ? sponsor : null;

  return (
    <ReferralContext.Provider value={{ sponsor, displaySponsor, setSponsorReferralCode, sponsorLoading }}>
      {props.children}
    </ReferralContext.Provider>
  );
};

export default ReferralContext;
