import DigsHelper from "@helpers/DigsHelper";
import fill from "lodash/fill";
import React, { FC } from "react";
import NumberInput, { INumberInput } from "../numberInput/NumberInput";

const NON_DIGITS = /[^0-9.]*/g;
const DECIMAL_PLACES = 2;

export interface ICurrencyInput extends Omit<INumberInput, "onChange" | "value"> {
  large?: boolean;
  value?: number | null;
  fixedDecimal?: boolean;
  max?: number;
  onChange?: (value: number | undefined) => void;
}

const CurrencyInput: FC<ICurrencyInput> = ({
  value,
  large = false,
  fixedDecimal = false,
  className,
  max,
  ...props
}) => {
  const decimalPlaces = fixedDecimal ? DECIMAL_PLACES : 0;

  const parse = (value: string) => {
    let newValue = value;
    if (fixedDecimal) {
      //shift digits to the left as the user types
      newValue = newValue.replace(/[^0-9]/g, "");
      if (newValue.length < decimalPlaces + 1) {
        newValue = `${fill(new Array(decimalPlaces + 1 - newValue.length), "0").join("")}${newValue}`;
      }
      newValue = `${newValue.substr(0, newValue.length - decimalPlaces)}.${newValue.substr(-decimalPlaces)}`;
    }

    let parsedValue = parseFloat(newValue.replace(NON_DIGITS, ""));
    if (!Number.isFinite(parsedValue) || Number.isNaN(parsedValue)) {
      parsedValue = 0;
    }
    return parsedValue;
  };

  const format = (value: number) => {
    return DigsHelper.formatMoney(value, decimalPlaces);
  };

  const formatCursorPosition = (value: string, cursorPosition: number, formattedValue: string) => {
    if (fixedDecimal) {
      return formattedValue.length;
    }
    return DigsHelper.cursorPositionAfterMoneyFormat(value, cursorPosition, formattedValue);
  };

  return (
    <NumberInput
      {...props}
      inputMode="numeric"
      value={value}
      formatCursorPosition={formatCursorPosition}
      format={format}
      parse={parse}
      max={max}
      blankZeroValue={false}
      className={`currency-input currency-input__${large ? "large" : "default"} ${
        value ? "" : "currency-input__empty"
      }`}
    />
  );
};

export default CurrencyInput;
