import { emerald } from "@helpers/Constants";
import React, { FC } from "react";

interface Loader {
  size: number;
}

const Loader: FC<Loader> = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 44C35.4934 44 44 35.4934 44 25C44 14.5066 35.4934 6 25 6C14.5066 6 6 14.5066 6 25C6 35.4934 14.5066 44 25 44ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="#EEEEEE"
      />
      <g>
        <path
          d="M25 47C25 48.6569 26.3494 50.0189 27.9946 49.8225C40.3899 48.3429 50 37.7937 50 25C50 12.2063 40.3899 1.65716 27.9946 0.17752C26.3494 -0.0188666 25 1.34316 25 3.00002C25 4.65687 26.3519 5.97514 27.9885 6.2337C37.0621 7.66721 44 15.5234 44 25C44 34.4766 37.0621 42.3328 27.9885 43.7663C26.3519 44.0249 25 45.3432 25 47Z"
          fill="url(#streak)"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </g>
      <defs>
        <linearGradient id="streak" x1="32" y1="50" x2="9" y2="16.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={emerald} />
          <stop offset="1" stopColor={emerald} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Loader;
