import React, { FC } from "react";
import "./RadioList.scoped.scss";

export interface IRadioList {}

const RadioList: FC<IRadioList> = ({ children }) => {
  return <div className="radio-list">{children}</div>;
};

export default RadioList;
