import React, { FC } from "react";
import Analytics from "../../../helpers/analytics";
import { Card } from "../index";
import TrackableLink from "../TrackableLink";
import "./SupportCard.scoped.scss";

const Body = Card.Body;

const SupportCard: FC = (props) => {
  return (
    <>
      <h3 className="d-md-none">Need Help?</h3>
      <Card className="support-card">
        <Body className="support-card--inner">
          <img src="/assets/images/global/support-card/chat-icon.svg" alt="Need Help Icon" />
          <h3>Need Help?</h3>
          <p>Our support team is available to answer any questions you might have.</p>
          <TrackableLink
            href="https://digs.co/contact/"
            event={new Analytics.Events.Support.ContactSupportClickedEvent()}
            className="support-card--button"
            rel={"noopener noreferrer"}
            target={"_blank"}
          >
            Contact Support
          </TrackableLink>
        </Body>
      </Card>
    </>
  );
};

export default SupportCard;
