import Config from "./config";

const prodConfig: Config = {
  ENVIRONMENT: "production",
  REACT_APP_API_URL: "https://api.homeowner.ojo.com/",
  REACT_APP_PLAID_ENV: "production",
  REACT_APP_PLAID_PUBLIC_KEY: "3f05931eff7b3b1b8b8c8911e7df41",
  REACT_APP_PLAID_WEBHOOK_URL: "https://api.homeowner.ojo.com/api/plaid/webhook",
  REACT_APP_GOOGLE_TAG_MANAGER_ID: "GTM-PCTH4RP",
  REACT_APP_GOOGLE_TAG_MANAGER_AUTH: "7b7Xrr7JkoRi41Ht5Zgg-w",
  REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW: "env-2",
  REACT_APP_SENTRY_ENVIRONMENT: "production",
  REACT_APP_SMARTY_STREETS_EMBEDDED_KEY: "31985543173639929",
  REACT_APP_SATISMETER_WRITE_KEY: "UhflZu2n6KTMYD0r",
  SPLIT_IO_AUTH_KEY: "mb4s4o7nuif7ns1ldochvk5isoqjn51tn8ie",
};

export default prodConfig;
