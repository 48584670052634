import { configureStore } from "@reduxjs/toolkit";
import { baseApi } from "./services/base";

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});

export default store;

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
