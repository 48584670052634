import { Button, Feedback, TextInputFormItem } from "@components/global";
import Modal, { ModalPropsInterface } from "@components/global/alt-modal/Modal";
import { UiContext } from "@components/utils/contexts";
import Analytics from "@helpers/analytics";
import { getQueryError } from "@helpers/Errors";
import Yup from "@helpers/Yup";
import { useLogin } from "@store/services/auth";
import { useFormik } from "formik";
import React, { FC, MouseEvent, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./LoginModal.scoped.scss";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email address required"),
  password: Yup.string().required("Password required"),
});

interface LoginModal extends ModalPropsInterface {}

const LoginModal: FC<LoginModal> = ({ onRequestClose, onModalShown, onModalDismissed, ...props }) => {
  const history = useHistory();
  const [login, { isLoading: loading, isError: loginErrored, error: loginError }] = useLogin();
  const { hideLoginModal, setUiStateAttributes, uiState } = useContext(UiContext);
  const { registration_modal_props: registrationProps } = uiState;

  useEffect(() => {
    const unlisten = history.listen(() => {
      hideLoginModal();
    });
    return () => {
      unlisten();
    };
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      login(values)
        .unwrap()
        .then(() => {
          Analytics.trackEvent(new Analytics.Events.PublicProperty.UserLoggedInEvent());
          onRequestClose && onRequestClose();
        });
    },
  });

  const handleModalShown = () => {
    Analytics.trackEvent(new Analytics.Events.PublicProperty.LoginModalShownEvent());
    onModalShown && onModalShown();
  };
  const handleModalDismissed = () => {
    Analytics.trackEvent(new Analytics.Events.PublicProperty.LoginModalDismissedEvent());
    onModalDismissed && onModalDismissed();
  };

  const onCreateAccountClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setUiStateAttributes({ login_modal: false, registration_modal: true, registration_modal_props: registrationProps });
  };

  return (
    <Modal
      className="login-modal"
      maxWidth="43rem"
      onRequestClose={onRequestClose}
      onModalDismissed={handleModalDismissed}
      onModalShown={handleModalShown}
      {...props}
    >
      <img
        className="digs-primary"
        src="/assets/images/global/logos/movoto-by-ojo-logo.svg"
        alt={"Movoto by OJO Logo"}
      />
      <h1>Login</h1>
      {loginErrored && <Feedback type="error" content={getQueryError(loginError)} />}
      <form onSubmit={formik.handleSubmit}>
        <TextInputFormItem
          errors={formik.errors.email}
          touched={!!formik.touched.email}
          name="email"
          label="Email Address"
          type="email"
          autoComplete="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        <TextInputFormItem
          errors={formik.errors.password}
          touched={!!formik.touched.password}
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        <Button loading={loading} disabled={!(formik.isValid && formik.dirty)} fullWidth={true}>
          Log In
        </Button>
        <div className="login-modal--actions">
          <Link to="/forgot-password" className="forgot-password">
            Forgot Password?
          </Link>
        </div>
      </form>
    </Modal>
  );
};

export default LoginModal;
