import React, { FC, useLayoutEffect, useRef, useState } from "react";
import NavItem, { NavItemProps } from "../navItem/NavItem";

export interface NavItemGroupProps extends NavItemProps {
  expanded?: boolean;
  expandable?: boolean;
  items?: NavItemProps[];
  onToggleExpanded?: () => void;
}
const NavItemGroup: FC<NavItemGroupProps> = ({
  className = "",
  expanded = true,
  expandable = false,
  onToggleExpanded,
  children,
  ...props
}) => {
  const [childrenHeight, setChildrenHeight] = useState(null);
  const childrenRef = useRef(null);
  let childrenMaxHeight = "";

  if (childrenHeight) {
    childrenMaxHeight = expanded ? `${childrenHeight}px` : "0";
  }

  useLayoutEffect(() => {
    if (childrenRef && childrenRef.current) {
      //@ts-ignore
      setChildrenHeight(childrenRef.current.scrollHeight);
    }
  }, [childrenRef, expanded]);

  return (
    <ul
      className={`navigation__item-group ${className} ${
        expanded ? "navigation__item-group--expanded" : "navigation__item-group--collapsed"
      }`}
    >
      <NavItem
        parent={true}
        expanded={expanded}
        onToggleExpanded={expandable ? onToggleExpanded : undefined}
        {...props}
      />
      <div style={{ maxHeight: childrenMaxHeight }} className="navigation__item-group__children" ref={childrenRef}>
        {children}
      </div>
    </ul>
  );
};

export default NavItemGroup;
