import Nav from "@components/global/nav/Nav";
import { NavItemGroupProps } from "@components/global/navItemGroup/NavItemGroup";
import { useIsLoggedIn } from "@store/services/auth";
import React, { FC } from "react";
import "./DesktopNavigation.scss";

interface DesktopNavigation {
  items: NavItemGroupProps[];
}

const DesktopNavigation: FC<DesktopNavigation> = ({ items }) => {
  const isLoggedIn = useIsLoggedIn();

  const secondaryNavItems = [
    {
      name: "Settings",
      to: "/settings",
      icon: "gear",
    },
  ];

  return (
    <div className="navigation-desktop">
      <Nav items={items} />
      {isLoggedIn ? <Nav items={secondaryNavItems} className="navigation-secondary" /> : null}
    </div>
  );
};

export default DesktopNavigation;
