import config from "@config";
import { AuthTokenData, clearAuthTokenData, getAuthTokenData, setAuthTokenData } from "@helpers/auth";
import { FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

let pendingAuthTokenData: MaybePromise<
  QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
> | null = null;

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.REACT_APP_API_URL || "http://digs:8888"}`,
  prepareHeaders: (headers) => {
    const tokenData = getAuthTokenData();
    // If we have a token set in state, let's assume that we should be passing it.
    if (tokenData) {
      headers.set("authorization", `${tokenData.token_type} ${tokenData.access_token}`);
    }
    headers.set("Accept", "application/json");
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const currentTokenData = getAuthTokenData();

    if (!pendingAuthTokenData) {
      pendingAuthTokenData = baseQuery(
        {
          url: "/consumer/v1/refresh-token",
          method: "POST",
          body: {
            refresh_token: currentTokenData?.refresh_token,
          },
        },
        api,
        {}
      );

      const { data: newAuthTokenData } = await pendingAuthTokenData;

      if (newAuthTokenData) {
        // Store the new token
        setAuthTokenData(newAuthTokenData as AuthTokenData);
      } else {
        // Clear auth token aka log user out
        clearAuthTokenData();
      }
    } else {
      await pendingAuthTokenData;
    }

    result = await baseQuery(args, api, extraOptions);
    pendingAuthTokenData = null;
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  tagTypes: [
    "Fund",
    "Property",
    "Transfer",
    "Transaction",
    "Sponsor",
    "Account",
    "User",
    "Auth",
    "Milestone",
    "Post",
    "Plaid",
    "Coowner",
    "ChecklistCounts",
    "Announcements",
    "Tasks",
    "LeadSources",
    "Statement",
    "Status",
    "RecentlySolds",
    "PropertyMetrics",
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
