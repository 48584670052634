import React, { FC, MouseEventHandler } from "react";
import "./DeleteButton.scss";

interface DeleteButtonProps {
  onClick?: MouseEventHandler<HTMLSpanElement>;
}

const DeleteButton: FC<DeleteButtonProps> = ({ children, onClick }) => {
  return (
    <div className="delete-button">
      <span className="delete-button__link" onClick={onClick} role="button">
        <i className="digs-icon-trash delete-button__icon" /> {children}
      </span>
    </div>
  );
};

export default DeleteButton;
