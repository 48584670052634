import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { createPortal } from "react-dom";
import { Card } from "../index";
import "./Modal.scoped.scss";

export interface ModalPropsInterface {
  children?: any;
  className?: string;
  closable?: boolean;
  visible?: boolean;
  onClose?: () => void;
  maxWidth?: string | number;
}

export interface ModalRefInterface {
  close(): void;
}

const Modal = forwardRef<ModalRefInterface, ModalPropsInterface>(
  ({ className, closable = true, visible = false, onClose, maxWidth, children }, ref) => {
    const [animating, setAnimating] = useState<boolean>(false);

    const close = useCallback(() => {
      if (!animating) {
        setAnimating(true);
        setTimeout(() => {
          setAnimating(false);
          if (onClose) {
            onClose();
          }
        }, 500);
      }
    }, [animating]);

    useEffect(() => {
      if (visible || animating) {
        document.body.classList.add("modal-locked");
      } else {
        document.body.classList.remove("modal-locked");
      }
      return () => {
        document.body.classList.remove("modal-locked");
      };
    }, [visible, animating]);

    useImperativeHandle(ref, () => ({ close }));

    const content = visible && (
      <div className={`modal ${visible && !animating ? "visible" : ""} ${className ? className : ""}`}>
        <div className="modal--mask" onClick={closable && !animating && onClose ? close : () => false} />
        <Card className="modal--card" style={{ maxWidth: maxWidth || undefined }}>
          <Card.Body className="modal--card-body">
            {closable && (
              <button onClick={close} className="modal--close">
                <i className="digs-icon-close"></i>
                <span className="sr-only">Close modal</span>
              </button>
            )}
            <div className="modal-body">{children}</div>
          </Card.Body>
        </Card>
      </div>
    );

    return createPortal(content, document.body);
  }
);

export default Modal;
