import { LoaderBlock, SponsorCard, SuspenseLoader } from "@components/global";
import { ReferralContext, UiContext } from "@components/utils/contexts";
import { breakpoints } from "@helpers/Constants";
import DigsHelper from "@helpers/DigsHelper";
import { useIsLoggedIn } from "@store/services/auth";
import { useGetAllHomeFundsQuery } from "@store/services/funds";
import { useGetAllPropertiesQuery } from "@store/services/properties";
import { useGetUserIfLoggedInQuery } from "@store/services/user";
import { includes } from "lodash";
import React, { FC, Suspense, useContext, useMemo } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import useWindowSize from "../../utils/useWindowSize";
import LayoutProps from "../LayoutProps";
import Dropdown from "../threeColumn/dropdown/Dropdown";
import Header from "../threeColumn/header/Header";
import LoginModal from "../threeColumn/loginModal/LoginModal";
import DesktopNavigation from "../threeColumn/navigation/desktopNavigation/DesktopNavigation";
import { useNavItems } from "../threeColumn/navItems";
import RegistrationModal from "../threeColumn/registrationModal/RegistrationModal";
import SponsorPanel from "../threeColumn/sponsorPanel/SponsorPanel";
import "./SingleColumnLayout.scoped.scss";

const SingleColumnLayout: FC<LayoutProps> = ({ components, paths, ...props }) => {
  const isLoggedIn = useIsLoggedIn();
  const { isSuccess: userLoaded } = useGetUserIfLoggedInQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { isSuccess: homeFundsLoaded } = useGetAllHomeFundsQuery(undefined, {
    skip: !isLoggedIn,
  });
  const { isSuccess: propertiesLoaded } = useGetAllPropertiesQuery(undefined, {
    skip: !isLoggedIn,
  });
  const loading = isLoggedIn && !homeFundsLoaded && !propertiesLoaded && !userLoaded;

  const { displaySponsor: sponsor } = useContext(ReferralContext);
  const { uiState, hideLoginModal, hideRegistrationModal } = useContext(UiContext);
  const { login_modal: loginModal, sponsor_panel: sponsorPanel, registration_modal: registrationModal } = uiState;
  const { width } = useWindowSize();
  const location = useLocation();

  const dropdownItems = useMemo(() => {
    if (isLoggedIn) {
      return [
        {
          title: "Account Settings",
          path: "/settings",
        },
        {
          title: "Support",
          href: "https://help.digs.co/en/",
        },
        {
          title: "Log Out",
          path: "/logout",
        },
      ];
    }
    return [
      {
        title: "Log In",
        path: "/",
      },
      {
        title: "Support",
        href: "https://help.digs.co/en/",
      },
    ];
  }, [isLoggedIn]);

  const navItems = useNavItems(!loading);

  const sponsorContent = (
    <>
      <SponsorCard sponsor={sponsor || undefined} />
      <SponsorPanel sponsor={sponsor || undefined} visible={sponsorPanel} />
    </>
  );

  const hideSponsorContent = useMemo(() => {
    const excludedPaths = ["/withdraw", "/sell"];
    paths.forEach(({ path }) => {
      if (DigsHelper.matchRule(path, "/settings*")) {
        excludedPaths.push(path);
      }
    });
    return includes(excludedPaths, location.pathname);
  }, [location.pathname, paths]);

  //TODO global component for logo link
  return (
    <div className="single-column-layout">
      <Header isLoggedIn={isLoggedIn} navItems={navItems} />
      <div className="single-column-layout__left">
        <div className="single-column-layout__left__inner">
          <Link to="/" className="single-column-layout__home single-column-layout__header">
            <i className="digs-icon-digs logo"></i>
            <span className="sr-only">Go to homepage</span>
          </Link>
          {width <= breakpoints.sm && <DesktopNavigation items={navItems} />}
          {width >= breakpoints.sm && !hideSponsorContent ? sponsorContent : null}
        </div>
      </div>
      <main className="single-column-layout__main">
        <Suspense fallback={<SuspenseLoader />}>
          {loading ? (
            <LoaderBlock />
          ) : (
            <Switch>
              {paths.map(({ path, exact, Component }, i) => (
                <Route {...props} path={path} component={Component} exact={exact} key={`route-${i}`} />
              ))}
            </Switch>
          )}
        </Suspense>
        {width < breakpoints.sm ? sponsorContent : null}
      </main>
      <div className="single-column-layout__right">
        <div className="single-column-layout__right__inner">
          <div className="single-column-layout__header">
            <Dropdown items={dropdownItems}>
              {width >= breakpoints.lg ? "My Account" : ""}
              <i className="digs-icon-menu" />
            </Dropdown>
          </div>
        </div>
      </div>
      {!isLoggedIn && (
        <>
          <LoginModal visible={loginModal} onRequestClose={() => hideLoginModal()} />
          <RegistrationModal visible={registrationModal} onRequestClose={() => hideRegistrationModal()} />
        </>
      )}
    </div>
  );
};

export default SingleColumnLayout;
