import { SponsorModel } from "@store/models/sponsor";
import React, { FC, MouseEventHandler } from "react";
import "./SponsorAvatar.scss";

interface SponsorAvatarProps {
  sponsor: SponsorModel;
  showDot?: boolean;
  showShadow?: boolean;
  size?: "xsmall" | "small" | "medium" | "large";
  onClick?: MouseEventHandler<HTMLDivElement>;
}
const SponsorAvatar: FC<SponsorAvatarProps> = ({
  sponsor,
  showDot = true,
  showShadow = true,
  size = "medium",
  onClick,
}) => {
  return (
    <div
      className={`sponsor-avatar ${showDot ? "" : "sponsor-avatar--no-dot"} sponsor-avatar--size-${size} ${
        showShadow ? "" : "sponsor-avatar--no-shadow"
      }`}
      onClick={onClick}
      role={onClick ? "button" : ""}
      aria-label={onClick ? `Contact your advisor, ${sponsor.firstname} ${sponsor.lastname}` : ""}
    >
      <img src={sponsor.avatar} alt={`${sponsor.firstname} ${sponsor.lastname} Avatar`} />
      <div className="sponsor-avatar__dot" />
    </div>
  );
};

export default SponsorAvatar;
