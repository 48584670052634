import React, { FC } from "react";
import "./CardLink.scoped.scss";

interface CardLink {
  path: string;
  label: string;
  className?: string;
  icon?: string;
}

const CardLink: FC<CardLink> = ({ label, className, icon, path }) => {
  return (
    <a href={path} className={`${className ? className : ""} card-link`}>
      <span className="card-link__label">
        {icon && <i className={`${icon} card-link__icon`} />}
        {label}
        <i className="digs-icon-caret-right" />
      </span>
    </a>
  );
};

export default CardLink;
