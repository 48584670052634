import React, { FC } from "react";
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";

export interface ILink extends LinkProps {
  className?: string;
}

const Link: FC<ILink> = ({ className, children, ...props }) => {
  const isExternalURL = props.to && props.to.includes("http");

  if (isExternalURL) {
    return (
      <a {...props} href={props.to} className={className || ""}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink {...props} className={className || ""}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;
