import { userStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import React, { createContext, FC, useEffect, useState } from "react";

type TempUserAttributes = {
  email?: string;
  firstname?: string;
  lastname?: string;
};

export interface UserContextInterface {
  temporaryUser: TempUserAttributes;
  setTemporaryUserAttributes: (tempAttributes: TempUserAttributes) => void;
  clearTemporaryUserAttributes: () => void;
}

const defaultTempUser = {
  email: "",
  firstname: "",
  lastname: "",
};

const UserContext = createContext<UserContextInterface>({
  temporaryUser: defaultTempUser,
  setTemporaryUserAttributes: () => {},
  clearTemporaryUserAttributes: () => {},
});

export const UserProvider: FC = (props) => {
  const [temporaryUserInLocalStorage, setTemporaryUserInLocalStorage] = useLocalStorage(userStorageKey, null);
  const [temporaryUser, setTemporaryUser] = useState<TempUserAttributes>(defaultTempUser);

  const setTemporaryUserAttributes = (tempAttributes: TempUserAttributes) => {
    setTemporaryUser({ ...temporaryUserInLocalStorage, ...tempAttributes });
  };

  const clearTemporaryUserAttributes = () => {
    setTemporaryUser(defaultTempUser);
    setTemporaryUserInLocalStorage(null);
  };

  useEffect(() => {
    setTemporaryUserInLocalStorage(temporaryUser);
  }, [temporaryUser]);

  return (
    <UserContext.Provider value={{ temporaryUser, setTemporaryUserAttributes, clearTemporaryUserAttributes }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
