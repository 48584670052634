import { NavItemGroupProps } from "@components/global/navItemGroup/NavItemGroup";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import MobileNavigation from "../navigation/mobileNavigation/MobileNavigation";
import "./Header.scoped.scss";

interface Header {
  isLoggedIn: boolean;
  navItems: NavItemGroupProps[];
  onClose?: () => void;
}

const Header: FC<Header> = ({ isLoggedIn, navItems, onClose, ...props }) => {
  return (
    <nav className={`header ${onClose ? "minimal" : ""}`}>
      <div className="header__inner">
        <div className="header__left three-column-layout__left">
          <Link to="/" className="header__link">
            <img src="/assets/images/global/logos/movoto-by-ojo-logo.svg" alt="Movoto by OJO logo" className="logo" />
            <img
              src="/assets/images/global/logos/movoto-by-ojo-logo-white.svg"
              alt="Movoto by OJO logo"
              className="logo mobile"
            />
            <span className="sr-only">Go to homepage</span>
          </Link>
        </div>
        <div className="three-column-layout__main"></div>
        {!onClose && (
          <div className="header__right d-md-none three-column-layout__right">
            <MobileNavigation isLoggedIn={isLoggedIn} items={navItems} />
          </div>
        )}
        {onClose && (
          <div className="header__right">
            <button onClick={onClose} className="header__close">
              <i className="digs-icon-close"></i>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
