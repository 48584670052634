import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./Faqs.scss";

export interface FaqsProps {
  title: ReactNode;
  className?: string;
}

const Faqs: FC<FaqsProps> = ({ title, children, className = "", ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);
  const contentMaxHeight = expanded ? contentHeight : 0;

  useEffect(() => {
    if (contentRef && contentRef.current) {
      //@ts-ignore
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [contentRef, expanded]);

  return (
    <div className={`faqs ${className}`}>
      <button
        type="button"
        className="faqs__title"
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded ? "true" : "false"}
      >
        <div className="faqs__title-inner">
          <i className="digs-icon-question-circle faqs__icon"></i> <span>{title}</span>
        </div>
        <i className={`digs-icon-${expanded ? "up" : "down"} faqs__caret`}></i>
      </button>
      <div
        className={`faqs__content faqs__content${expanded ? "--expanded" : "--collapsed"}`}
        aria-hidden={expanded ? "false" : "true"}
        style={{ maxHeight: `${contentMaxHeight}px` }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Faqs;
