import Analytics from "@helpers/analytics";
import { authTokenStorageKey, sponsorStorageKey } from "@helpers/Constants";
import useLocalStorage from "@helpers/LocalStorage";
import { AuthModel } from "@store/models/auth";
import { LeadSourceModel } from "@store/models/leadsource";
import { OwnerPropertyModel } from "@store/models/properties";
import { baseApi } from "@store/services/base";
import { useDispatch } from "react-redux";
import { useLazyGetUserQuery } from "./user";

export interface LoginCredentialsProps {
  email: string;
  password: string;
}

export interface RegistrationProps {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  password_confirmation: string;
  provider_id?: number;
  owner_property?: Pick<OwnerPropertyModel, "street_address" | "apt_or_unit" | "city" | "state" | "zip_code">;
  lead_source?: LeadSourceModel;
}

export interface ResetPasswordProps {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface UpdatePasswordProps {
  current_password: string;
  password: string;
  password_confirmation: string;
}

const transformSingleResponse = (data: AuthModel) => data;

const baseUrl = "consumer/v1";

export const authApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body: LoginCredentialsProps) => ({
        url: `${baseUrl}/login`,
        method: "POST",
        body,
      }),
      transformResponse: transformSingleResponse,
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${baseUrl}/logout`,
        method: "GET",
      }),
    }),
    register: builder.mutation({
      query: (body: RegistrationProps) => ({
        url: `${baseUrl}/register`,
        method: "POST",
        body,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email: string) => ({
        url: "/consumer/password/email",
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: (body: ResetPasswordProps) => ({
        url: "/consumer/password/reset",
        method: "POST",
        body,
      }),
    }),
    updatePassword: builder.mutation({
      query: (body: UpdatePasswordProps) => ({
        url: `${baseUrl}/user/update-password`,
        method: "POST",
        body,
      }),
    }),
    resendEmailVerification: builder.mutation({
      query: () => ({
        url: `${baseUrl}/email/verify/resend`,
        method: "POST",
      }),
    }),
  }),
});

//public endpoints
export const {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useResendEmailVerificationMutation,
} = authApi;

//private endpoints, public wrappers exported below
const { useLoginMutation, useLogoutMutation, useRegisterMutation } = authApi;

export const useLogin = () => {
  const [authToken, setAuthToken] = useLocalStorage(authTokenStorageKey, undefined);
  const [login, state] = useLoginMutation();
  const loginAndSetToken = (body: LoginCredentialsProps) => {
    const result = login(body);
    result
      .unwrap()
      .then((data) => {
        setAuthToken(data);
      })
      .catch((err) => {});
    return result;
  };

  return [loginAndSetToken, state] as const;
};

export const useLogout = () => {
  const [logout, state] = useLogoutMutation();
  const localLogout = useLocalLogout();
  const logoutAndClearToken = () => {
    const result = logout(undefined);
    localLogout();
    return result;
  };

  return [logoutAndClearToken, state] as const;
};

//clear local data in the browser only (used for logout and for delete user)
export const useLocalLogout = () => {
  const [authToken, setAuthToken] = useLocalStorage(authTokenStorageKey, undefined);
  const [sponsorInLocalStorage, setSponsorInLocalStorage] = useLocalStorage(sponsorStorageKey, null);
  const dispatch = useDispatch();
  const logoutAndClearToken = () => {
    setAuthToken("");
    Analytics.reset();
    setSponsorInLocalStorage(null);
    dispatch(baseApi.util.resetApiState());
  };

  return logoutAndClearToken;
};

export const useRegister = () => {
  const [authToken, setAuthToken] = useLocalStorage(authTokenStorageKey, undefined);
  const [register, state] = useRegisterMutation();
  const [fetchUser] = useLazyGetUserQuery(undefined);
  const registerAndSetToken = (body: RegistrationProps) => {
    const result = register(body);
    result
      .unwrap()
      .then(async (token) => {
        setAuthToken(token);
        const { data: user } = await fetchUser(undefined);
        if (user) {
          Analytics.alias(user.unique_id);
          Analytics.identify(user);
        }
      })
      .catch((err) => {});
    return result;
  };

  return [registerAndSetToken, state] as const;
};

export const useIsLoggedIn = () => {
  const [authToken] = useLocalStorage(authTokenStorageKey, undefined);
  return !!authToken;
};
