import { LeadSourceProvider } from "@components/utils/contexts/leadsource";
import { PropertyProvider } from "@components/utils/contexts/property";
import { ReferralProvider } from "@components/utils/contexts/referral";
import { StatusProvider } from "@components/utils/contexts/status";
import { UiProvider } from "@components/utils/contexts/ui";
import { UserProvider } from "@components/utils/contexts/user";
import React, { FC } from "react";

export const GlobalContextProvider: FC = (props) => {
  return (
    <UserProvider>
      <StatusProvider>
        <LeadSourceProvider>
          <ReferralProvider>
            <PropertyProvider>
              <UiProvider>{props.children}</UiProvider>
            </PropertyProvider>
          </ReferralProvider>
        </LeadSourceProvider>
      </StatusProvider>
    </UserProvider>
  );
};
