import React, { FC, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import "./Back.scoped.scss";

interface Back {
  className?: string;
  onClick: MouseEventHandler;
  type?: string | null;
  to?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Back: FC<Back> = ({ type = null, loading, disabled, to, className, onClick, ...props }) => {
  if (to) {
    return (
      <Link {...props} to={to} className={`back ${type ? type : ""} ${className ? className : ""}`}>
        <i className="digs-icon-caret-left" />
        <span className="sr-only">Go back</span>
      </Link>
    );
  }

  return (
    <a
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`back ${type ? type : ""} ${className ? className : ""}`}
    >
      <i className="digs-icon-caret-left" />
      <span className="sr-only">Go back</span>
    </a>
  );
};

export default Back;
