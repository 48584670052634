import { PropertyVariantImage } from "@components/global";
import { propertyTypes, propertyVariants } from "@helpers/Constants";
import { HomeFundModel } from "@store/models/funds";
import React, { FC, MouseEvent, useMemo, useRef } from "react";
import Slider from "react-slick";
import "./PropertyVariantCarousel.scss";

interface PropertyVariantCarousel {
  onChange: (n: HomeFundModel["property_variant"]) => void;
  value?: HomeFundModel["property_variant"];
  propertyType?: HomeFundModel["property_type"];
  className?: string;
  theme?: "default" | "minimal";
}

//TODO arrows should be <button> elements, not divs
const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className={"slider__arrow slider__next"} onClick={onClick}>
      <i className={"digs-icon-caret-right"} />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className={"slider__arrow slider__prev"} onClick={onClick}>
      <i className={"digs-icon-caret-left"} />
    </div>
  );
};

const PropertyVariantCarousel: FC<PropertyVariantCarousel> = ({
  onChange,
  value = "",
  propertyType,
  className = "",
  theme = "default",
}) => {
  const sliderRef = useRef(null);
  const variants = propertyType
    ? propertyTypes[propertyType].variants.map((v) => propertyVariants[v])
    : Object.values(propertyVariants);
  const variant = propertyVariants[value as HomeFundModel["property_variant"]];
  const findInitialVariantIndex = (value: string | null) => {
    const index = variants.findIndex((variant) => variant.value === value);
    return index > -1 ? index : 0;
  };

  const onNextHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (sliderRef.current) {
      //@ts-ignore
      sliderRef.current.slickNext();
    }
  };

  const onPrevHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (sliderRef.current) {
      //@ts-ignore
      sliderRef.current.slickPrev();
    }
  };

  //TODO move to global
  const variantSlider = useMemo(() => {
    const carouselSettings = {
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      touchMove: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      arrows: theme === "default",
      initialSlide: findInitialVariantIndex(value),
      afterChange: (current: number) => {
        onChange(variants[current].value);
      },
    };

    const circle =
      theme === "default" ? (
        <div className={"carousel__circle"}>
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
          </svg>
        </div>
      ) : null;

    const slides = variants.map((variant, i) => {
      return (
        <div key={i}>
          {circle}
          <div key={i} className="slider__content">
            <PropertyVariantImage property_variant={variant.value} />
            {theme === "default" ? <h4 className="carousel__title">{variant.title}</h4> : null}
          </div>
        </div>
      );
    });

    return (
      <Slider ref={sliderRef} className={"slider"} {...carouselSettings}>
        {slides}
      </Slider>
    );
  }, [propertyType]);

  return (
    <div className={`carousel ${className} carousel--theme-${theme}`}>
      {variantSlider}
      {theme === "minimal" ? (
        <div className="carousel__controls">
          <button className="carousel__control" onClick={onPrevHandler}>
            <i className="digs-icon-left-arrow" />
          </button>
          <div className="carousel__title">{variant ? variant.title : ""}</div>
          <button className="carousel__control" onClick={onNextHandler}>
            <i className="digs-icon-right-arrow" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PropertyVariantCarousel;
