import React, { FC } from "react";
import "./FormItem.scoped.scss";

export interface FormItemProps {
  touched?: boolean;
  errors?: any;
  hideErrors?: boolean;
  showValidated?: boolean;
  darkBackground?: boolean;
  feedbackId?: string;
}

const FormItem: FC<FormItemProps> = ({
  touched,
  errors,
  hideErrors,
  showValidated = false,
  darkBackground = false,
  feedbackId = "",
  children,
}) => {
  return (
    <div
      className={`form-item ${touched && errors ? "errors" : ""} ${darkBackground ? "form-item--dark-background" : ""}`}
    >
      {children}
      {touched && !errors && showValidated && (
        <div className="form-item__validated">
          <i className="digs-icon-checkmark" />
        </div>
      )}
      <div className="form-item__feedback" id={feedbackId}>
        {touched && errors && !hideErrors && <p>{errors}</p>}
      </div>
    </div>
  );
};

export default FormItem;
