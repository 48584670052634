import "animate.css/animate.min.css";
import React, { FC } from "react";
import ReactNotification, { store } from "react-notifications-component";
import "./scss/notification.scss";

const Notifications: FC = (props) => <ReactNotification {...props} />;

export const createNotification = (type: "success" | "danger" | "info" | "default", title: string, message: string) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3500,
      showIcon: true,
    },
  });
};

export default Notifications;
