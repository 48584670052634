export type ThumbtackChecklistTaskTagLine =
  | "PREVENTS_FLOODING"
  | "PREVENTS_BREAKDOWNS"
  | "IMPROVES_YOUR_HOME"
  | "REDUCES_ELECTRIC_BILL"
  | "PREVENTS_MOLD"
  | "PREVENTS_FIRES"
  | "IMPROVES_SAFETY"
  | "SAVES_ENERGY_COSTS"
  | "IMPROVES_AIR_QUALITY"
  | "PREVENTS_LEAKS"
  | "BOOSTS_CURB_APPEAL";

export interface ChecklistTask {
  id: number;
  property_id: number;
  external_id?: string;
  source: "digs" | "thumbtack";
  name?: string;
  completed_at?: string;
  irrelevant_at?: string;
  read_at?: string;
  created_at: string;
  updated_at: string;
  recurrence_days?: number;
  rawData?: any;

  //below values are filled-in by the checklist service
  imageUrl?: string;
  url?: string;
  cost?: number;
  categoryName?: string;
  categoryDisplayName?: string;
  description?: string;
  tagLine?: ThumbtackChecklistTaskTagLine;
  isNew?: boolean;
}

export interface DigsChecklistTask extends ChecklistTask {
  source: "digs";
  //TODO specify our rawData shape here
}

export interface ThumbtackChecklistTask extends ChecklistTask {
  source: "thumbtack";
  rawData: {
    taskDetails: {
      title: string;
      description: string;
      tagline: ThumbtackChecklistTaskTagLine;
      avgCost: {
        max: number;
        min: number;
      };
    };
    categoryPK: string;
    taskPK: string;
    categoryName: string;
    categoryDisplayName: string;
    activeServices: number | null;
    url: string;
    imageURL: string;
  };
}

export interface ChecklistCount {
  count: number;
  property_id: number;
}

export type ChecklistCounts = Record<string, number>;

export function isDigsItem(item: ChecklistTask): item is DigsChecklistTask {
  return item.source === "digs";
}

export function isThumbtackItem(item: ChecklistTask): item is ThumbtackChecklistTask {
  return item.source === "thumbtack";
}
