import { LeadSourceContext, PropertyContext, ReferralContext, UserContext } from "@components/utils/contexts";
import { TemporaryPropertyModel } from "@components/utils/contexts/property";
import Analytics from "@helpers/analytics";
import Api from "@helpers/api/index";
import DigsHelper from "@helpers/DigsHelper";
import { getQueryError } from "@helpers/Errors";
import { useTreatment } from "@helpers/Split";
import useSearchParams, { SearchParamTypes } from "@helpers/useSearchParams";
import { SubscriberModel } from "@store/models/subscriber";
import { UserModel } from "@store/models/user";
import { useCreateSubscriberMutation } from "@store/services/subscriber";
import { useFormik } from "formik";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Back, Button, Feedback } from "../../index";
import { Address, AddressSchema, AptOrUnitNumber, AptOrUnitNumberSchema, Email, EmailSchema, Login } from "./forms";
import "./SignupOnboarding.scoped.scss";

interface SignupOnboarding {
  onSubmit: (property: TemporaryPropertyModel) => void;
  product: string;
}

const OwnerOnboarding: FC<SignupOnboarding> = ({ onSubmit, product = "homeowner" }) => {
  const [step, setStep] = useState<number>(1);
  const { temporaryUser, setTemporaryUserAttributes } = useContext(UserContext);
  const { sponsor } = useContext(ReferralContext);
  const { leadSource } = useContext(LeadSourceContext);
  const { temporaryProperty, setTemporaryPropertyAttributes, clearTemporaryPropertyAttributes } = useContext(
    PropertyContext
  );
  const showAlternateSignupPage = useTreatment("digs-app_alternate-signup-page");

  const [
    createSubscriber,
    { isLoading: submitting, isError: createSubscriberErrored, error: createSubscriberError },
  ] = useCreateSubscriberMutation();

  const { address } = useSearchParams<SearchParamTypes>();

  const renderValidation = (step: number) => {
    switch (step) {
      case 1:
        return AddressSchema;
      case 2:
        return AptOrUnitNumberSchema;
      case 3:
        return EmailSchema;
      default:
        return AddressSchema;
    }
  };

  const sendStepCompletedEvent = () => {
    switch (step) {
      case 1:
        return Analytics.trackEvent(new Analytics.Events.Onboarding.UserProvidedAddressEvent());
      case 2:
        return Analytics.trackEvent(new Analytics.Events.Onboarding.UserProvidedUnitNumberEvent());
      case 3:
        return Analytics.trackEvent(new Analytics.Events.Onboarding.UserSubscribedEvent());
    }
  };

  const initialValues: TemporaryPropertyModel & Pick<UserModel, "email"> = {
    search: temporaryProperty?.search || "",
    street_address: temporaryProperty?.street_address || "",
    city: temporaryProperty?.city || "",
    state: temporaryProperty?.state || "",
    zip_code: temporaryProperty?.zip_code || "",
    apt_or_unit: temporaryProperty?.apt_or_unit || "",
    email: temporaryUser?.email || "",
    latitude: 0,
    longitude: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: renderValidation(step),
    onSubmit: ({ email, ...propertyValues }) => {
      if (email) {
        setTemporaryUserAttributes({ email });
      }

      sendStepCompletedEvent();

      /*
        Set completed property into state so
        that user can be properly redirected
        on registration
      */
      setTemporaryPropertyAttributes({
        type: "owner",
        ...propertyValues,
      });

      if (step >= 3) {
        createSubscriber({
          ...propertyValues,
          email,
          provider_id: sponsor ? sponsor.id : null,
          lead_source: leadSource ?? undefined,
        })
          .unwrap()
          .then(({ subscriber, property }: { subscriber: SubscriberModel; property: any }) => {
            Analytics.alias(subscriber.unique_id);
            Analytics.identify(subscriber);

            if (property) {
              clearTemporaryPropertyAttributes();
            }

            if (onSubmit) {
              onSubmit(temporaryProperty);
            }
          })
          .catch((err: any) => {
            if (err.error_code === "login_required") {
              setStep(step + 1);
            }
          });
      } else {
        setStep(step + 1);
      }
    },
  });

  // Attempt at making an easily iterable pattern for
  // conditionally changing content for different landing pages, open to suggestions
  const renderAddressStep = () => {
    return <Address formik={formik} />;
  };

  const renderAptOrUnitStep = () => {
    return <AptOrUnitNumber formik={formik} />;
  };

  const renderEmailStep = () => {
    switch (product) {
      case "maintenance-fund":
        return (
          <Email formik={formik}>
            <>
              <h1>
                <span>Where should we send maintenance alerts for</span>{" "}
                {DigsHelper.formatAddress(formik.values, false)}?
              </h1>
              <p></p>
            </>
          </Email>
        );
      case "trusted-providers":
        return (
          <Email formik={formik}>
            <>
              <h1>
                <span>What is the best email to contact you regarding future home repair?</span>
              </h1>
              <p></p>
            </>
          </Email>
        );
      default:
        return <Email formik={formik} />;
    }
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 1:
        return renderAddressStep();
      case 2:
        return renderAptOrUnitStep();
      case 3:
        return renderEmailStep();
      case 4:
        return (
          <Login
            formValues={formik.values}
            onSubmit={onSubmit}
            onBack={() => setStep(step - 1)}
            step={4}
            currentStep={step}
          />
        );
      default:
        return <Address formik={formik} />;
    }
  };

  const renderButtonText = (step: number) => {
    switch (step) {
      case 2:
        return formik.values.apt_or_unit ? "Continue" : "Skip";
      case 3:
        return "Save & Continue";
      default:
        return "Continue";
    }
  };

  const onBackHandler = useCallback(
    (e) => {
      e.preventDefault();
      setStep(step - 1);
    },
    [step]
  );

  useEffect(() => {
    if (temporaryProperty && temporaryProperty.street_address) {
      temporaryProperty.apt_or_unit ? setStep(3) : setStep(2);
    } else if (address) {
      Api.getSSPropertyByAddress(address)
        .then((results) => {
          formik
            .setValues({
              ...formik.values,
              search: address,
              street_address: results.street_address || "",
              city: results.city || "",
              state: results.state || "",
              zip_code: results.zip_code || "",
              apt_or_unit: String(results.apt_or_unit) || "",
              latitude: results.latitude,
              longitude: results.longitude,
            })
            .then(() => {
              results.apt_or_unit ? setStep(3) : setStep(2);
            });
        })
        .catch((error) => {
          setStep(1);
        });
    }
  }, []);

  return (
    <div className="signup-onboarding">
      {createSubscriberErrored && <Feedback type="error" content={getQueryError(createSubscriberError)} />}
      <form onSubmit={formik.handleSubmit}>
        {renderStep(step)}
        <div className="signup-onboarding--actions">
          <div className="signup-onboarding--actions__inner">
            {step > 1 && step <= 3 && <Back type="button" onClick={onBackHandler} />}
            {step <= 3 && (
              <Button loading={submitting} disabled={!formik.isValid} fullWidth={true}>
                {renderButtonText(step)}
              </Button>
            )}
          </div>
          <div className={`signup-onboarding--actions__terms`}>
            <sub>
              By signing up you agree to Movoto's{" "}
              <a href="https://ojo.com/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>
            </sub>
          </div>
        </div>
      </form>
      {showAlternateSignupPage === "on" && (
        <>
          <ul className="signup-onboarding__value-props">
            <li>
              <i className="digs-icon-pie-chart-line" />
              Monitor your equity
            </li>
            <li>
              <i className="digs-icon-trend-graph-arrow" />
              Track your home’s value
            </li>
            <li>
              <i className="digs-icon-checklist" />
              Get personalized maintenance checklists{" "}
            </li>
            <li>
              <i className="digs-icon-people" />
              Connect with top-rated home experts
            </li>
            <li>
              <i className="digs-icon-map-pin" />
              Be informed with real-time insights
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default OwnerOnboarding;
