import AnalyticsEvent from "./AnalyticsEvent";

abstract class PartnersEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "Partnerships";
  readonly data: any = {};
}

export type PartnerLinkLocation = "callout" | "primary";

export class PartnerClickedEvent extends PartnersEvent {
  readonly name = "Partners.PartnerClicked";

  constructor(partner: string, location: PartnerLinkLocation, featured: boolean = false) {
    super();
    this.data.partner = partner;
    this.data.location = location;
    this.data.featured = featured;
  }
}
