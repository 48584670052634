import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/browser";
import "source-map-support/register";

export function recordError(message: string, cause: Error) {
  Sentry.captureMessage(message, {
    extra: {
      cause,
    },
    level: Sentry.Severity.Error,
  });
}

export function recordWarning(message: string, cause: Error) {
  Sentry.captureMessage(message, {
    extra: {
      cause,
    },
    level: Sentry.Severity.Warning,
  });
}

export interface ErrorMessage {
  message?: string;
  errors?: string[];
}

export const getQueryError = (error?: FetchBaseQueryError | SerializedError) => {
  if (!error) {
    return null;
  }
  if ("status" in error) {
    //error is FetchBaseQueryError
    const data = error.data as ErrorMessage;
    return data?.message || data?.errors;
  } else {
    //error is SerializedError
    return error.message;
  }
};
