const baseUrl = "/consumer/v1/savings-funds";

export interface FundModel {
  id: number;
  balance: number;
  available_balance: number;
  pending_balance: number;
  is_q2_primary: boolean;
  is_q2_closeable: boolean;
  is_locked: boolean;
  lock_reason_type_code: string;
  lock_type_code: string;
  name?: string;
  type: "generic" | "maintenance" | "new-home";
}

export interface GenericFundModel extends FundModel {
  savings_goal: number;
  type: "generic";
}

export interface MaintenanceFundModel extends FundModel {
  type: "maintenance";
}

export interface HomeFundModel extends FundModel {
  timeline?: string;
  purchase_date: string;
  purchase_price: number;
  savings_target: number;
  bedrooms?: number;
  bathrooms?: number;
  down_payment_percentage: number;
  zip_code: number;
  type: "new-home";
  property_use: "primary" | "secondary" | "investment";
  property_type: "single" | "townhouse" | "condo" | "multi" | "manufactured";
  property_variant:
    | "suburban"
    | "modern"
    | "ranch"
    | "lake"
    | "beach"
    | "row"
    | "tudor"
    | "victorian"
    | "cottage"
    | "cabin"
    | "mobile"
    | "vintage"
    | "tiny"
    | "duplex"
    | "complex"
    | "midrise"
    | "highrise";
}

export function isGenericFund(
  fund: FundModel | GenericFundModel | MaintenanceFundModel | HomeFundModel
): fund is GenericFundModel {
  return fund.type === "generic";
}

export function isMaintenanceFund(
  fund: FundModel | GenericFundModel | MaintenanceFundModel | HomeFundModel
): fund is MaintenanceFundModel {
  return fund.type === "maintenance";
}

export function isHomeFund(
  fund: FundModel | GenericFundModel | MaintenanceFundModel | HomeFundModel
): fund is HomeFundModel {
  return fund.type === "new-home";
}

// TODO: Temp limiting to one
export const maxFunds = 1;
