import Yup from "@helpers/Yup";
import React, { FC } from "react";
import AddressInput, { SearchSuggestion } from "../../../form/addressInput/AddressInput";
import { FormItem } from "../../../index";

interface Address {
  formik: any;
}

export const AddressSchema = Yup.object().shape({
  search: Yup.string().required("Address required"),
  street_address: Yup.string().required("Street address required"),
  city: Yup.string().required("City required"),
  state: Yup.string().required("State required"),
  zip_code: Yup.string().required("Zip code required"),
});

const Address: FC<Address> = ({ formik, ...props }) => {
  const onSelectHandler = (results: SearchSuggestion) => {
    formik
      .setValues({
        ...formik.values,
        search: results.search,
        street_address: results.street_address || "",
        city: results.city || "",
        state: results.state || "",
        zip_code: results.zip_code || "",
        apt_or_unit: results.apt_or_unit || "",
      })
      .then(() => {
        formik.submitForm();
      });
  };

  return (
    <div className="signup-onboarding--step">
      {props.children || (
        <>
          <span className="title">Free Signup</span>
          <h1>Your personalized home dashboard</h1>
          <p>Track your home’s value, find ways to save, and build long-term wealth.</p>
        </>
      )}
      <FormItem>
        <AddressInput
          value={formik.values.search}
          onChange={(val) => formik.setFieldValue("search", val)}
          onSelect={onSelectHandler}
        />
      </FormItem>
    </div>
  );
};

export default Address;
