import config from "@config";
import TagManager from "react-gtm-module";
import AnalyticsEvent from "../events/AnalyticsEvent";
import { AnalyticsModule } from "./AnalyticsModule";

const GOOGLE_TAG_MANAGER_ID = config.REACT_APP_GOOGLE_TAG_MANAGER_ID || "";
const GOOGLE_TAG_MANAGER_AUTH = config.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || "";
const GOOGLE_TAG_MANAGER_PREVIEW = config.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW || "";

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
  auth: GOOGLE_TAG_MANAGER_AUTH,
  preview: GOOGLE_TAG_MANAGER_PREVIEW,
};

class GoogleModule implements AnalyticsModule {
  defaultDataLayer: any = {};

  initialize() {
    TagManager.initialize(tagManagerArgs);
  }

  identify(user: { unique_id: string; provider_id?: number }) {
    if (user && user.unique_id) {
      if (user.unique_id) {
        this.defaultDataLayer.userId = user.unique_id;
      }

      if (user.provider_id) {
        this.defaultDataLayer.providerId = user.provider_id;
      }
    }
  }

  alias(alias: string) {}

  reset() {
    this.defaultDataLayer = {};
  }

  trackEvent({ name, data, category }: AnalyticsEvent) {
    const valuesToSendToGTM = {
      eventCategory: category,
      eventAction: data.eventAction || "Default Action",
      eventLabel: data.eventLabel || name,
      eventValue: 0,
    };

    const args = {
      ...tagManagerArgs,
      dataLayer: {
        ...this.defaultDataLayer,
        event: "CustomEvent",
        userId: 0,
        providerId: 0,
        ...valuesToSendToGTM,
        ...data,
      },
    };

    TagManager.dataLayer(args);
  }

  trackPageview({ data }: AnalyticsEvent) {
    const args = {
      ...tagManagerArgs,
      dataLayer: {
        ...this.defaultDataLayer,
        event: "Pageview",
        pagePath: data.url,
        ...data,
      },
    };

    TagManager.dataLayer(args);
  }
}

export default new GoogleModule();
