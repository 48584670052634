import { Card } from "@components/global";
import SponsorAvatar from "@components/global/sponsorAvatar/SponsorAvatar";
import { ReferralContext, UiContext } from "@components/utils/contexts";
import useWindowSize from "@components/utils/useWindowSize";
import { breakpoints } from "@helpers/Constants";
import { SponsorModel } from "@store/models/sponsor";
import React, { FC, useCallback, useContext, useEffect } from "react";
import Analytics from "../../../helpers/analytics/index";
import "./SponsorCard.scss";
interface SponsorCard {
  sponsor?: SponsorModel;
}

const SponsorCard: FC<SponsorCard> = ({ sponsor }) => {
  const { openSponsorPanel, closeSponsorMessage, uiState } = useContext(UiContext);
  const { sponsorLoading } = useContext(ReferralContext);

  const { width } = useWindowSize();

  const onClickHandler = useCallback(() => {
    openSponsorPanel();
    if (sponsor) {
      Analytics.trackEvent(new Analytics.Events.SponsorPanel.PanelOpenedEvent(sponsor.id));
    }
  }, []);

  useEffect(() => {
    document.body.classList.add("with-sponsor");
    return () => {
      document.body.classList.remove("with-sponsor");
    };
  }, []);

  const closeMessage = () => {
    closeSponsorMessage();
  };

  if (width <= breakpoints.sm) {
    return (
      <div className="sponsor-card" onClick={onClickHandler}>
        {sponsor ? (
          <SponsorAvatar sponsor={sponsor} size="medium" />
        ) : (
          <div className="sponsor-card__providerless-chat" onClick={onClickHandler}>
            <i className="digs-icon-chat-alt" />
          </div>
        )}
        <div className="sponsor-card__message">
          <p>
            <strong>Hello!</strong>{" "}
            {sponsor
              ? "I’m your real estate contact. Feel free to ask me a question about your home."
              : "Do you have a question about your home? Feel free to ask it here."}
          </p>
        </div>
      </div>
    );
  }

  if (!sponsorLoading) {
    return (
      <div className="sponsor-card">
        {uiState.sponsor_message && (
          <Card className="sponsor-card__message">
            <button onClick={closeMessage} className="sponsor-card__message__close">
              <i className="digs-icon-close"></i>
              <span className="sr-only">Close modal</span>
            </button>
            <p>
              <strong>Hello!</strong>{" "}
              {sponsor
                ? "I’m your real estate contact. Feel free to ask me a question about your home"
                : "Do you have a question about your home? Feel free to ask it here."}
            </p>
          </Card>
        )}
        {sponsor ? (
          <SponsorAvatar sponsor={sponsor} size="medium" onClick={onClickHandler} />
        ) : (
          <div className="sponsor-card__providerless-chat" onClick={onClickHandler}>
            <i className="digs-icon-chat-alt" />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default SponsorCard;
