import { RegistrationModalIntent } from "@store/models/ui";
import AnalyticsEvent from "./AnalyticsEvent";

abstract class PublicPropertyEvent implements AnalyticsEvent {
  abstract name: string;
  readonly category = "PublicProperty";
  readonly data: any = { flow: "owner_page" };
}

export class RegistrationModalShownEvent extends PublicPropertyEvent {
  readonly name = "PublicProperty.RegistrationModalShown";

  constructor(intent?: RegistrationModalIntent) {
    super();
    this.data.intent = intent;
  }
}

export class RegistrationModalDismissedEvent extends PublicPropertyEvent {
  readonly name = "PublicProperty.RegistrationModalDismissed";
}

export class UserCreatedAccountEvent extends PublicPropertyEvent {
  readonly name = "PublicProperty.UserCreatedAccount";
}

export class LoginModalShownEvent extends PublicPropertyEvent {
  readonly name = "PublicProperty.LoginModalShown";
}

export class LoginModalDismissedEvent extends PublicPropertyEvent {
  readonly name = "PublicProperty.LoginModalDismissed";
}

export class UserLoggedInEvent extends PublicPropertyEvent {
  readonly name = "PublicProperty.UserLoggedIn";
}
