import React, { CSSProperties, FC, HTMLProps } from "react";
import "./TextButton.scoped.scss";

interface TextButton extends HTMLProps<HTMLButtonElement> {
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  type?: "button" | "submit";
}

const TextButton: FC<TextButton> = ({ children, className, ...props }) => {
  return (
    <button {...props} className={`text-button ${className ? className : ""}`}>
      {children}
    </button>
  );
};

export default TextButton;
